import { fromNullable } from 'fp-ts/lib/Option';
import * as debug from 'debug';

const logger = debug('sdi:toggle');

type Id = string | number;

export type ToggleTuple<U extends string> = {
    typeName: U;
    id: Id;
    value: boolean;
};

export type ToggleState<U extends string> = ToggleTuple<U>[];

export const getTypeName = <U extends string>(t: ToggleTuple<U>) => t.typeName;
export const getId = <U extends string>(t: ToggleTuple<U>) => t.id;
export const getValue = <U extends string>(t: ToggleTuple<U>) => t.value;

export const toggle = <U extends string>(
    state: ToggleState<U>,
    typeName: U,
    id: Id
): ToggleState<U> => {
    const index = state.findIndex(
        t => getTypeName(t) === typeName && getId(t) === id
    );
    logger(`${typeName}  ${id} ${index < 0}`);
    if (index < 0) {
        return state.concat({ typeName, id, value: true });
    }
    state[index].value = !getValue(state[index]);
    return state;
};

export const clearToggles = <U extends string>(state: ToggleState<U>) => {
    state.forEach(s => (s.value = false));
    return state;
};

export const getToggleValue = <U extends string>(
    state: ToggleState<U> | Readonly<ToggleState<U>>,
    tn: U,
    id: Id
): boolean =>
    fromNullable(state.find(t => getTypeName(t) === tn && getId(t) === id))
        .map(getValue)
        .getOrElse(false);

logger('loaded');
