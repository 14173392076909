import { create, singleSelectOptions } from 'sdi/map';
import {
    getBaseLayer,
    getMapView,
    getMapInfo,
    MAP_COLLECT_NAME,
    getMapInteraction,
    getMapSelection,
} from '../queries/map';
import { Option, none, some } from 'fp-ts/lib/Option';
import { updateView, setScaleLine } from '../events/map';
import { DIV, H1 } from 'sdi/components/elements';
import { resetObservationSelection } from '../events/collect';
import { tryNumber } from 'sdi/util';
import {
    navigateObservation,
    navigateHome,
    navigateCollect,
    navigateObservationForm,
} from '../events/route';
import { Collect } from '../remote';
import {
    renderButtonHome,
    renderButtonAddObervation,
    renderButtonViewCollect,
} from './buttons';
import { getSelectedCollect } from '../queries/collect';
import { fromRecord } from 'sdi/locale';

const renderActions = (c: Collect) =>
    DIV(
        'footer-actions',
        renderButtonViewCollect(() => navigateCollect(c.id)),
        renderButtonAddObervation(() => navigateObservationForm(c.id))
    );

let mapUpdate: Option<() => void> = none;
let mapSetTarget: Option<(e: HTMLElement) => void> = none;

const selectFeature = (_lid: string, fid: number | string) =>
    tryNumber(fid).map(navigateObservation);

const attachMap = (element: HTMLElement | null) => {
    mapUpdate = mapUpdate.foldL(
        () => {
            const { update, setTarget, selectable } = create(MAP_COLLECT_NAME, {
                getBaseLayer,
                getView: getMapView,
                getMapInfo,

                updateView,
                setScaleLine,

                element,
            });

            selectable(
                singleSelectOptions({
                    clearSelection: resetObservationSelection,
                    getSelected: getMapSelection,
                    selectFeature,
                }),
                getMapInteraction
            );

            mapSetTarget = some(setTarget);
            update();
            return some(update);
        },
        update => some(update)
    );

    if (element) {
        mapSetTarget.map(f => f(element));
    }
};

const renderMap = () => {
    mapUpdate.map(f => f());
    return DIV(
        {
            className: 'map-wrapper',
            key: 'map-wrapper',
        },
        DIV({
            id: 'collect-map',
            key: 'collect-map',
            className: 'map',
            ref: attachMap,
        })
    );
};

const renderErrorSelection = () =>
    DIV('error', 'Soory, could not find the selected campaign');

const renderCollectMapWrapper = (c: Collect) =>
    DIV(
        { className: 'collect-map' },
        renderButtonHome(navigateHome),
        H1(
            {
                className: 'collect__title',
            },
            fromRecord(c.title)
        ),
        renderActions(c),
        renderMap()
    );

export const renderSimpleCollectMap = () =>
    DIV({}, getSelectedCollect().foldL(renderErrorSelection, renderMap));

export const render = () =>
    DIV(
        { className: 'collect-wrapper' },
        getSelectedCollect().foldL(
            renderErrorSelection,
            renderCollectMapWrapper
        )
    );

export default render;
