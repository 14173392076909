import { query } from 'sdi/shape';
import { fromNullable, fromPredicate, some } from 'fp-ts/lib/Option';
import { AnnotationTargetKind } from '../remote';
import { getToggleValue } from 'sdi/lib';

export const getLayout = () => query('app/layout');

export const getUsername = () =>
    fromNullable(query('data/user')).map(u => u.name);

export const getAppName = () => query('app/name');

export const findUser = (id: number | null) =>
    fromNullable(id)
        .map(id => id.toString())
        .chain(uid =>
            fromNullable(query('data/users').find(u => u.id === uid))
        );

export const getUnkownUsers = (ids: number[]) => {
    // const uids = ids.map(i => i.toString());
    const knowns = query('data/users').map(u => parseInt(u.id, 10));
    return ids.filter(i => knowns.indexOf(i) < 0);
};

export const isAnnotationOpen = (at: AnnotationTargetKind, id: number) =>
    getToggleValue(query('collect/toggle/annotation'), at, id);

const isTrue = fromPredicate<boolean>(v => v);

export const predAnnotationOpen = (at: AnnotationTargetKind, id: number) =>
    isTrue(isAnnotationOpen(at, id));

export const getRegisterForm = () =>
    fromNullable(query('collect/form/register'));

export const getRegisterUsername = () =>
    getRegisterForm().chain(form => fromNullable(form.username));
export const getRegisterEmail = () =>
    getRegisterForm().chain(form => fromNullable(form.email));
export const getRegisterPassword = () =>
    getRegisterForm().chain(form => fromNullable(form.password));
export const getRegisterControlPassword = () =>
    getRegisterForm().chain(form =>
        form.completed
            ? some(form.password)
            : fromNullable(form.passwordControl)
    );
export const getRegisterConsent = () =>
    getRegisterForm()
        .chain(form => fromNullable(form.consent))
        .getOrElse(false);

export const getRegisterCompleted = () =>
    getRegisterForm()
        .map(form => form.completed)
        .getOrElse(false);

export const getRegisterError = () =>
    fromNullable(query('component/register/error'));
