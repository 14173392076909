import { assign, assignK, dispatch, query } from 'sdi/shape';
import { IUser } from 'sdi/source';
import { Layout } from './route';
import {
    AnnotationTargetKind,
    fetchAllUsers,
    postRegister,
    RegisterFormInProgress,
    checkRegisterForm,
    tryRegisterForm,
    fetchUser,
} from '../remote';
import { getApiUrl, getRootUrl, getUserId } from 'sdi/app';
import { fromNullable } from 'fp-ts/lib/Option';
import { clearToggles, toggle } from 'sdi/lib';

export const setLayout = (l: Layout) => {
    assign('app/layout', l);
};

export const setUserData = (u: IUser) => assign('data/user', u);

export const setAppName = (n: string) => assign('app/name', n);

export const loadAllUsers = () =>
    fetchAllUsers(getApiUrl('users'))
        .then(assignK('data/users'))
        .catch(err => console.error(err));

export const initRegisterForm = (community: number) =>
    assign('collect/form/register', { community, completed: false });

export const updateRegisterForm =
    <K extends keyof RegisterFormInProgress>(key: K) =>
    (value: RegisterFormInProgress[K]) =>
        dispatch('collect/form/register', state =>
            fromNullable(state)
                .map(form =>
                    checkRegisterForm({
                        ...form,
                        [key]: value,
                    })
                )
                .getOrElse({
                    [key]: value,
                    completed: false,
                })
        );

export const toggleAnnotation = (at: AnnotationTargetKind, id: number) =>
    dispatch('collect/toggle/annotation', s => toggle(s, at, id));

export const clearToggleAnnotation = () =>
    dispatch('collect/toggle/annotation', clearToggles);

export const submitRegister = () =>
    fromNullable(query('collect/form/register'))
        .chain(tryRegisterForm)
        .map(postRegister)
        .map(prm =>
            prm
                .then(result => {
                    if (result.tag === 'ok') {
                        window.location.assign(getRootUrl('smartwater'));
                    } else {
                        assign('component/register/error', result);
                    }
                })
                .catch(err => console.error(err))
        );

export const loadUser = () =>
    getUserId().map(id =>
        fetchUser(getApiUrl(`users/${id}`)).then(user =>
            assign('data/user', user)
        )
    );
