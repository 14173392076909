import { dispatch, dispatchK } from 'sdi/shape';
import {
    removeLayerAll,
    addLayer,
    FetchData,
    viewEventsFactory,
    checkMap,
    defaultInteraction,
    trackerEventsFactory,
} from 'sdi/map';
import {
    MAP_COLLECT_NAME,
    makeLayerInfoOptionFromCollect,
    makeLayerFromCollect,
    getMapInteraction,
} from '../queries/map';
import {
    findCollect,
    getCommunityExtent,
    getRemoteObservations,
} from '../queries/collect';
import { right } from 'fp-ts/lib/Either';
import { some } from 'fp-ts/lib/Option';
import { remoteToOption } from 'sdi/source';
import { noop } from 'sdi/util';
import { Coordinate } from 'ol/coordinate';

export const updateView = viewEventsFactory(
    dispatchK('port/map/view')
).updateMapView;
export const updateObservationView = viewEventsFactory(
    dispatchK('port/map/observation/view')
).updateMapView;
export const setScaleLine = noop;
export const setMapSelection = dispatchK('port/map/selection');
export const setMapInteraction = dispatchK('port/map/interaction');

// observe('port/map/view', v => {
//     if (getLayout() === 'map') {
//         assign('port/map/observation/view', v);
//     }
// });

export const loadCollectMap = (id: number) =>
    checkMap(MAP_COLLECT_NAME)
        .chain(() => remoteToOption(getRemoteObservations()))
        .chain(() => findCollect(id))
        .foldL(
            () => {
                window.setTimeout(() => loadCollectMap(id), 32);
            },
            c => {
                removeLayerAll(MAP_COLLECT_NAME);
                const observedInfo = makeLayerInfoOptionFromCollect(c);
                const observedFetcher: FetchData = () =>
                    right(some(makeLayerFromCollect(c.id)));

                addLayer(MAP_COLLECT_NAME, observedInfo, observedFetcher);
            }
        );

export const zoomToExtent = (
    communityId: number,
    view: 'map' | 'observation'
) =>
    getCommunityExtent(communityId).map(extent => {
        dispatch(
            view === 'map' ? 'port/map/view' : 'port/map/observation/view',
            state => ({
                dirty: 'geo/extent',
                center: state.center,
                rotation: state.rotation,
                zoom: state.zoom,
                srs: state.srs,
                feature: null,
                extent,
            })
        );
    });

export const trackerEvents = trackerEventsFactory(
    setMapInteraction,
    getMapInteraction
);

// observe('port/map/view', (v) => {
//     if (getLayout() === 'map') {
//         assign('port/map/observation/view', v);
//     }
// });

export const startMark = () =>
    setMapInteraction(s => {
        if (s.label === 'mark') {
            return { ...s, state: { ...s.state, started: true } };
        }
        return s;
    });

export const endMark = () => setMapInteraction(() => defaultInteraction());

export const putMark = (coordinates: Coordinate) =>
    setMapInteraction(() => ({
        label: 'mark',
        state: {
            started: false,
            endTime: Date.now() + 12000,
            coordinates,
        },
    }));
