import { DIV, H3, H1, DETAILS, SUMMARY, SPAN } from 'sdi/components/elements';
import { Collect, CollectCategory, Question } from '../remote';
import { markdown } from 'sdi/ports/marked';
import {
    findCategory,
    getChoices,
    getCollectsWithCategory,
    getCurrentCommunity,
    getNbOfObservations,
    getObservedQuestions,
    getObserverQuestions,
    getSelectedCollect,
} from '../queries/collect';
import {
    navigateCollect,
    navigateCommunity,
    navigateObservationForm,
    navigateObservationFormPrint,
} from '../events/route';
import {
    renderButtonAddObervation,
    renderButtonCommunity,
    renderButtonPrintForm,
} from './buttons';
import tr, { fromRecord } from 'sdi/locale';
import { renderSimpleCollectMap } from './map';
import { notEmpty } from 'sdi/util';
import { catOptions } from 'fp-ts/lib/Array';
import { nameToString } from 'sdi/components/button/names';
import { isAnswerdCollect } from '../events/collect';

const renderErrorSelection = () =>
    DIV('error', tr.smartwater('error.noCampaign'));

const renderQuestionChoices = (q: Question) =>
    DIV(
        'choice__list',
        getChoices(q.id).map(c =>
            DIV(
                {
                    className: 'choice__wrapper',
                    key: c.id,
                },
                DIV('choice__label', fromRecord(c.title))
            )
        )
    );

const breadCrumb = () =>
    DIV(
        'breadcrumb',
        getCurrentCommunity().map(com =>
            renderButtonCommunity(com.name)(() => navigateCommunity(com.id))
        )
    );

const renderQuestionNumber = (n: number, total: number, q: Question) =>
    DIV(`question__number ${q.kind}`, `${n + 1}/${total}`);

const renderQuestion = (q: Question, index: number, questions: Question[]) =>
    DIV(
        {
            className: 'question',
            key: `question-${q.id}`,
        },
        DIV(
            'question__infos',
            renderQuestionNumber(index, questions.length, q),
            H3({}, fromRecord(q.title)),
            markdown(fromRecord(q.description)),
            renderQuestionChoices(q)
            // annotationTriggerQuestion(q)
        )
        // renderAnnotationWrapperQuestion(q)
    );

const collectMapLegend = () =>
    DIV(
        'legend',
        DIV(
            'legend-item',
            SPAN('fa icon legend-icon week', nameToString('PentagonEFill')),
            SPAN('legend-label', tr.smartwater('legendLabel1'))
        ),
        DIV(
            'legend-item',
            SPAN('fa icon legend-icon month', nameToString('PentagonEFill')),
            SPAN('legend-label', tr.smartwater('legendLabel2'))
        ),
        DIV(
            'legend-item',
            SPAN(
                'fa icon legend-icon more-than-month',
                nameToString('PentagonEFill')
            ),
            SPAN('legend-label', tr.smartwater('legendLabel3'))
        )
    );

const collectMap = (c: Collect) =>
    DIV(
        'collect-map__wrapper',
        DIV(
            'collect-map__body',
            DIV(
                'collect__meta',
                getNbOfObservations(c.id) > 1
                    ? `${getNbOfObservations(c.id)} ${tr.smartwater(
                          'labelObservationMultiple'
                      )}`
                    : `${getNbOfObservations(c.id)} ${tr.smartwater(
                          'labelObservationSingle'
                      )}`
            ),
            renderSimpleCollectMap(),
            collectMapLegend()
            // collectMapSide(),
        )
    );

const collectForm = (c: Collect) =>
    DETAILS(
        'question-lists__wrapper',
        SUMMARY(
            '',
            DIV(
                'btn btn-2 label-and-icon collaspe',
                SPAN('label', tr.smartwater('labelCollectForm')),
                SPAN('icon', nameToString('th-list'))
            )
        ),
        DIV(
            'question__list observed',
            getObservedQuestions(c.id).map(renderQuestion)
        ),
        DIV(
            'question__list observer',
            getObserverQuestions(c.id).map(renderQuestion)
        )
    );

export const renderCollectLink = (c: Collect) => {
    const isAnswerd = isAnswerdCollect(c.id) ? 'answered' : 'not-answered';
    const isAnswerdPicto = isAnswerdCollect(c.id) ? 'check' : 'pencil-alt';
    return DIV(
        {
            className: `collect-link link ${isAnswerd}`,
            onClick: () => navigateCollect(c.id),
        },
        SPAN('icon', nameToString(isAnswerdPicto)),
        SPAN('label', fromRecord(c.title))
    );
};

const renderCategoryTag = (current: Collect) => (c: CollectCategory) =>
    DIV(
        {
            key: `cat-${c.id} col-${current}`,
            className: 'category',
            title: fromRecord(c.description),
        },
        DIV(
            'category-label',
            SPAN('icon', nameToString('tag')),
            fromRecord(c.title)
        )
    );

const renderLinkedCollect = (current: Collect) => (c: CollectCategory) =>
    DIV(
        {
            key: `linked-cat-${c.id}`,
            className: 'linked-collects',
            title: fromRecord(c.description),
        },
        H3(
            '',
            `${tr.smartwater('otherLinkedCollects')} "${fromRecord(c.title)}": `
        ),
        DIV(
            '',
            getCollectsWithCategory(c.id)
                .chain(collects =>
                    notEmpty(collects.filter(col => col.id !== current.id))
                )
                .map(collects =>
                    DIV(
                        'collect-link__list',
                        ...collects.map(renderCollectLink)
                    )
                )
        )
    );

const renderCategories = (c: Collect) => (cl: CollectCategory[]) =>
    DIV('cat-box', ...cl.map(renderCategoryTag(c)));

const renderLinkedCollects = (c: Collect) => (cl: CollectCategory[]) =>
    DIV('', ...cl.map(renderLinkedCollect(c)));

const actions = (c: Collect) =>
    DIV(
        'footer-actions',
        notEmpty(catOptions(c.categories.map(findCategory))).map(
            renderLinkedCollects(c)
        ),
        renderButtonPrintForm(() => navigateObservationFormPrint(c.id))
    );

const renderSelection = (c: Collect) =>
    DIV(
        'collect-infos__body',
        breadCrumb(),
        notEmpty(catOptions(c.categories.map(findCategory))).map(
            renderCategories(c)
        ),
        collectMap(c),
        DIV('collect-meta__wrapper', H1('collect__title', fromRecord(c.title))),
        DIV('collect-description', markdown(fromRecord(c.shortDescription))),
        DIV('collect__description', markdown(fromRecord(c.description))),
        collectForm(c),
        renderButtonAddObervation(() => navigateObservationForm(c.id)),
        actions(c)
    );

export const render = () =>
    DIV(
        'collect-infos__wrapper',
        getSelectedCollect().foldL(renderErrorSelection, renderSelection)
    );

export default render;
