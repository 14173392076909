// tslint:disable: variable-name
import * as io from 'io-ts';
import {
    deleteIO,
    fetchIO,
    MultiPolygonIO,
    PointIO,
    postIO,
    postUnrelatedIO,
    putIO,
    uploadIO,
} from 'sdi/source';
import { getApiUrl } from 'sdi/app';
import { MessageRecordIO, nullable } from 'sdi/source/io/io';
import { none, some, Option } from 'fp-ts/lib/Option';

const CollectIO = io.interface(
    {
        id: io.number,
        title: MessageRecordIO,
        description: MessageRecordIO,
        shortDescription: MessageRecordIO,
        question: MessageRecordIO,
        image: nullable(io.string),
        categories: io.array(io.Integer),
        timestamp: io.number,
    },
    'CollectIO'
);
const CollectCategoryIO = io.interface(
    {
        id: io.number,
        title: MessageRecordIO,
        description: MessageRecordIO,
        timestamp: io.number,
    },
    'CollectCategoryIO'
);

const QuestionKindIO = io.union(
    [io.literal('observer'), io.literal('observed')],
    'QuestionKindIO'
);

const QuestionIO = io.interface(
    {
        kind: QuestionKindIO,
        id: io.number,
        title: MessageRecordIO,
        description: MessageRecordIO,
        collect: io.number, // TODO: remove this or change to list - a question can be linked to multiple collects
        order: io.number,
    },
    'QuestionIO'
);

const ChoiceIO = io.interface(
    {
        id: io.number,
        title: MessageRecordIO,
        description: MessageRecordIO,
        question: io.number,
        order: io.number,
    },
    'ChoiceIO'
);

const ObservationIO = io.interface(
    {
        id: io.number,
        user: nullable(io.number),
        collect: io.number,
        timestamp: io.number,
        age: io.number,
        observed: PointIO,
        communities: io.array(io.Integer),
    },
    'ObservationIO'
);

const AnswerIO = io.interface(
    {
        id: io.number,
        observation: io.number,
        choice: io.number,
        timestamp: io.number,
    },
    'AnswerIO'
);

const CollectListIO = io.array(CollectIO, 'CollectListIO');
const CollectCategoryListIO = io.array(
    CollectCategoryIO,
    'CollectCategoryListIO'
);
const QuestionListIO = io.array(QuestionIO, 'QuestionListIO');
const ChoiceListIO = io.array(ChoiceIO, 'ChoiceListIO');
const ObservationListIO = io.array(ObservationIO, 'ObservationListIO');
const AnswerListIO = io.array(AnswerIO, 'AnswerListIO');

export type Collect = io.TypeOf<typeof CollectIO>;
export type CollectCategory = io.TypeOf<typeof CollectCategoryIO>;
export type Question = io.TypeOf<typeof QuestionIO>;
export type Choice = io.TypeOf<typeof ChoiceIO>;
export type Observation = io.TypeOf<typeof ObservationIO>;
export type Answer = io.TypeOf<typeof AnswerIO>;
export type QuestionKind = io.TypeOf<typeof QuestionKindIO>;

const url = (path: string) => getApiUrl(`geodata/smartwater/${path}`);

export const fetchCollectList = () => fetchIO(CollectListIO, url('collect/'));

export const fetchCollectCategoryList = () =>
    fetchIO(CollectCategoryListIO, url('category/'));

export const fetchQuestionList = () =>
    fetchIO(QuestionListIO, url('question/'));

export const fetchChoiceList = () => fetchIO(ChoiceListIO, url('choice/'));

export const fetchObservationList = () =>
    fetchIO(ObservationListIO, url('observation/'));

export const fetchAnswerList = () => fetchIO(AnswerListIO, url('answer/'));

export const postObservation = (
    collectId: number,
    observation: Partial<Observation>
) => postIO(ObservationIO, url(`observation/${collectId}`), observation);

export const putObservation = (collectId: number, observation: Observation) =>
    putIO(
        ObservationIO,
        url(`observation/${collectId}/${observation.id}`),
        observation
    );

export const postAnswer = (observarionId: number, answer: Partial<Answer>) =>
    postIO(AnswerIO, url(`answer/${observarionId}/`), answer);

export const AnnotationTargetKindIO = io.union(
    [
        io.literal('question'),
        io.literal('choice'),
        io.literal('observation'),
        io.literal('answer'),
        io.literal('annotation'),
    ],
    'AnnotationTargetKindIO'
);

const AnnotationTargetIO = io.interface(
    {
        kind: AnnotationTargetKindIO,
        id: io.number,
    },
    'AnnotationTargetIO'
);

const AnnotationBaseIO = io.interface(
    {
        id: io.number,
        collect: io.number,
        target: AnnotationTargetIO,
        user: nullable(io.number),
        timestamp: io.number,
    },
    'AnnotationBaseIO'
);

const AnnotationTextKindIO = io.literal('text');
const AnnotationImageKindIO = io.literal('image');
const AnnotationSoundKindIO = io.literal('sound');

export const AnnotationKindIO = io.union(
    [AnnotationTextKindIO, AnnotationImageKindIO, AnnotationSoundKindIO],
    'AnnotationKindIO'
);

const AnnotationTextIO = io.intersection(
    [
        AnnotationBaseIO,
        io.interface({ kind: AnnotationTextKindIO }),
        io.interface({
            text: io.string,
        }),
    ],
    'AnnotationTextIO'
);

const AnnotationImageIO = io.intersection(
    [
        AnnotationBaseIO,
        io.interface({ kind: AnnotationImageKindIO }),
        io.interface({
            image: io.string, // actually UUIDv4, so we might even do better validation at some point
        }),
    ],
    'AnnotationImageIO'
);

const AnnotationSoundIO = io.intersection(
    [
        AnnotationBaseIO,
        io.interface({ kind: AnnotationSoundKindIO }),
        io.interface({
            track: io.string, // actually UUIDv4, so we might even do better validation at some point
        }),
    ],
    'AnnotationSoundIO'
);

const AnnotationIO = io.union(
    [AnnotationTextIO, AnnotationImageIO, AnnotationSoundIO],
    'AnnotationIO'
);

export type AnnotationTargetKind = io.TypeOf<typeof AnnotationTargetKindIO>;
export type AnnotationTarget = io.TypeOf<typeof AnnotationTargetIO>;
export type AnnotationKind = io.TypeOf<typeof AnnotationKindIO>;
export type AnnotationText = io.TypeOf<typeof AnnotationTextIO>;
export type AnnotationImage = io.TypeOf<typeof AnnotationImageIO>;
export type AnnotationSound = io.TypeOf<typeof AnnotationSoundIO>;
export type Annotation = io.TypeOf<typeof AnnotationIO>;

export const fetchAnnotationList = (collectId: number) =>
    fetchIO(io.array(AnnotationIO), url(`annotation/${collectId}/`));

export const postTextAnnotation = (
    annotation: Omit<AnnotationText, 'id' | 'timestamp' | 'user'>
) => postIO(AnnotationIO, url(`annotation/`), annotation);

export const postImageAnnotation = (
    annotation: Omit<AnnotationImage, 'id' | 'timestamp' | 'user'>
) => postIO(AnnotationIO, url(`annotation/`), annotation);

export const postSoundTrack = (file: File) =>
    uploadIO(
        io.interface({
            id: io.string,
            url: io.string,
        }),
        '/documents/documents/',
        'file',
        file
    );

export const postSoundAnnotation = (
    annotation: Omit<AnnotationSound, 'id' | 'timestamp' | 'user'>
) => postIO(AnnotationIO, url(`annotation/`), annotation);

const ContactIO = io.interface(
    {
        id: io.Integer,
        name: io.string,
    },
    'ContactIO'
);

const RobotIO = io.interface(
    {
        id: io.Integer,
        name: io.string,
        code: io.string,
    },
    'RobotIO'
);

const CommunityIO = io.interface(
    {
        id: io.Integer,
        name: MessageRecordIO,
        description: MessageRecordIO,
        shortDescription: MessageRecordIO,
        robots: io.array(RobotIO),
        collects: io.array(io.Integer),
        contact: ContactIO,
        geom: MultiPolygonIO,
    },
    'CommunityIO'
);

export type Contact = io.TypeOf<typeof ContactIO>;
export type Robot = io.TypeOf<typeof RobotIO>;
export type Community = io.TypeOf<typeof CommunityIO>;

export const fetchCommunityList = () =>
    fetchIO(io.array(CommunityIO), url('community/'));

const AffinityIO = io.interface(
    {
        id: io.Integer,
        user: io.Integer,
        community: io.Integer,
    },
    'AffinityIO'
);
export type Affinity = io.TypeOf<typeof AffinityIO>;

const SmartwaterUserIO = io.interface(
    {
        id: io.Integer,
        name: io.string,
        email: io.string,
        affinities: io.array(AffinityIO),
    },
    'SmartwaterUserIO'
);
export type SmartwaterUser = io.TypeOf<typeof SmartwaterUserIO>;

const ContributionsIO = io.interface(
    {
        user: SmartwaterUserIO,
        observations: io.array(ObservationIO),
        annotations: io.array(AnnotationIO),
    },
    'ContributionsIO'
);

export type Contributions = io.TypeOf<typeof ContributionsIO>;

export const fetchMe = () => fetchIO(ContributionsIO, url('me/'));

export const postAffinity = (communityId: number) =>
    postUnrelatedIO(AffinityIO, url(`affinity/${communityId}/`), {});

export const deleteAffinity = (affinityId: number) =>
    deleteIO(url(`affinity/delete/${affinityId}`));

const RegisterFormIO = io.interface(
    {
        username: io.string,
        email: io.string,
        password: io.string,
        community: io.Integer,
        consent: io.boolean,
    },
    'RegisterFormIO'
);

export type RegisterForm = io.TypeOf<typeof RegisterFormIO>;

export type RegisterFormCompleted = RegisterForm & {
    passwordControl: string;
    completed: true;
};
export type RegisterFormUnCompleted = Partial<
    RegisterForm & { passwordControl: string }
> & { completed: false };

export type RegisterFormInProgress =
    | RegisterFormCompleted
    | RegisterFormUnCompleted;

export const checkRegisterForm = (
    form: RegisterFormInProgress
): RegisterFormInProgress => {
    if (
        form.username !== undefined &&
        form.username.length > 2 &&
        form.email !== undefined &&
        form.email.length > 4 &&
        form.password !== undefined &&
        form.password.length > 3 &&
        form.passwordControl === form.password &&
        form.community !== undefined &&
        form.consent !== undefined &&
        form.consent
    ) {
        return {
            username: form.username,
            email: form.email,
            password: form.password,
            community: form.community,
            passwordControl: form.passwordControl,
            consent: form.consent,
            completed: true,
        };
    }
    return { ...form, completed: false };
};

export const tryRegisterForm = (
    form: RegisterFormInProgress
): Option<RegisterFormCompleted> => (form.completed ? some(form) : none);

const RegisterErrorIO = io.interface(
    {
        tag: io.literal('error'),
        field: io.union([
            io.literal('username'),
            io.literal('email'),
            io.literal('password'),
            io.literal('community'),
            io.literal('other'),
        ]),
        message: io.union([io.literal('duplicate'), io.literal('other')]),
    },
    'RegisterErrorIO'
);

export type RegisterError = io.TypeOf<typeof RegisterErrorIO>;

const RegisterOKIO = io.interface(
    {
        tag: io.literal('ok'),
        id: io.Integer,
    },
    'RegisterOKIO'
);

const RegisterResultIO = io.union(
    [RegisterErrorIO, RegisterOKIO],
    'RegisterResultIO'
);
export type RegisterResult = io.TypeOf<typeof RegisterResultIO>;

export const postRegister = ({
    username,
    email,
    password,
    community,
}: RegisterFormCompleted) =>
    postUnrelatedIO(RegisterResultIO, url('register/'), {
        username,
        email,
        password,
        community,
    });

export const deleteAnswerRemote = (id: number) =>
    deleteIO(getApiUrl(`/answer/${id}/`));
