import { nameToString } from 'sdi/components/button/names';
import { DETAILS, DIV, H1, SPAN, SUMMARY } from 'sdi/components/elements';
import tr from 'sdi/locale';
import { markdown } from 'sdi/ports/marked';
import { navigateBack } from '../events/route';
import { buttonBack } from './buttons';

const renderTitle = () => H1('title', tr.smartwater('aboutAppTitle'));

const breadCrumb = () =>
    DIV(
        'breadcrumb',
        buttonBack(() => navigateBack())
    );

const aboutDescription = () => DIV('', markdown(tr.smartwater('aboutApp')));

const gdprText = () =>
    DETAILS(
        'gdpr-infos',
        SUMMARY(
            '',
            SPAN('icon', nameToString('info-circle')),
            tr.smartwater('gdprTitle')
        ),
        markdown(tr.smartwater('gdprText'))
    );

export const render = () =>
    DIV(
        'about--inner',
        breadCrumb(),
        renderTitle(),
        aboutDescription(),
        gdprText()
    );

export default render;
