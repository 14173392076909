import { colord } from 'colord';
// import Color = require('color');
import * as debug from 'debug';
import { tryNumber, tryString, uniqId } from '../../util';
import { nameToString } from '../../components/button/names';
import { isENTER } from '../../components/keycodes';
import tr from '../../locale';
import { getLang } from '../../app';
import { BUTTON, DIV, INPUT, LABEL, SPAN, TEXTAREA } from '../elements';
import { Option } from 'fp-ts/lib/Option';
import React = require('react');

const logger = debug('sdi:components/input');

export type Getter<T> = () => T;
export type Setter<T> = (a: T) => void;

export type InputAttributes = React.AllHTMLAttributes<HTMLInputElement> &
    React.RefAttributes<HTMLInputElement>;
export type TextAreaAttributes = React.AllHTMLAttributes<HTMLTextAreaElement> &
    React.RefAttributes<HTMLTextAreaElement>;
export type AllInputAttributes = InputAttributes & React.Attributes;
export type AllTextAreaAttributes = TextAreaAttributes & React.Attributes;

export const CLEAR_INPUT_TEXT = `__${Date.now()}__`;

export interface InputOptions<T, Attrs = AllInputAttributes> {
    key: string;
    get: Getter<T> | Getter<Option<T>>;
    set: Setter<T>;
    attrs: Attrs;
    monitor?: (val: T) => void | undefined;
}

export type TextAreaOptions = InputOptions<string, AllTextAreaAttributes>;

export const options = <T>(
    key: string,
    get: Getter<T> | Getter<Option<T>>,
    set: Setter<T>
): InputOptions<T> => ({
    get,
    set,
    key,
    attrs: {},
    monitor: undefined,
});

export const attrOptions = <T>(
    key: string,
    get: Getter<T> | Getter<Option<T>>,
    set: Setter<T>,
    attrs: AllInputAttributes
): InputOptions<T> => ({
    get,
    set,
    key,
    attrs,
    monitor: undefined,
});

const isOption = (o: unknown): o is Option<unknown> =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    o !== null &&
    typeof o === 'object' &&
    typeof (o as any).toUndefined === 'function';

const getterValue = <T>(get: Getter<T> | Getter<Option<T>>) => {
    const value = get();
    if (isOption(value)) {
        return value.toUndefined();
    }
    return value;
};

const okButton = (onClick: () => void) =>
    BUTTON(
        {
            className: 'input-ok-button',
            onClick,
        },
        SPAN('button-icon', nameToString('check'))
    );

export const inputText = ({
    get,
    set,
    key,
    attrs,
    monitor,
}: InputOptions<string>) => {
    let state = getterValue(get);
    return DIV(
        'input__wrapper--text',
        INPUT({
            ...attrs,
            key: `${key}_${getLang()}`,
            defaultValue: getterValue(get),
            onKeyUp: e => {
                if (isENTER(e)) {
                    set(e.currentTarget.value);
                }
            },
            onBlur: e => set(e.currentTarget.value),
            onChange: e => {
                state = e.currentTarget.value;
                monitor !== undefined ? monitor(state) : undefined;
            },
        }),
        okButton(() => {
            if (state !== undefined) {
                set(state);
            }
        })
    );
};

export const inputLongText2 = ({ get, set, key, attrs }: TextAreaOptions) =>
    DIV(
        'text-area-wrapper',
        TEXTAREA(
            {
                key,
                ...attrs,
                onKeyUp: e => {
                    if (isENTER(e)) {
                        set(e.currentTarget.value);
                    }
                },
                onBlur: e => set(e.currentTarget.value),
            },
            getterValue(get)
        )
    );

export const inputLongText = (
    get: Getter<string>,
    set: Setter<string>,
    attrs: TextAreaAttributes = {}
) =>
    DIV(
        'text-area-wrapper',
        TEXTAREA(
            {
                ...attrs,
                onKeyUp: e => {
                    if (isENTER(e)) {
                        set(e.currentTarget.value);
                    }
                },
                onBlur: e => set(e.currentTarget.value),
            },
            getterValue(get)
        )
    );

type InputValueType = number | string;
type InputType = 'number' | 'string';
const toType = (value: InputValueType, type: InputType) => {
    switch (type) {
        case 'number':
            return tryNumber(value);
        case 'string':
            return tryString(value);
    }
};

export const inputNumber = (
    { key, get, set, attrs, monitor }: InputOptions<InputValueType>,
    valueType: InputType = 'number'
) => {
    let state = getterValue(get);
    let elem: null | HTMLInputElement = null;
    return DIV(
        'input__wrapper--number',
        INPUT({
            key,
            step: 'any',
            ...attrs,
            type: 'number',
            defaultValue: getterValue(get),
            onKeyUp: e => {
                if (isENTER(e)) {
                    toType(e.currentTarget.value, valueType).map(set);
                }
            },
            onBlur: e => toType(e.currentTarget.value, valueType).map(set),
            onChange: e => {
                toType(e.currentTarget.value, valueType).map(n => (state = n));
                if (monitor !== undefined && state !== undefined) {
                    monitor(state);
                }
            },
            // firefox does not focus when using arrows in inputnumber (which implies onblur does not work) - so we have to focus
            onClick: () => {
                if (elem !== null) {
                    elem.focus();
                }
            },
            ref: e => {
                elem = e;
            },
        })
    );
};
export const inputNumberAsStr = ({
    get,
    set,
    key,
    attrs,
    monitor,
}: InputOptions<string>) =>
    inputNumber({ get, set, key, attrs, monitor }, 'string');

export const inputYear = ({
    get,
    set,
    key,
    attrs,
    monitor,
}: InputOptions<string>) =>
    inputNumber(
        { get, set, key, attrs: { pattern: '[0-9]{4}', ...attrs }, monitor },
        'string'
    );

export const inputNullableNumber = ({
    key,
    get,
    set,
    attrs,
    monitor,
}: InputOptions<number | null>) => {
    let state = getterValue(get);
    let elem: null | HTMLInputElement = null;
    return DIV(
        'input-wrapper',
        INPUT({
            key,
            step: 'any',
            ...attrs,
            type: 'number',
            defaultValue: state == null ? undefined : state,
            onKeyUp: e => {
                if (isENTER(e)) {
                    tryNumber(e.currentTarget.value).map(set);
                }
            },
            onBlur: e => tryNumber(e.currentTarget.value).map(set),
            onChange: e => {
                tryNumber(e.currentTarget.value).map(n => (state = n));
                if (monitor !== undefined && state !== undefined) {
                    monitor(state);
                }
            },
            // firefox does not focus when using arrows in inputnumber (which implies onblur does not work) - so we have to focus
            onClick: () => {
                if (elem !== null) {
                    elem.focus();
                }
            },
            ref: e => {
                elem = e;
            },
        })
        // okButton(() => {
        //     set(state);
        // })
    );
};

export const isColor = (colorStr: string) => colord(colorStr).isValid();

// React transforms 'input' to 'change' from nativeElement - we need to override it
const realChange = (handler: (value: string) => void) => {
    return (element: HTMLInputElement | null) => {
        if (element) {
            element.addEventListener('change', () => handler(element.value));
        }
    };
};

// const vivaReact =
//     (set: Setter<string>) => (element: HTMLInputElement | null) => {
//         if (element) {
//             element.addEventListener('change', () => {
//                 if (isColor(element.value)) {
//                     set(element.value);
//                 }
//             });
//         }
//     };

// const inputPlainColor = (
//     get: Getter<string>,
//     set: Setter<string>,
//     attrs?: AllInputAttributes
// ) =>
//     INPUT({
//         ...attrs,
//         type: 'color',
//         defaultValue: get(),
//         ref: realChange(value => {
//             if (isColor(value)) {
//                 set(value);
//             }
//         }),
//     });

const inputPlainColor = (
    get: Getter<string>,
    set: Setter<string>,
    attrs?: AllInputAttributes
) =>
    LABEL(
        'input-plain-label',
        SPAN('label', tr.core('colorHue')),
        INPUT({
            ...attrs,
            type: 'color',
            defaultValue: get(),
            ref: realChange(value => {
                if (isColor(value)) {
                    set(value);
                }
            }),
        })
    );

export const inputAlpha = (
    get: Getter<number>,
    set: Setter<number>,
    attrs?: AllInputAttributes
) =>
    LABEL(
        'input-alpha-label',
        SPAN('label', tr.core('colorOpacity')),
        INPUT({
            ...attrs,
            type: 'range',
            title: `${get()}`,
            min: 0,
            max: 1,
            step: 0.1,
            defaultValue: get(),
            // onChange: e => tryNumber(e.currentTarget.value).map(set),
            ref: realChange(value => tryNumber(value).map(set)),
        })
    );

export const alphaToHex = (alpha: number) => {
    const aHex = Math.floor(alpha * 255);
    const aStr = aHex.toString(16);
    return aHex < 16 ? '0' + aStr : aStr;
};

const rgbaToHex = (rgbaColor: string) => {
    const c = colord(rgbaColor).toHex(); // fixme: returns black if rgbacolor not valid
    logger(`rgba: ${rgbaColor} VS hex: ${c}`);
    return c;
};

export const getPlainColor = (color: string) => () => {
    const c = colord(color).toHex(); // fixme: returns black if rgbacolor not valid
    return c.length > 7 ? c.slice(0, 7) : c;
};

export const updateAlpha = (color: string, alpha: number) => {
    const newColor = `${getPlainColor(color)()}${alphaToHex(alpha)}`;
    return isColor(newColor) ? newColor : color;
};

const setPlainColor =
    (setColor: Setter<string>, getColor: Getter<string>) =>
        (plainColor: string) => {
            const newColor = `${plainColor}${getAlphaStr(getColor())}`;
            if (isColor(newColor)) {
                setColor(newColor);
            } else if (isColor(plainColor) && isColor(rgbaToHex(plainColor))) {
                setColor(rgbaToHex(plainColor));
                // TODO: keep the alpha part
            } else {
                logger(`wrong color code : ${newColor}`);
            }
        };

export const getAlphaNb = (color: string) => () => colord(color).alpha(); // fixme: returns black if rgbacolor not valid

const getAlphaStr = (color: string) => alphaToHex(getAlphaNb(color)());

export const setAlpha =
    (setColor: Setter<string>, getColor: Getter<string>) => (alpha: number) => {
        const newColor = `${getPlainColor(getColor())()}${alphaToHex(alpha)}`;
        isColor(newColor)
            ? setColor(newColor)
            : logger(`wrong color code : ${newColor}`);
    };

export const renderInputPlainColor = (
    getColor: Getter<string>,
    setColor: Setter<string>,
    attrs?: AllInputAttributes
) =>
    inputPlainColor(
        getPlainColor(getColor()),
        setPlainColor(setColor, getColor),
        {
            ...attrs,
            key: `${attrs?.key ?? uniqId()}-input-plain-color`,
        }
    );

export const renderInputAlphaColor = (
    getColor: Getter<string>,
    setColor: Setter<string>,
    attrs?: AllInputAttributes
) =>
    inputAlpha(getAlphaNb(getColor()), setAlpha(setColor, getColor), {
        ...attrs,
        key: `${attrs?.key ?? uniqId()}-input-alpha`,
    });

export const inputColor = (
    get: Getter<string>,
    set: Setter<string>,
    attrs?: AllInputAttributes
) =>
    DIV(
        'input-color-wrapper',
        renderInputPlainColor(get, set, attrs),
        renderInputAlphaColor(get, set, attrs)
    );

export const layerOpacitySelector = (
    getColor: (idx: number) => string,
    setAlpha: Setter<number>
) =>
    inputAlpha(getAlphaNb(getColor(0)), setAlpha, {
        key: `${uniqId()}--input-alpha`,
    });

logger('loaded');
