import { MessageStore, fromRecord } from 'sdi/locale';
import { marked } from './marked-texts';

const messages = {
    ...marked,

    registerErrorDuplicate: {
        fr: 'Un compte existe déjà avec cet e-mail. ',
        nl: 'Er bestaat al een account met deze e-mail.',
        en: 'An account already exists with this e-mail.',
    },
    observationObservedOn: {
        fr: 'Observé le',
        nl: 'Observatie van',
        en: 'Observed on',
    },
    'register/validate': {
        fr: 'Créer un compte',
        nl: 'Account aanmaken',
        en: 'Signup',
    },
    printFormPlace: {
        fr: 'Lieu :',
        nl: 'Locatie:',
        en: 'Location :',
    },
    aboutAppTitle: {
        fr: 'Une application, pour faire quoi ?  ',
        nl: 'Een app, voor welk doel?',
        en: 'An app, what for?',
    },
    btnLabelMap: {
        fr: 'carte',
        nl: 'kaart',
        en: 'map',
    },
    proposeCommunityText: {
        fr: "Si vous voulez qu'un nouvel espace d'attention apparaisse sur l'application, contactez nous à **coordegeb@gmail.com**",
        nl: 'Contacteer ons via **coordegeb@gmail.com** als je een nieuwe aandachtsplek in de app wil laten opnemen',
        en: 'If you want a new attention space to appear on the application, contact us at **coordegeb@gmail.com**',
    },
    loadingLabel: {
        fr: 'Chargement',
        nl: 'Aan het laden',
        en: 'Loading',
    },
    btnLabelCommunity: {
        fr: 'Communauté',
        nl: 'Gemeenschap',
        en: 'Community',
    },
    observedOn: {
        fr: 'Observation du ',
        nl: 'Observatie van',
        en: 'Observed on ',
    },
    userAnnotation: {
        fr: 'Mes annotations',
        nl: 'Mijn aantekeningen',
        en: 'My annotations',
    },
    reportYourObservation: {
        fr: 'Signalez vos observations',
        nl: 'Rapporteer jouw observaties',
        en: 'Report your observations',
    },
    annotateTextPlaceholder: {
        fr: 'Ajoutez une note ou un commentaire à cette réponse',
        nl: 'Voeg een opmerking of commentaar toe aan dit antwoord',
        en: 'Add a note or comment to this reply',
    },
    annotateAudioPlaceholder: {
        fr: 'Ajoutez un enregistrement sonore à cette réponse',
        nl: 'Voeg een geluidsopname toe aan dit antwoord',
        en: 'Add a sound recording to this answer',
    },
    annotateAudioCannotRead: {
        fr: 'Votre navigateur ne permet pas de lire ce fichier ',
        nl: 'Uw browser kan dit bestand niet lezen',
        en: 'Your browser cannot read this file',
    },
    audioDownloadLabel: {
        fr: 'mais vous pouvez le télécharger.',
        nl: 'maar u kunt het wel downloaden.',
        en: 'but you can download it.',
    },
    labelCollectForm: {
        fr: "Prévisualisation du formulaire d'observation",
        nl: 'Preview van het observatieformulier',
        en: 'Observation form preview',
    },
    'field/username': {
        fr: 'Pseudo',
        nl: 'Alias',
        en: 'Pseudo',
    },
    serverError: {
        fr: 'Une erreur est survenue sur le serveur. Ré-essayez plus tard.',
        nl: 'Er is een probleem opgetreden op de server. Probeer het later opnieuw.',
        en: 'An error has occurred on the server. Try again later.',
    },
    stop: {
        fr: 'Arrêter la geolocalisation',
        nl: 'Stop de geolocalisatie',
        en: 'Stop geolocation',
    },
    otherLinkedCollects: {
        fr: "D'autres collectes en lien avec la catégorie",
        nl: 'Andere campagnes in verband met deze categorie',
        en: 'Other campaign linked with the category',
    },
    switchLabelCommunity: {
        fr: 'pour des lieux identifiés',
        nl: 'voor bepaalde locaties',
        en: 'for identified locations',
    },
    anonymous: {
        fr: 'anonyme',
        nl: 'anoniem',
        en: 'anonymous',
    },
    annotateText: {
        fr: 'Texte',
        nl: 'Tekst',
        en: 'Text',
    },
    errorSelectionLabel: {
        fr: "Impossible de trouver l'observation sélectionnée, désolé⋅e",
        nl: 'Sorry, de geselecteerde observatie werd niet gevonden',
        en: 'Sorry, the selected observation could not be found',
    },
    'title/no-login': {
        fr: 'Créer un compte ou se connecter',
        nl: 'Account aanmaken of inloggen',
        en: 'Log in or create an account',
    },
    'field/community': {
        fr: "Espace d'attention",
        nl: 'Aandachtsplek',
        en: 'Attention space',
    },
    findPosition: {
        fr: 'Se géolocaliser',
        nl: 'Geolocalisatie',
        en: 'Geolocation',
    },
    signIn: {
        fr: 'Se connecter',
        nl: 'Inloggen',
        en: 'Log-in',
    },
    'navigate-camera': {
        fr: 'Image',
        nl: 'Foto',
        en: 'Image',
    },
    'navigate-audio': {
        fr: 'Piste audio',
        nl: 'audio', // TODO
        en: 'Soundtrack',
    },
    'navigate/print': {
        fr: 'Imprimer le formulaire',
        nl: 'Het formulier afdrukken',
        en: 'Print the form',
    },
    questionWithoutAnswer: {
        fr: 'Questions en attente de réponse',
        nl: 'Openstaande vragen',
        en: 'Unanswered questions',
    },
    proposeCommunityTitle: {
        fr: "Constituer un espace d'attention",
        nl: 'Een aandachtsplek creëren',
        en: 'Creating an attention space',
    },
    observerLabel: {
        fr: 'Observateur⋅rice',
        nl: 'Waarnemer',
        en: 'Observer',
    },
    communityLogTitle: {
        fr: 'Dernières nouvelles',
        nl: 'Laatste nieuws',
        en: 'Latest news',
    },
    aboutLabel: {
        fr: 'À propos de SmartWater',
        nl: 'Over SmartWater',
        en: 'About SmartWater',
    },
    madeAnObservationOn: {
        fr: 'Le ',
        nl: 'Op ',
        en: '~not translated',
    },
    observationsMap: {
        fr: 'Carte des observations',
        nl: 'Overzichtskaart van de observaties',
        en: 'Map of observations',
    },
    printDisclaimer: {
        fr: 'Pour exporter en .PDF, utilisez les fonctionalité "print to pdf" de votre module d\'impressions.',
        nl: 'Gebruik de "print to pdf" functie van je afdrukmodule om het bestand te exporteren als pdf.',
        en: 'To export in .PDF, use the "print to pdf" function of your printing module.',
    },
    wrongCredentials: {
        fr: "Une erreur s'est sans doute glissée dans votre addresse e-mail ou votre mot de passe, veuillez ré-essayer.",
        nl: 'Het kan zijn dat er een fout in je e-mailadres of wachtwoord is, gelieve opnieuw te proberen.',
        en: 'There may be an error in your email address or password, please try again.',
    },
    back: {
        fr: 'retour',
        nl: 'terug',
        en: 'back',
    },
    'field/password': {
        fr: 'Mot de passe',
        nl: 'Wachtwoord',
        en: 'Password',
    },
    madeAnObservationLink: {
        fr: 'observation',
        nl: 'Observatie',
        en: '~not translated',
    },
    'help/no-login': {
        fr: "Pour participer à une campagne d'observations, nous vous invitons à créer un compte ou à vous connecter. ",
        nl: 'Om deel te nemen aan een observatiecampagne, nodigen wij je uit een account aan te maken of in te loggen.',
        en: 'To participate in an observation campaign, we invite you to create an account or to log in.',
    },
    observedObjectTitle: {
        fr: "Questions relatives au site d'observation",
        nl: 'Vragen met betrekking tot de observatieplaats',
        en: 'Questions relating to the observation site',
    },
    validateLocation: {
        fr: 'Valider la position',
        nl: 'Bevestig de positie',
        en: 'Validate location',
    },
    loadingErrorLabel: {
        fr: 'Erreur de chargement',
        nl: 'Laadfout',
        en: 'Loading error',
    },
    stateProcessing: {
        fr: 'Sauvegarde en cours',
        nl: 'Aan het laden',
        en: 'Saving',
    },
    errorSelectionCollectLabel: {
        fr: 'Impossible de trouver la collecte sélectionnée, désolé⋅e',
        nl: 'Sorry, de geselecteerde campagne werd niet gevonden',
        en: 'Sorry, the selected campaign could not be found',
    },
    bntLabelSeeObservation: {
        fr: "Voir l'observation",
        nl: 'Naar de observatie',
        en: 'See observation',
    },
    placeHolderUserName: {
        fr: '(3 caractères minimum)',
        nl: '(minimaal 3 tekens)',
        en: '',
    },
    'legal/register': {
        fr: 'TEXTE LEGAL',
        nl: 'WETTELIJKE VERMELDINGEN',
        en: 'TEXTE LEGAL',
    },
    saveAndContinue: {
        fr: 'Valider et continuer',
        nl: 'Opslaan en verder gaan',
        en: 'Save and continue',
    },
    btnLabelSend: {
        fr: 'Envoyer',
        nl: 'Verzenden',
        en: 'Send',
    },
    bntLabelMoreInformation: {
        fr: "Plus d'information",
        nl: 'Meer informatie',
        en: 'More information',
    },

    bntLabelInformation: {
        fr: 'Informations',
        nl: 'Informatie',
        en: 'Informations',
    },
    switchLabelRegion: {
        fr: '... ou ailleurs dans la région',
        nl: '... of elders in het gewest',
        en: '... or elsewhere in the region',
    },
    newObservation: {
        fr: 'Nouvelle observation',
        nl: 'Nieuwe observatie',
        en: 'New observation',
    },
    printFormName: {
        fr: '(Sur)Nom :',
        nl: '(Bij)Naam:',
        en: '(Nick)Name :',
    },
    'navigate/participate': {
        fr: 'Participer',
        nl: 'Deelnemen',
        en: 'Participate',
        // fr: "participer à la collecte d'observations",
        // nl: 'deelnemen aan het verzamelen van observaties',
        // en: 'participate',
    },
    observationLocation: {
        fr: "Position de l'observation",
        nl: 'Locatie van de observatie',
        en: 'Observation location',
    },
    thankYou: {
        fr: 'Merci pour votre observation !',
        nl: 'Dank je voor je observatie',
        en: 'Thank you for your observation',
    },
    helptextValidateLocation: {
        fr: 'Vous pourrez ensuite encoder les informations relaitve à cette observation.',
        nl: 'Je kunt vervolgens de informatie met betrekking tot deze observatie invoeren.',
        en: 'to do',
    },
    login: {
        fr: 'Se connecter',
        nl: 'Inloggen',
        en: 'Log in',
    },
    btnLabelHome: {
        fr: 'Accueil',
        nl: 'Home',
        en: 'Home',
    },
    locateObservation: {
        fr: "Localiser l'observation sur la carte",
        nl: 'Lokaliseer de observatie op de kaart',
        en: 'Locate the observation on the map',
    },
    observationSaved: {
        fr: 'Observation enregistrée',
        nl: 'Observatie opgeslagen',
        en: 'Observation saved',
    },
    annotateImagePlaceholder: {
        fr: 'Ajoutez une image à cette réponse',
        nl: 'Voeg een afbeelding toe aan dit antwoord',
        en: '~not translated',
    },
    labelObservationSingle: {
        fr: 'observation',
        nl: 'observatie',
        en: 'observation',
    },
    updateDateTime: {
        fr: 'Mettre à jour la date',
        nl: 'De datum bijwerken',
        en: 'Update date time',
    },
    labelEditionMode: {
        fr: 'mode édition',
        nl: 'bewerkmodus',
        en: 'edition mode',
    },
    creditUrbis: {
        fr: 'Fond de plan © UrbIS. ',
        nl: 'Achtergrond © UrbIS.',
        en: 'Basemap © UrbIS',
    },
    'navigate-comment': {
        fr: 'Commentaire',
        nl: 'Commentaar',
        en: 'Comment',
    },
    gdprTitle: {
        fr: 'Information relative à la protection des données personnelles',
        nl: 'Informatie betreffende de bescherming van persoonsgegevens',
        en: 'Information regarding the protection of personal data ',
    },
    addTextTitle: {
        fr: 'Annoter avec un texte',
        nl: 'Annoteren met een tekst',
        en: 'Annotate with a text',
    },
    madeAnObservation: {
        fr: 'a fait une ',
        nl: 'heeft een ',
        en: '~not translated',
    },
    'field/control-password': {
        fr: 'Vérification du mot de passe',
        nl: 'Wachtwoordverificatie',
        en: 'Password check',
    },
    addImageTitle: {
        fr: 'Annoter avec une image',
        nl: 'Annoteren met een foto',
        en: 'Annotate with an image',
    },
    addSoundTitle: {
        fr: 'Annoter avec un enregistrement audio',
        nl: 'Annoteren met audio-opname',
        en: 'Annotate with audio recording',
    },
    signup: {
        fr: 'Créer un compte',
        nl: 'Account aanmaken',
        en: 'Create an account',
    },
    userObservation: {
        fr: 'Mes observations',
        nl: 'Mijn observaties',
        en: 'My observations',
    },
    footerDataLicence: {
        fr: 'Licences : Application [AGPL-3.0](https://www.gnu.org/licenses/agpl-3.0.en.html) - Données [CC BY 4.0](https://creativecommons.org/licenses/by/4.0/deed.fr) ',
        nl: 'Licenties : App [AGPL-3.0](https://www.gnu.org/licenses/agpl-3.0.en.html) - Data [CC BY 4.0](https://creativecommons.org/licenses/by/4.0/deed.nl)',
        en: '[AGPL-3.0](https://www.gnu.org/licenses/agpl-3.0.en.html) - Data [CC BY 4.0](https://creativecommons.org/licenses/by/4.0/deed.en)',
    },
    'error.noCampaign': {
        fr: 'Désolé, impossible de trouver la campagne sélectionnée',
        nl: 'Sorry, de geselecteerde campagne werd niet gevonden',
        en: 'Sorry, the selected campaign could not be found',
    },
    creditInnoviris: {
        fr: '[SmartWater](https://smartwater.brussels) est un projet subsidié par la Région de Bruxelles-Capitale - Innoviris ',
        nl: '[SmartWater](https://smartwater.brussels) is een project gesubsidieerd door het Brussels Hoofdstedelijk Gewest - Innoviris ',
        en: '[SmartWater](https://smartwater.brussels) is a project subsidised by the Brussels-Capital Region - Innoviris ',
    },
    homeTitle: {
        fr: "SmartWater, prendre soin de l'eau collectivement",
        nl: 'SmartWater, samen zorg dragen voor onze waterlichamen',
        en: 'Smartwater, caring for our waters collectively',
    },
    regionCommunityDescription: {
        fr: "Si votre observation n'est pas liée à un des lieux listés ci-dessus, vous pouvez l'indiquer dans la communauté de savoirs et d'actions qui se construit à l'échelle régionale.",
        nl: 'Als je observatie geen verband houdt met een van de hierboven weergegeven aandachtsplekken, kun je dit aangeven in de kennis- en actiegemeenschap die op regionaal niveau wordt opgebouwd.',
        en: 'If your observation is not linked to one of the places listed above, you can indicate it in the community of knowledge and action that is being built at the regional level.',
    },
    btnLabelSee: {
        fr: 'Voir',
        nl: 'Bekijken',
        en: 'See',
    },
    loginTitle: {
        fr: 'Utiliser un compte existant',
        nl: 'Gebruik een bestaand account',
        en: 'I already have an account',
    },
    registerErrorOther: {
        fr: 'Une erreur méconnue est survenue, désolée.',
        nl: 'Er is een fout opgetreden, sorry.',
        en: 'An unknown error occurred, sorry.',
    },
    observationObservedBy: {
        fr: 'par',
        nl: 'door',
        en: 'by',
    },
    printFormComment: {
        fr: 'Commentaire :',
        nl: 'Commentaar',
        en: 'Comment :',
    },
    bntLabelSaveAndContinue: {
        fr: 'Sauver et continuer',
        nl: 'Opslaan en verder gaan',
        en: 'Save and continue',
    },
    labelObservationMultiple: {
        fr: 'observations',
        nl: 'observaties',
        en: 'observations',
    },
    annotateLabel: {
        fr: 'Annoter',
        nl: 'Annoteren',
        en: 'Annotate',
    },
    btnAddObservation: {
        // fr: 'Ajouter une observation',
        // nl: 'Een observatie toevoegen',
        // en: 'Add an observation',
        fr: 'Observation',
        nl: 'Observatie',
        en: 'Observation',
    },
    'legal/consent': {
        fr: "J'accepte que le contenu que je partage sur cette plateforme soit rendu disponible sous licence [CC BY 4.0](https://creativecommons.org/licenses/by/4.0/deed.fr)",
        nl: 'Ik ga ermee akkoord dat de inhoud die ik deel op dit platform beschikbaar kan worden gesteld onder een licentie [CC BY 4.0](https://creativecommons.org/licenses/by/4.0/deed.nl)',
        en: 'I agree that the content I share on this platform may be made available under a license [CC BY 4.0](https://creativecommons.org/licenses/by/4.0/deed.en)',
    },
    'field/e-mail': {
        fr: 'E-mail',
        nl: 'E-mail',
        en: 'E-mail',
    },
    collectDescriptionTitle: {
        fr: 'Description',
        nl: 'beschrijving',
        en: 'Description',
    },
    unknownError: {
        fr: 'Une erreur mystérieuse, nous ne savons pas vraiment quoi en faire, peut-être rien.',
        nl: 'Een mysterieuze vergissing, we weten niet echt wat we ermee moeten doen, misschien niets.',
        en: "A mysterious mistake, we don't really know what to do with it, maybe nothing.",
    },
    situatedObserverTitle: {
        fr: "Questions relatives à l'observateur·trice",
        nl: 'Vragen met betrekking tot de waarnemer',
        en: 'Questions relating to the observer',
    },
    'user-profile': {
        fr: 'Mon compte',
        nl: 'Mijn account',
        en: 'to do',
    },
    'title/register': {
        fr: 'Créer un compte',
        nl: 'Account aanmaken',
        en: 'Create an account',
    },
    stateSuccess: {
        fr: 'Enregistré!',
        nl: 'Opgeslagen!',
        en: 'Saved!',
    },
    regionCommunityTitle: {
        fr: 'quelque part en Region Bruxelles Capitale',
        nl: 'ergens in het Brussels Hoofdstedelijk Gewest',
        en: 'somewhere in the Brussels Capital Region',
    },
    campaignListTitle: {
        fr: "Collectes d'observation pour ce lieu",
        nl: 'Observatiecampagne voor deze plek',
        en: 'Observation campaign for this place',
    },
    madeAnObservation2: {
        fr: '',
        nl: 'gemaakt',
        en: '~not translated',
    },
    stateError: {
        fr: 'Erreur',
        nl: 'Error',
        en: 'Error',
    },
    printFormDate: {
        fr: 'Date : ',
        nl: 'Datum : ',
        en: 'Date : ',
    },
    labelAbout: {
        fr: 'A propos',
        nl: 'Over',
        en: 'About',
    },
    annotateImage: {
        fr: 'Image',
        nl: 'Foto ',
        en: 'Image',
    },
    homeSubTitle: {
        fr: 'Collectez vos observations',
        nl: 'Verzamel je observaties',
        en: 'Collect your observations',
    },
    forgotPassword: {
        fr: "J'ai oublié mon mot de passe",
        nl: 'Ik ben mijn wachtwoord vergeten',
        en: 'Forgot password',
    },
    communityContactLabel: {
        fr: "Contact pour ce lieu d'observation",
        nl: 'Contact voor deze observatieplek',
        en: 'Contact for this places',
    },
    communityListTitle: {
        fr: "Lieux d'observations",
        nl: 'Observatieplekken',
        en: 'Observations places',
    },
    observedLabel: {
        fr: "Sujet de l'observation",
        nl: 'Onderwerp van de observatie',
        en: 'Topic of observation',
    },
    'log/on': {
        fr: `Le`,
        nl: `Op`, //nl to check
        en: `On`,
    },
    'log/answered': {
        fr: `à répondu à :`,
        nl: `antwoordde :`, //nl to check
        en: `answered to :`,
    },

    notifications: {
        en: 'E-mail Notifications',
        fr: 'Notifications mail',
        nl: 'E-mailmeldingen',
    },
    unsubscribed: {
        en: 'unsubscribed',
        fr: 'unsubscribed',
        nl: 'unsubscribed',
    },
    subscribed: {
        en: 'subscribed',
        fr: 'subscribed',
        nl: 'subscribed',
    },
    seeCollectTabLabel: {
        en: 'Collect',
        fr: 'Collectes',
        nl: 'Collectes',
    },
    seeLogTabLabel: {
        en: 'News',
        fr: 'Journal',
        nl: 'JournalNL',
    },
    legendLabel1: {
        en: 'Less than a week old',
        fr: `Depuis moins d'une semaine`,
        nl: 'Minder dan een week',
    },
    legendLabel2: {
        en: 'Between a week and a month old',
        fr: 'Entre une semaine et un mois',
        nl: 'Tussen een week en een maand',
    },
    legendLabel3: {
        en: 'More than a month old',
        fr: `Il y a plus d'un mois`,
        nl: 'Meer dan een maand geleden',
    },
};

type MDB = typeof messages;
export type SmartwaterMessageKey = keyof MDB;

declare module 'sdi/locale' {
    export interface MessageStore {
        smartwater(k: SmartwaterMessageKey): Translated;
    }
}

MessageStore.prototype.smartwater = (k: SmartwaterMessageKey) =>
    fromRecord(messages[k]);
