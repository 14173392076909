import { DIV, NodeOrOptional, H1, SPAN } from 'sdi/components/elements';
import { Contributions, Observation, SmartwaterUser } from '../remote';
import {
    findCommunity,
    getAnswers,
    getContributions,
} from '../queries/collect';
import tr, { fromRecord } from 'sdi/locale';
import { foldRemote } from 'sdi/source';
import { buttonBack, logoutButton } from './buttons';
import { navigateBack, navigateCommunity } from '../events/route';

import { tryLogout } from '../events/login';
import { dateISOFormat } from 'sdi/util';
import { renderLogAnswer } from './community';

const breadCrumb = () =>
    DIV(
        'breadcrumb',
        buttonBack(() => navigateBack()),
        logoutButton(tryLogout)
    );

const renderNoAuth = () =>
    DIV('no-auth', "You shouldn't be here in the first place");

const renderUser = (u: SmartwaterUser) => DIV('u', u.name);

// const renderObservation = (o: Observation) =>
//     DIV(
//         'o',
//         SPAN('date', formatDate(new Date(o.timestamp))),
//         buttonNavigateObservation(() => navigateObservation(o.id))
//     );

// const renderObservations = (os: Observation[]) =>
//     DIV('o-list', ...os.map(renderObservation));

// const renderAnnotationMeta = (a: Annotation) =>
//     DIV('note meta', SPAN('date', formatDate(new Date(a.timestamp))));

// const renderAnnotationText = (a: AnnotationText) =>
//     DIV('note text', renderAnnotationMeta(a), markdown(a.text));

// const renderAnnotationImage = (a: AnnotationImage) =>
//     DIV(
//         'note image',
//         renderAnnotationMeta(a),
//         IMG({
//             className: 'user-image',
//             src: `/documents/images/${a.image}?size=medium`,
//         })
//     );

// const renderAnnotation = (a: Annotation) => {
//     switch (a.kind) {
//         case 'text':
//             return renderAnnotationText(a);
//         case 'image':
//             return renderAnnotationImage(a);
//     }
// };

// const renderAnnotations = (as: Annotation[]) =>
//     DIV('a-list', ...as.map(renderAnnotation));

const renderCommunityLink = (cid: number) =>
    DIV(
        'community-link',
        findCommunity(cid).map(c =>
            SPAN(
                {
                    onClick: () => navigateCommunity(cid),
                },
                fromRecord(c.name)
            )
        )
    );

const renderObservation = (o: Observation) =>
    DIV(
        `log id-${o.id}`,
        DIV(
            'log-meta',
            `${tr.smartwater('log/on')} ${dateISOFormat(
                new Date(o.timestamp)
            )}`,
            o.communities.map(renderCommunityLink)
        ),
        ...getAnswers(o.id).map(renderLogAnswer(o))
    );

const renderContributions = foldRemote<Contributions, string, NodeOrOptional>(
    () => DIV('none'),
    () => DIV('loading', tr.core('loadingData')),
    err => DIV('error', err),
    ({ user, observations }) =>
        DIV('parts', renderUser(user), ...observations.map(renderObservation))
);

export const render = () =>
    DIV(
        'user__wrapper',
        breadCrumb(),
        H1('', tr.smartwater('user-profile')),
        getContributions().fold(renderNoAuth, renderContributions)
    );

export default render;
