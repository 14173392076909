import {
    IMapInfo,
    IMapBaseLayer,
    Feature,
    ILayerInfo,
    StyleConfig,
    Inspire,
    FeatureCollection,
    MultiPolygon,
} from 'sdi/source';
import { queryK } from 'sdi/shape';
import {
    getObservations,
    getAnswers,
    getQuestionForAnswer,
    getSelectedCollect,
} from './collect';
import { Observation, Collect } from '../remote';
import { nameToCode } from 'sdi/components/button/names';
import { getRootUrl } from 'sdi/app';
import { some } from 'fp-ts/lib/Option';
import { flatten } from 'sdi/util';
import { Extent } from 'ol/extent';

export const getBaseLayer = (): IMapBaseLayer => ({
    codename: 'ign-topo',
    name: {
        fr: 'orthophoto_Urbis',
        nl: 'orthophoto_Urbis',
    },
    // srs: 'EPSG:3857',
    srs: 'EPSG:31370',
    // url: 'https://wms.ngi.be/cartoweb/1/service',
    url: 'https://geoservices-urbis.irisnet.be/geoserver/ows',
    params: {
        VERSION: '1.1.1',
        LAYERS: {
            // fr: 'topo',
            // nl: 'topo',
            fr: 'Ortho2020,UrbisAdm:Adpt,UrbisAdm:ToNameLine',
            nl: 'Ortho2020,UrbisAdm:Adpt,UrbisAdm:ToNameLine',
        },
    },
});

export const getMapView = queryK('port/map/view');
export const getMapInteraction = queryK('port/map/interaction');
export const getMapSelection = queryK('port/map/selection');

export const getMapObservationView = queryK('port/map/observation/view');

export const MAP_COLLECT_NAME = 'collect-map';

export const getMapInfo = (): IMapInfo | null =>
    getSelectedCollect()
        .map<IMapInfo>(c => ({
            baseLayer: 'urbis.irisnet.be/urbis_gray',
            id: c.id.toString(),
            id_origin: c.id.toString(),
            url: getRootUrl(`collect/map/${c.id}`),
            lastModified: c.timestamp,
            status: 'published',
            title: c.title,
            description: c.description,
            categories: [],
            attachments: [],
            layers: [makeLayerInfo(c.id)],
        }))
        .toNullable();

const linkLayerInfo = (collectId: number): ILayerInfo => ({
    id: `${collectId}-link`,
    featureViewOptions: { type: 'default' },
    group: null,
    layerInfoExtra: null,
    legend: null,
    metadataId: `${collectId}-link`,
    visible: true,
    style: linkLayerStyle,
    visibleLegend: true,
    opacitySelector: false,
});

const linkLayerStyle: StyleConfig = {
    kind: 'line-simple',
    dash: [],
    strokeColor: 'black',
    strokeWidth: 1,
};

export const linkLayerMetadata = (collect: Collect): Inspire => ({
    id: `${collect.id}-link`,
    geometryType: 'LineString',
    resourceTitle: collect.title,
    resourceAbstract: collect.description,
    uniqueResourceIdentifier: `none://${collect.id}.link`,
    topicCategory: [],
    keywords: [],
    geographicBoundingBox: { west: 0.0, north: 0.0, east: 0.0, south: 0.0 },
    temporalReference: {
        creation: new Date(collect.timestamp).toLocaleDateString(),
        revision: new Date().toLocaleDateString(),
    },
    responsibleOrganisation: [1],
    metadataPointOfContact: [1],
    metadataDate: Date(),
    published: false,
    dataStreamUrl: null,
    maintenanceFrequency: 'continual',
});

const featureLinkFromObservation = (o: Observation): Feature => ({
    id: o.id,
    type: 'Feature',
    geometry: {
        type: 'Point',
        coordinates: o.observed.coordinates,
    },
    properties: makeObsProps(o),
});

export const makeLinkLayerFromCollect = (
    collectId: number
): FeatureCollection => ({
    type: 'FeatureCollection',
    features: getObservations(collectId).map(featureLinkFromObservation),
});

const makeLayerInfo = (collectId: number): ILayerInfo => ({
    id: `${collectId}`,
    featureViewOptions: { type: 'default' },
    group: null,
    layerInfoExtra: null,
    legend: null,
    metadataId: `${collectId}`,
    visible: true,
    style: makeStyle(),
    visibleLegend: true,
    opacitySelector: false,
});

const makeObsProps = (o: Observation) => {
    const props: { [k: number]: number } = {};
    getAnswers(o.id).forEach(a =>
        getQuestionForAnswer(a).map(q => {
            props[q.id] = a.choice;
        })
    );
    return { ...props, age: o.age };
};

const featureFromObservation = (o: Observation): Feature => ({
    id: o.id,
    type: 'Feature',
    geometry: o.observed,
    properties: makeObsProps(o),
});

export const makeLayerFromCollect = (collectId: number): FeatureCollection => ({
    type: 'FeatureCollection',
    features: getObservations(collectId).map(featureFromObservation),
});

const makeStyle = (): StyleConfig => ({
    kind: 'point-continuous',
    propName: 'age',
    intervals: [
        {
            low: 0,
            high: 7,
            label: {},
            marker: {
                codePoint: nameToCode('PentagonEFill'),
                size: 20,
                color: '#ff6684',
            },
        },
        {
            low: 7,
            high: 30,
            label: {},
            marker: {
                codePoint: nameToCode('PentagonEFill'),
                size: 20,
                color: '#ff6684bf',
            },
        },
        {
            low: 30,
            high: 10000,
            label: {},
            marker: {
                codePoint: nameToCode('PentagonEFill'),
                size: 20,
                color: '#ff668480',
            },
        },
    ],
});

export const makeMetadataFromCollect = (collect: Collect): Inspire => ({
    id: `${collect.id}`,
    geometryType: 'Point',
    resourceTitle: collect.title,
    resourceAbstract: collect.description,
    uniqueResourceIdentifier: `none://${collect.id}`,
    topicCategory: [],
    keywords: [],
    geographicBoundingBox: { west: 0.0, north: 0.0, east: 0.0, south: 0.0 },
    temporalReference: { creation: Date(), revision: Date() },
    responsibleOrganisation: [1],
    metadataPointOfContact: [1],
    metadataDate: Date(),
    published: false,
    dataStreamUrl: null,
    maintenanceFrequency: 'continual',
});

export const makeLayerInfoOptionFromCollect = (collect: Collect) => () =>
    some({
        name: collect.title,
        info: makeLayerInfo(collect.id),
        metadata: makeMetadataFromCollect(collect),
    });

export const makeLinkLayerInfoOptionFromCollect = (collect: Collect) => () =>
    some({
        name: collect.title,
        info: linkLayerInfo(collect.id),
        metadata: linkLayerMetadata(collect),
    });

export const isTracking = () => getMapInteraction().label === 'track';

export const getMultiPolygonExtent = (polygon: MultiPolygon) => {
    const lines = flatten(polygon.coordinates.map(poly => flatten(poly)));
    const extent = lines.reduce<Extent>(
        ([minx, miny, maxx, maxy], coord) => [
            Math.min(minx, coord[0]),
            Math.min(miny, coord[1]),
            Math.max(maxx, coord[0]),
            Math.max(maxy, coord[1]),
        ],
        [Number.MAX_VALUE, Number.MAX_VALUE, Number.MIN_VALUE, Number.MIN_VALUE]
    );
    return extent;
};
