import { makeLabelAndIcon, makeIcon, makeLabel } from 'sdi/components/button';
import { tooltipConfig } from 'sdi/components/tooltip';
import tr, { fromRecord } from 'sdi/locale';
import { MessageRecord } from 'sdi/source';

export const renderButtonMap = makeLabelAndIcon('navigate', 3, 'map', () =>
    tr.smartwater('btnLabelMap')
);

export const renderButtonViewCollect = makeLabelAndIcon(
    'navigate',
    2,
    'info-circle',
    () => tr.smartwater('bntLabelInformation')
);
export const logoutButton = makeLabelAndIcon('logout', 2, 'times', () =>
    tr.core('logout')
);

export const renderButtonAddObervation = makeLabelAndIcon(
    'navigate',
    1,
    'plus',
    () => tr.smartwater('btnAddObservation')
);

export const renderButtonSignup = makeLabelAndIcon(
    'navigate',
    1,
    'chevron-right',
    () => tr.smartwater('signup')
);

export const renderButtonHome = makeLabelAndIcon(
    'navigate',
    2,
    'chevron-left',
    () => tr.smartwater('btnLabelHome')
);

export const renderButtonCommunity = (communityName: MessageRecord) =>
    makeLabelAndIcon('navigate', 2, 'chevron-left', () =>
        fromRecord(communityName)
    );

export const buttonNavigateObservation = makeLabelAndIcon(
    'navigate',
    2,
    'chevron-right',
    () => tr.smartwater('btnLabelSee')
);

export const renderButtonViewObservation = makeLabelAndIcon(
    'navigate',
    2,
    'eye',
    () => tr.smartwater('bntLabelSeeObservation')
);

export const buttonSend = makeLabelAndIcon('save', 1, 'save', () =>
    tr.smartwater('btnLabelSend')
);
export const buttonCancel = makeLabelAndIcon('cancel', 2, 'times', () =>
    tr.core('cancel')
);
export const buttonBack = makeLabelAndIcon('cancel', 2, 'chevron-left', () =>
    tr.smartwater('back')
);

export const buttonAnnotateText = makeLabel('navigate', 3, () =>
    tr.smartwater('annotateLabel')
);
export const buttonAnnotateImage = makeIcon(
    'navigate',
    3,
    'camera',
    tooltipConfig(() => tr.smartwater('navigate-camera'))
);
export const buttonAnnotateSound = makeIcon(
    'navigate',
    3,
    'volume-up',
    tooltipConfig(() => tr.smartwater('navigate-audio'))
);
export const buttonClose = makeIcon(
    'close',
    3,
    'times',
    tooltipConfig(() => tr.smartwater('back')) // FIXME
);

export const buttonLargeAnnotateText = makeLabelAndIcon(
    'navigate',
    3,
    'comment',
    () => tr.smartwater('annotateText')
);
export const buttonLargeAnnotateImage = makeLabelAndIcon(
    'navigate',
    3,
    'camera',
    () => tr.smartwater('annotateImage')
);

export const profileButton = makeLabelAndIcon('navigate', 2, 'user', () =>
    tr.smartwater('user-profile')
);

export const buttonValidateRegister = makeLabelAndIcon(
    'validate',
    1,
    'chevron-right',
    () => tr.smartwater('register/validate')
);

export const renderButtonPrintForm = makeLabelAndIcon(
    'navigate',
    2,
    'print',
    () => tr.smartwater('navigate/print')
);

export const renderButtonParticipate = makeLabelAndIcon(
    'navigate',
    1,
    'chevron-right',
    () => tr.smartwater('navigate/participate')
);

export const renderButtonPrint = makeLabelAndIcon('validate', 1, 'print', () =>
    tr.smartwater('navigate/print')
);
