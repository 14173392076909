import * as debug from 'debug';
import { some } from 'fp-ts/lib/Option';

import { scopeOption } from '../lib';
import { getAppManifest, getRootUrl, setRoute } from '../app';
import { Nullable, isNotNullNorUndefined } from '../util';
import { appRoute } from '../source';

const logger = debug('sdi:route');

interface Stringer {
    toString(): string;
}

export type Pather = Stringer[];
export type Path = string[];
export type RouteParser<R> = (p: Path) => R;
export type RouteEvent<R> = (parsed: R, raw: Path) => void;

const defaultRouteParser = <R>(p: R) => p;

// tslint:disable-next-line: variable-name
export const Router = <T extends string>(appName: string) => {
    type InternalT = T | '';

    interface Route {
        kind: InternalT;
        path: Path;
    }

    const hasHistory =
        typeof window !== 'undefined' &&
        window.history &&
        window.history.pushState;

    let handlers = scopeOption().let(
        '__null_route__' as InternalT,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        some((_p: Path) => void 0)
    );

    const cleanPath = (p: Path) =>
        p.reduce((acc, s) => {
            if (s.length > 0) {
                return acc.concat([s]);
            }
            return acc;
        }, [] as Path);

    if (hasHistory) {
        window.onpopstate = (event: PopStateEvent) => {
            const s = event.state as Nullable<Route>;
            if (isNotNullNorUndefined(s)) {
                logger(`onpopstate: ${s.kind} ${s.path}`);
                navigateInternal(s.kind, s.path);
            } else {
                navigateInternal('', []);
                logger('onpopstate undefined');
            }
        };
    }

    const handleRoute = (t: InternalT, path: Path) =>
        handlers.pick(t).foldL(
            () => logger(`RouteNotFound: ${t}`),
            handler => {
                const cp = cleanPath(path);
                logger(`Navigate(${t}): ${cp}`);
                handler(cp);
            }
        );

    const pushState = (kind: InternalT, path: Path) => {
        if (!hasHistory) {
            return;
        }
        logger(`pushState:${kind} ${path}`);
        getAppManifest(appName).map(manifest => {
            const routeName = appRoute(manifest);
            const state: Route = { kind, path };
            const url = [getRootUrl(routeName), kind].concat(path).join('/');
            window.history.pushState(state, kind, url);
        });
    };

    const route = <R = Path>(
        r: T,
        e: RouteEvent<R>,
        parser = defaultRouteParser as RouteParser<R>
    ) => {
        const event = (p: Path) => e(parser(p), p);
        handlers = handlers.let(r, some(event));
    };

    const home = <R = Path>(
        r: T,
        e: RouteEvent<R>,
        parser = defaultRouteParser as RouteParser<R>
    ) => {
        const event = (p: Path) => e(parser(p), p);
        handlers = handlers.let('', some(event)).let(r, some(event));
    };
    const navigateInternal = (t: InternalT, path: Pather) => {
        const stringPath = path.map(a => a.toString());
        setRoute([t.toString()].concat(stringPath));
        handleRoute(t, stringPath);
        return stringPath;
    };

    const navigate = (t: InternalT, path: Pather, skipState = false) => {
        if (skipState) {
            navigateInternal(t, path);
        } else {
            pushState(t, navigateInternal(t, path));
        }
    };

    const back = () => {
        if (hasHistory) {
            window.history.back();
        }
    };

    return { home, route, navigate, back };
};

/**
    I know it's rather dry as far as documentation goes, but, well, should be enough
    
    const { route, navigate } = Router<('a' | 'b')>();
    
    route('a', p => logger('route', ...p));
    route('b', p => logger(p), (p) => 1);

    navigate('a', ['foo', 2]);
    >> route foo 2
    navigate('b', []);
    >> 1
 */

logger('loaded');
