import { Nullable } from 'sdi/util';
import { Credentials, IUser, IUserList } from 'sdi/source';
import { Layout, Route } from '../events/route';
import { ButtonComponent } from 'sdi/components/button';
import { emptyCredentials } from '../events/login';
import { Path } from 'sdi/router';
import { RegisterError } from '../remote';

// State Augmentation
declare module 'sdi/shape' {
    export interface IShape {
        'app/layout': Layout;
        'app/selectd-item': Nullable<string>;
        'data/user': Nullable<IUser>;
        'component/button': ButtonComponent;

        'navigate/next': Nullable<Route>;
        'app/name': string;
        'data/users': IUserList;

        'component/login': Credentials;
        'component/login/error': number | null;
        'component/login/from': Path;

        'component/register/error': Nullable<RegisterError>;
    }
}

export const defaultAppShape = () => ({
    'app/layout': 'home' as Layout,
    'app/selectd-item': null,
    'data/user': null,
    'component/button': {},
    'navigate/next': null,
    'app/name': '',
    'data/users': [],
    'component/login': emptyCredentials(),
    'component/login/error': null,
    'component/login/from': [],
    'component/register/error': null,
});
