import { loop, getUserId } from 'sdi/app';
import { DIV } from 'sdi/components/elements';
import header from './components/header';
import footer from './components/footer';

import { loadRoute } from './events/route';
import { getLayout } from './queries/app';

import home from './components/home';
import login from './components/login';
import collect from './components/collect';
import community from './components/community';
import user from './components/user';
import observation from './components/observation';
import observationForm from './components/form/observation';
import answerForm from './components/form/answer';
import annotationForm from './components/form/annotation';
import register from './components/form/register';
import print from './components/form/print';
import { loadAllUsers, loadUser } from './events/app';
import { loadCommunityList, loadContributions } from './events/collect';
import about from './components/about';
import { createCommunityImg } from './components/minimap';

const wrappedMain = (
    name: string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    ...elements: React.DOMElement<{}, Element>[]
) =>
    DIV(
        { className: 'viewport smartwater', key: name },
        header('smartwater'),
        DIV({ className: `main ${name}` }, ...elements),
        footer()
    );

const renderHome = () => wrappedMain('home', home());

const renderLogin = () => wrappedMain('login', login());

const renderAbout = () => wrappedMain('about', about());

// const renderMap = () => wrappedMain('map', map());

const renderCommunity = () => wrappedMain('community', community());

const renderUser = () => wrappedMain('user', user());

const renderRegister = () => wrappedMain('register', register());

const renderCollect = () => wrappedMain('collect', collect());

const renderObservation = () => wrappedMain('observation', observation());

const renderObservationForm = () =>
    wrappedMain('observation-form', observationForm());

const renderAnswerForm = () => wrappedMain('answer-form', answerForm());

const renderAnnotationForm = () =>
    wrappedMain('annotation-form', annotationForm());

const renderFormPrint = () => wrappedMain('observation-form-print', print());

const render = () => {
    // const layout = getLayout();
    switch (getLayout()) {
        case 'home':
            return renderHome();
        case 'login':
            return renderLogin();
        case 'community':
            return renderCommunity();
        case 'user':
            return renderUser();
        case 'register':
            return renderRegister();
        case 'collect':
            return renderCollect();
        case 'observation':
            return renderObservation();
        case 'observation-form':
            return renderObservationForm();
        case 'observation-form-print':
            return renderFormPrint();
        case 'answer-form':
            return renderAnswerForm();
        case 'annotation-form':
            return renderAnnotationForm();
        case 'about':
            return renderAbout();
    }
};

const effects = (initialRoute: string[]) => () => {
    loadRoute(initialRoute);
    loadCommunityList().then(c => c.map(com => createCommunityImg(com)));
    loadUser();
    getUserId().map(loadContributions);
    loadAllUsers();
};

export const app = (initialRoute: string[]) =>
    loop('smartwater', render, effects(initialRoute));
