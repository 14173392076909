/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import { fromNullable } from 'fp-ts/lib/Option';

import { query, queryK } from 'sdi/shape';

export const getCredentials = queryK('component/login');

export const getUsername = () => getCredentials().username;

export const getPassword = () => getCredentials().password;

export type LoginError = 'auth' | 'server' | 'unknown';

export const getLoginError = () =>
    fromNullable(query('component/login/error')).map<LoginError>(code => {
        switch (code) {
            case 401:
                return 'auth';
            case 500:
                return 'server';
            default:
                return 'unknown';
        }
    });

export const getFrom = queryK('component/login/from');

export const getUserData = () => fromNullable(query('data/user'));
