import { DIV, A, SPAN } from 'sdi/components/elements';
import tr from 'sdi/locale';
import { markdown } from 'sdi/ports/marked';
// import { navigateAbout } from '../events/route';

// const swAppLink = () =>
//     DIV(
//         {
//             onClick: navigateAbout,
//         },
//         tr.smartwater('footerLabelAbout')
//     );

const creditUrbis = () =>
    DIV('credit urbis-credit', tr.smartwater('creditUrbis'));

const csLink = () =>
    SPAN(
        { className: 'cs-link', title: 'cartostation' },
        A(
            { href: 'https://cartostation.com', target: '_blank' },
            `cartostation`
        )
    );

const acLink = () =>
    SPAN(
        { className: 'ac-link', title: 'atelier cartographique' },
        A(
            { href: 'https://atelier-cartographique.be', target: '_blank' },
            `atelier cartographique`
        )
    );

const csacCredits = () => DIV('credit csac-credits', csLink(), '©', acLink());

const fundCredit = () =>
    DIV('fund-credit', markdown(tr.smartwater('creditInnoviris')));

const licence = () =>
    DIV('licence', markdown(tr.smartwater('footerDataLicence')));

// const footerInfos = () => DIV('footer-infos', swAppLink());

const render = () =>
    DIV(
        'footer',
        // footerInfos(),
        fundCredit(),
        creditUrbis(),
        csacCredits(),
        licence()
    );

export default render;
