import {
    Collect,
    Question,
    Choice,
    Observation,
    Answer,
    AnnotationTarget,
    Annotation,
    AnnotationKind,
    Community,
    Contributions,
    RegisterFormInProgress,
    AnnotationTargetKind,
    CollectCategory,
} from '../remote';
import { RemoteResource, remoteNone, Point } from 'sdi/source';
import { Nullable, Collection } from 'sdi/util';
import { Interaction, InteractionCreate } from 'sdi/map';
import {
    ImageUploaderState,
    defaultImageUploaderState,
} from 'sdi/components/upload';
import { ToggleState } from 'sdi/lib';
import { AudioUploadStep } from '../components/form/annotation';
import { CommunityView } from '../components/community';

declare module 'sdi/shape' {
    export interface IShape {
        'data/collect/me': RemoteResource<Contributions>;
        'data/collect/community': RemoteResource<Community[]>;
        'data/collect/base-community': Nullable<Community>;
        'data/collect/collect': RemoteResource<Collect[]>;
        'data/collect/category': RemoteResource<CollectCategory[]>;
        'data/collect/question': RemoteResource<Question[]>;
        'data/collect/choice': RemoteResource<Choice[]>;
        'data/collect/observation': RemoteResource<Observation[]>;
        'data/collect/answer-list': RemoteResource<Answer[]>;
        'data/collect/annotations': Collection<Annotation[]>;
        'data/collect/community/img': Collection<string>;

        'collect/selected/community': Nullable<number>;
        'collect/selected/collect': Nullable<number>;
        'collect/selected/observation': Nullable<number>;
        'collect/toggle/annotation': ToggleState<AnnotationTargetKind>;
        'collect/community/view': CommunityView;

        'collect/form/observation/observed': Nullable<Point>;
        'collect/form/observation/collect': Nullable<number>;
        'collect/form/answer/observation': Nullable<number>;
        'collect/form/answer/question': Nullable<number>;
        'collect/form/register': Nullable<RegisterFormInProgress>;
        'collect/form/question-state': Nullable<
            [number, 'processing' | 'success' | 'error']
        >;

        'collect/map/interaction': Interaction;

        'collect/annotation-form/target': Nullable<AnnotationTarget>;
        'collect/annotation-form/text': Nullable<string>;
        'collect/annotation-form/image': ImageUploaderState;
        'collect/annotation-form/sound': AudioUploadStep;
        'collect/annotation-form/kind': AnnotationKind;
    }
}

export const defaultCollectShape = () => ({
    'data/collect/me': remoteNone,
    'data/collect/community': remoteNone,
    'data/collect/base-community': null,
    'data/collect/collect': remoteNone,
    'data/collect/category': remoteNone,
    'data/collect/question': remoteNone,
    'data/collect/choice': remoteNone,
    'data/collect/observation': remoteNone,
    'data/collect/answer-list': remoteNone,
    'data/collect/annotations': {},
    'data/collect/community/img': {},

    'collect/selected/community': null,
    'collect/selected/collect': null,
    'collect/selected/observation': null,
    'collect/toggle/annotation': [],
    'collect/community/view': 'collect' as CommunityView,

    'collect/form/observation/observed': null,
    'collect/form/observation/collect': null,
    'collect/form/answer/observation': null,
    'collect/form/answer/question': null,
    'collect/form/register': null,
    'collect/form/question-state': null,

    'collect/map/interaction': {
        label: 'create',
        state: { geometryType: 'Point' },
    } as InteractionCreate,

    'collect/annotation-form/target': null,
    'collect/annotation-form/text': null,
    'collect/annotation-form/image': defaultImageUploaderState(),
    'collect/annotation-form/sound': 'none' as AudioUploadStep,
    'collect/annotation-form/kind': 'text' as AnnotationKind,
});
