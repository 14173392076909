import * as debug from 'debug';
import { DirectGeometryObject, Position } from '../source';
import bbox from '@turf/bbox';
import { Option, none, some, fromNullable } from 'fp-ts/lib/Option';
import { index } from 'fp-ts/lib/Array';
import { __forceRefreshState } from '../app';
import { scopeOption } from '../lib';

const logger = debug('sdi:map/mini');

type BBox2d = [number, number, number, number];
type Size = [number, number];

export type MiniStep =
    | { step: 'loading' }
    | { step: 'failed' }
    | { step: 'loaded'; data: string };

export const miniMapLoading = (): MiniStep => ({ step: 'loading' });
export const miniMapFailed = (): MiniStep => ({ step: 'failed' });
export const miniMapLoaded = (data: string): MiniStep => ({
    step: 'loaded',
    data,
});

// tslint:disable-next-line:variable-name
const WMSBaseRequest = {
    SERVICE: 'WMS',
    VERSION: '1.1.1',
    REQUEST: 'GetMap',
    FORMAT: 'image/png',
    TRANSPARENT: 'true',
    LAYERS: 'urbisFRGray',
    WIDTH: 256,
    HEIGHT: 256,
    SRS: 'EPSG:31370',
    STYLES: '',
    BBOX: '',
};

export type WMSRequest = typeof WMSBaseRequest;
export type WMSRequestKey = keyof WMSRequest;

const encodeRequest = (r: WMSRequest) =>
    Object.keys(r)
        .map(
            (k: WMSRequestKey) => `${k}=${encodeURIComponent(r[k].toString())}`
        )
        .join('&');

export const mapCoordinates = <T>(
    geom: DirectGeometryObject,
    f: (p: Position) => T
) => {
    switch (geom.type) {
        case 'Point':
            return f(geom.coordinates);
        case 'MultiPoint':
            return geom.coordinates.map(f);
        case 'LineString':
            return geom.coordinates.map(f);
        case 'MultiLineString':
            return geom.coordinates.map(l => l.map(f));
        case 'Polygon':
            return geom.coordinates.map(l => l.map(f));
        case 'MultiPolygon':
            return geom.coordinates.map(p => p.map(l => l.map(f)));
    }
};

const buffer = (width: number, geom: DirectGeometryObject) => {
    switch (geom.type) {
        // case 'Point': return 100; // TODO generalize the "100" value for other CRS than 31370.
        case 'Point':
            return geom.coordinates[0] > 180.0 ? 100 : 0.1;
        // case 'MultiPoint': return Math.max(100, width * 2);
        case 'MultiPoint':
            return geom.coordinates[0][0] > 180.0 ? 100 : 0.1; //  width * 2);
        case 'LineString':
        case 'MultiLineString':
        case 'Polygon':
        case 'MultiPolygon':
            return width * 1.3;
    }
};

export const drawPoint = (ctx: CanvasRenderingContext2D, p: Position) => {
    const [x, y] = p;
    ctx.rect(x - 5, y - 5, 10, 10);
};

export const drawLine = (ctx: CanvasRenderingContext2D, p: Position[]) => {
    index(0, p).map(([x, y]) => ctx.moveTo(x, y));
    p.slice(1).map(([x, y]) => ctx.lineTo(x, y));
};

export const drawPolygon = (ctx: CanvasRenderingContext2D, p: Position[][]) => {
    p.map(inner => {
        index(0, inner).map(([x, y]) => ctx.moveTo(x, y));
        inner.slice(1).map(([x, y]) => ctx.lineTo(x, y));
        ctx.closePath();
    });
};

const defaultPaint = (
    ctx: CanvasRenderingContext2D,
    geom: DirectGeometryObject
) => {
    switch (geom.type) {
        case 'Point':
        case 'MultiPoint':
            return ctx.fill();
        case 'LineString':
        case 'MultiLineString':
            return ctx.stroke();
        case 'Polygon':
        case 'MultiPolygon':
            return ctx.stroke();
    }
};

export type Painter = typeof defaultPaint;

const draw = (
    ctx: CanvasRenderingContext2D,
    geom: DirectGeometryObject,
    paint: Painter
) => {
    switch (geom.type) {
        case 'Point': {
            drawPoint(ctx, geom.coordinates);
            return paint(ctx, geom);
        }
        case 'MultiPoint':
            return geom.coordinates.map(coords => {
                drawPoint(ctx, coords);
                paint(ctx, geom);
            });
        case 'LineString': {
            drawLine(ctx, geom.coordinates);
            return paint(ctx, geom);
        }
        case 'MultiLineString':
            return geom.coordinates.map(coords => {
                drawLine(ctx, coords);
                paint(ctx, geom);
            });
        case 'Polygon': {
            drawPolygon(ctx, geom.coordinates);
            return paint(ctx, geom);
        }
        case 'MultiPolygon':
            return geom.coordinates.map(coords => {
                drawPolygon(ctx, coords);
                paint(ctx, geom);
            });
    }
};

const drawGeomOnImage = (
    image: CanvasImageSource,
    geom: Readonly<DirectGeometryObject>,
    box: BBox2d,
    sz: Size,
    paint: Painter
) => {
    const [minx, miny, maxx, maxy] = box;
    logger(`drawGeomOnImage (${minx}. ${miny})`);
    const canvas = document.createElement('canvas');
    const hscale = sz[0] / (maxx - minx);
    const vscale = sz[1] / (maxy - miny);
    canvas.width = sz[0];
    canvas.height = sz[1];
    logger(`drawGeomOnImage (${hscale}. ${vscale})`);
    const ctx = canvas.getContext('2d');
    if (ctx) {
        ctx.drawImage(image, 0, 0);
        const coordinates = mapCoordinates(geom, p => [
            (p[0] - minx) * hscale,
            sz[1] - (p[1] - miny) * vscale,
        ]);
        const transformed = { ...geom, coordinates } as DirectGeometryObject;

        ctx.beginPath();
        draw(ctx, transformed, paint);
        return some(canvas.toDataURL('image/png', false));
    }
    return none;
};

const hashCoordinate = (p: Position) => `${p[0]},${p[1]}`;

const hashGeom = (geom: Readonly<DirectGeometryObject>) => {
    switch (geom.type) {
        case 'Point':
            return hashCoordinate(geom.coordinates);
        case 'LineString':
            return geom.coordinates.map(hashCoordinate).join('~');
        case 'Polygon':
            return geom.coordinates
                .map(multi => multi.map(hashCoordinate).join('+'))
                .join('~');
        case 'MultiPoint':
            return geom.coordinates.map(hashCoordinate).join('~');
        case 'MultiLineString':
            return geom.coordinates
                .map(multi => multi.map(hashCoordinate).join('+'))
                .join('~');
        case 'MultiPolygon':
            return geom.coordinates
                .map(multi =>
                    multi
                        .map(inner => inner.map(hashCoordinate).join('+'))
                        .join('~')
                )
                .join('/');
    }
};

/**
 * Build a static image based on a
 * geometry and a  WMS URL
 */
export const miniMap =
    (url: string, options: Partial<WMSRequest>, paint = defaultPaint) =>
    (geometry: Readonly<DirectGeometryObject>): Promise<Option<string>> => {
        const [minxOrig, minyOrig, maxx, maxy] = bbox(geometry);
        const widthOrig = maxx - minxOrig;
        const heightOrig = maxy - minyOrig;
        const width = buffer(widthOrig, geometry);
        const height = buffer(heightOrig, geometry);
        const sideMax = Math.max(width, height);
        const sideMin = Math.min(width, height);
        const minx = minxOrig - (width - widthOrig) / 2;
        const miny = minyOrig - (height - heightOrig) / 2;
        const abbox: BBox2d = [
            minx - (sideMax - sideMin) / 2,
            miny - (sideMax - sideMin) / 2,
            minx + sideMax,
            miny + sideMax,
        ];
        const bboxString = abbox.map(c => c.toFixed(2)).join(',');
        const parameters = { ...WMSBaseRequest, ...options, BBOX: bboxString };
        const querystring = encodeRequest(parameters);
        const requestUrl = `${url}?${querystring}`;
        logger(`URL: ${requestUrl}`);
        return fetch(requestUrl)
            .then(response => {
                if (response.ok) {
                    return response.blob();
                }
                throw new Error(response.statusText);
            })
            .then(blob => createImageBitmap(blob))
            .then(image =>
                drawGeomOnImage(
                    image,
                    geometry,
                    abbox,
                    [parameters.WIDTH, parameters.HEIGHT],
                    paint
                )
            )
            .catch(err => {
                logger(`fetch(${requestUrl}) error ${err}`);
                return none;
            });
    };

export default miniMap;

export const miniMap2 = (
    url: string,
    options: Partial<WMSRequest>,
    paint = defaultPaint
) => {
    const cache: Record<string, MiniStep> = {};

    return (geometry: Readonly<DirectGeometryObject>): MiniStep => {
        const hash = hashGeom(geometry);
        if (!(hash in cache)) {
            cache[hash] = miniMapLoading();
            const [minxOrig, minyOrig, maxx, maxy] = bbox(geometry);
            const widthOrig = maxx - minxOrig;
            const heightOrig = maxy - minyOrig;
            const width = buffer(widthOrig, geometry);
            const height = buffer(heightOrig, geometry);
            const sideMax = Math.max(width, height);
            const sideMin = Math.min(width, height);
            const minx = minxOrig - (width - widthOrig) / 2;
            const miny = minyOrig - (height - heightOrig) / 2;
            const ratio = scopeOption()
                .let('width', fromNullable(options.WIDTH))
                .let('height', fromNullable(options.HEIGHT))
                .map(({ width, height }) => width / height)
                .getOrElse(1);

            const abbox: BBox2d = [
                minx - ((sideMax - sideMin) / 2) * ratio,
                miny - (sideMax - sideMin) / 2,
                minx + sideMax * ratio,
                miny + sideMax,
            ];

            const bboxString = abbox.map(c => c.toFixed(2)).join(',');
            const parameters = {
                ...WMSBaseRequest,
                ...options,
                BBOX: bboxString,
            };
            const querystring = encodeRequest(parameters);
            const requestUrl = `${url}?${querystring}`;

            fetch(requestUrl)
                .then(response => {
                    if (response.ok) {
                        return response.blob();
                    }
                    throw new Error(response.statusText);
                })
                .then(blob => createImageBitmap(blob))
                .then(image => {
                    drawGeomOnImage(
                        image,
                        geometry,
                        abbox,
                        [parameters.WIDTH, parameters.HEIGHT],
                        paint
                    ).foldL(
                        () => (cache[hash] = miniMapFailed()),
                        data => (cache[hash] = miniMapLoaded(data))
                    );
                    __forceRefreshState();
                })
                .catch(err => {
                    logger(`fetch(${requestUrl}) error ${err}`);
                    cache[hash] = miniMapFailed();
                    __forceRefreshState();
                });
        }
        return cache[hash];
    };
};

logger('loaded');
