/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import * as debug from 'debug';
import { DIV, NodeOrOptional, HEADER, BUTTON } from 'sdi/components/elements';
import langSwitch from 'sdi/components/lang-switch';
import { getUserId, getAppManifest } from 'sdi/app';
import tr, { fromRecord } from 'sdi/locale';
import { appDisplayName } from 'sdi/source';
import { none } from 'fp-ts/lib/Option';
// import { profileButton } from './buttons';
import {
    navigateAbout,
    navigateHome,
    navigateLogin,
    navigateProfile,
} from '../events/route';
import { getUserData } from '../queries/login';

const logger = debug('sdi:header');

// const loginURL = () => {
//     const path = getPathElements(document.location.pathname);
//     const root = getPathElements(getRelativeRoot());
//     const next = path
//         .filter((p, i) => (i < root.length ? p !== root[i] : true))
//         .join('/');
//     return `${getRoot()}login/${next}`;
// };

const renderTitle = (appCodename: string) =>
    getAppManifest(appCodename)
        .chain(appDisplayName)
        .map<string>(n => fromRecord(n))
        .getOrElse(appCodename);

const appElementDefault = (): NodeOrOptional => none;

const renderAuthLink = () =>
    BUTTON(
        { className: 'navigate login', onClick: navigateLogin },
        tr.smartwater('signIn')
    );

const renderAboutLink = () =>
    BUTTON(
        { className: 'navigate documentation', onClick: navigateAbout },
        tr.smartwater('labelAbout')
    );

const renderLoginLink = () =>
    BUTTON(
        { className: 'navigate user', onClick: navigateProfile },
        getUserData()
            .map(({ name }) => name)
            .getOrElse(tr.smartwater('user-profile'))
    );

const renderAuth = () => getUserId().foldL(renderAuthLink, renderLoginLink);

export const header = (appCodename: string, appElement = appElementDefault) =>
    HEADER(
        'header',
        DIV(
            {
                onClick: navigateHome,
            },
            DIV('brand-logo', DIV('brand-name'))
        ),
        DIV('app-title', renderTitle(appCodename)),
        DIV('app-listwrapper', appElement()),
        DIV(
            'header-toolbar',
            renderAuth(),
            renderAboutLink(),
            langSwitch(appCodename)
        )
    );

export default header;

logger('loaded');
