/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import * as debug from 'debug';
import { fromNullable } from 'fp-ts/lib/Option';

import { dispatchK, assign, assignK } from 'sdi/shape';
import { getApiUrl, getRootUrl, updateCSRF } from 'sdi/app';

import { loginUser, logoutUser } from '../remote';
import { getCredentials, getFrom } from '../queries/login';
import { Credentials } from 'sdi/source';
import { loadRoute } from './route';
import { tryNumber } from 'sdi/util';
import { loadContributions } from './collect';

const logger = debug('sdi:events/login');

const login = dispatchK('component/login');

export const emptyCredentials = (): Credentials => ({
    password: '',
    username: '',
});

export const resetLogin = () => assign('component/login', emptyCredentials());

export const setUsername = (username: string) =>
    login(s => ({ ...s, username }));

export const setPassword = (password: string) =>
    login(s => ({ ...s, password }));

export const setFrom = assignK('component/login/from');

export const tryLogin = () => {
    assign('component/login/error', null);
    loginUser(getApiUrl('auth/login'), getCredentials())
        .then(user => {
            assign('app/user', user);
            assign('data/user', user);
            fromNullable(user.csrf).map(updateCSRF);
            loadContributions();
        })
        .then(() => {
            loadRoute(getFrom() as string[]);
        })
        .catch(err => {
            const errorCode = tryNumber(err.status).getOrElse(0);
            assign('component/login/error', errorCode);
            resetLogin();
        });
};

export const tryLogout = () =>
    logoutUser(getApiUrl('auth/logout')).then(() =>
        window.location.assign(getRootUrl('smartwater'))
    );

logger('loaded');
