import {
    fetchCollectList,
    fetchQuestionList,
    fetchChoiceList,
    fetchObservationList,
    fetchAnswerList,
    postObservation,
    Observation,
    postAnswer,
    Answer,
    AnnotationTargetKind,
    fetchAnnotationList,
    postImageAnnotation,
    postTextAnnotation,
    fetchCommunityList,
    fetchMe,
    fetchCollectCategoryList,
    Choice,
    putObservation,
    Community,
    postAffinity,
    Affinity,
    deleteAffinity,
    postSoundTrack,
    postSoundAnnotation,
} from '../remote';
import {
    assign,
    makeSetReset,
    query,
    dispatch,
    observe,
    dispatchK,
    assignK,
} from 'sdi/shape';
import {
    remoteLoading,
    remoteSuccess,
    remoteError,
    mapRemote,
    remoteNone,
    Point,
} from 'sdi/source';
import { scopeOption } from 'sdi/lib';
import { fromNullable, some } from 'fp-ts/lib/Option';
import {
    getSelectedCollect,
    observationInfoOption,
    getObservationFeatures,
    getFormAnswerObservation,
    getAnnotationTarget,
    getAnnotationText,
    getAnnotationImage,
    getAnnotations,
    MAP_OBSERVATION_FORM,
    findChoice,
    getTrackerObservationFeatures,
    observationTrackerInfoOption,
    BASE_COMMUNITY_ID,
    getObservations,
    observationCommunityLayer,
} from '../queries/collect';
import { navigateAnswerForm, navigateBack } from './route';
import { FetchData, addLayer, removeLayerAll } from 'sdi/map';
import { right } from 'fp-ts/lib/Either';
import { updateCollection, getCollectionItem, noop, tryNumber } from 'sdi/util';
import { makeImageUploaderEvents } from 'sdi/components/upload';
import { toggleAnnotation } from './app';
import { getUserId } from 'sdi/app/queries';

observe('collect/selected/collect', s =>
    fromNullable(s).map(collectId =>
        getAnnotations(collectId).foldL(() => {
            loadAnnotations(collectId);
        }, noop)
    )
);

export const withLambert72 = (geom: Point) => ({
    crs: { type: 'name', properties: { name: 'EPSG:31370' } },
    ...geom,
});

export const loadCollectList = () => {
    assign('data/collect/collect', remoteLoading);

    return fetchCollectList()
        .then(ls => assign('data/collect/collect', remoteSuccess(ls)))
        .catch(err =>
            assign('data/collect/collect', remoteError(err.toString()))
        );
};

export const loadCategoryList = () => {
    assign('data/collect/category', remoteLoading);

    return fetchCollectCategoryList()
        .then(ls => assign('data/collect/category', remoteSuccess(ls)))
        .catch(err =>
            assign('data/collect/category', remoteError(err.toString()))
        );
};

export const loadCommunityList = () => {
    assign('data/collect/community', remoteLoading);

    return fetchCommunityList()
        .then(ls => {
            fromNullable(ls.find(({ id }) => id === BASE_COMMUNITY_ID)).map(
                assignK('data/collect/base-community')
            );
            assign(
                'data/collect/community',
                remoteSuccess(ls.filter(({ id }) => id !== BASE_COMMUNITY_ID))
            );
            return ls;
        })
        .catch(err => {
            assign('data/collect/community', remoteError(err.toString()));
            return [];
        });
};

export const loadQuestionList = () => {
    assign('data/collect/question', remoteLoading);

    return fetchQuestionList()
        .then(ls => assign('data/collect/question', remoteSuccess(ls)))
        .catch(err =>
            assign('data/collect/question', remoteError(err.toString()))
        );
};

export const loadChoiceList = () => {
    assign('data/collect/choice', remoteLoading);

    return fetchChoiceList()
        .then(ls => assign('data/collect/choice', remoteSuccess(ls)))
        .catch(err =>
            assign('data/collect/choice', remoteError(err.toString()))
        );
};

export const loadObservationList = () => {
    assign('data/collect/observation', remoteLoading);

    return fetchObservationList()
        .then(ls => assign('data/collect/observation', remoteSuccess(ls)))
        .catch(err =>
            assign('data/collect/observation', remoteError(err.toString()))
        );
};

export const loadAnswerList = () => {
    assign('data/collect/answer-list', remoteLoading);

    return fetchAnswerList()
        .then(ls => assign('data/collect/answer-list', remoteSuccess(ls)))
        .catch(err =>
            assign('data/collect/answer-list', remoteError(err.toString()))
        );
};

export const [selectCommunity, resetCommunity] = makeSetReset(
    'collect/selected/community',
    null
);

export const [selectCollect, resetCollectSelection] = makeSetReset(
    'collect/selected/collect',
    null
);

export const [selectObservation, resetObservationSelection] = makeSetReset(
    'collect/selected/observation',
    null
);

export const [setFormObservationCollect, resetFormObservationCollect] =
    makeSetReset('collect/form/observation/collect', null);

export const [setFormObservationObserved, resetFormObservationObserved] =
    makeSetReset('collect/form/observation/observed', null);

export const [setFormAnswerObservation, resetFormAnswerObservation] =
    makeSetReset('collect/form/answer/observation', null);

export const addObservationLayer = () => {
    removeLayerAll(MAP_OBSERVATION_FORM);
    const fetchData: FetchData = () =>
        right(
            some({
                type: 'FeatureCollection',
                features: getObservationFeatures(),
            })
        );
    addLayer(MAP_OBSERVATION_FORM, observationInfoOption, fetchData);
    observationCommunityLayer().map(({ info, data }) =>
        addLayer(MAP_OBSERVATION_FORM, info, data)
    );
};

const addObservation = (obs: Observation) =>
    dispatch('data/collect/observation', resource =>
        mapRemote(resource, data => data.concat(obs))
    );

export const saveFormObservation = () =>
    scopeOption()
        .let(
            'observed',
            fromNullable(query('collect/form/observation/observed'))
        )
        .let('collect', getSelectedCollect())
        .map(({ observed, collect }) =>
            postObservation(collect.id, { observed, collect: collect.id })
                .then(observation => {
                    addObservation(observation);
                    navigateAnswerForm(collect.id, observation.id);
                    assign('data/collect/me', remoteNone);
                })
                .catch(err => {
                    // TODO
                    console.error(err);
                })
        );

const ensureCRS = (observation: Observation): Observation => ({
    ...observation,
    observed: withLambert72(observation.observed),
});

export const updateObservation = (observation: Observation) =>
    getSelectedCollect().map(collect => {
        putObservation(collect.id, ensureCRS(observation))
            .then(obs => {
                dispatch('data/collect/observation', resource =>
                    mapRemote(resource, data =>
                        data.filter(({ id }) => id !== obs.id).concat(obs)
                    )
                );
                assign('data/collect/me', remoteNone);
            })
            .catch(noop);
    });

export const updateObservationTime = (observation: Observation, dt: Date) =>
    updateObservation({
        ...observation,
        timestamp: dt.getTime(),
    });
// scopeOption()
//     .let('collect', )
//     .map(({ observed, collect }) =>
//         postObservation(collect.id, { observed, collect: collect.id })
//             .then(observation => {
//                 addObservation(observation);
//                 navigateAnswerForm(collect.id, observation.id);
//             })
//             .catch(err => {
//                 // TODO
//                 console.error(err);
//             })
//     );

const addAnswer = (a: Answer) =>
    dispatch('data/collect/answer-list', resource => {
        const questionId = findChoice(a.choice)
            .map(c => c.question)
            .getOrElse(-1);
        return mapRemote(resource, data =>
            data
                .filter(
                    b =>
                        b.observation !== a.observation ||
                        questionId !==
                            findChoice(b.choice)
                                .map(c => c.question)
                                .getOrElse(-1)
                )
                .concat(a)
        );
    });

export const saveAnswer = (choice: Choice) => {
    assign('collect/form/question-state', [choice.question, 'processing']);
    getFormAnswerObservation().map(observation =>
        postAnswer(observation, { observation, choice: choice.id })
            .then(a => {
                addAnswer(a);
                toggleAnnotation('answer', a.id);
                assign('collect/form/question-state', [
                    choice.question,
                    'success',
                ]);
                setTimeout(clearQuestionState, 2600);
            })
            .catch(() => {
                assign('collect/form/question-state', [
                    choice.question,
                    'error',
                ]);
            })
    );
};

export const clearQuestionState = () =>
    assign('collect/form/question-state', null);

export const [setAnnotationFormKind, resetAnnotationFormKind] = makeSetReset(
    'collect/annotation-form/kind',
    'text'
);

export const [setAnnotationText, resetAnnotationText] = makeSetReset(
    'collect/annotation-form/text',
    ''
);

export const setAnnotationFormTarget = (
    kind: AnnotationTargetKind,
    id: number
) => assign('collect/annotation-form/target', { kind, id });

export const resetAnnotationFormTarget = () =>
    assign('collect/annotation-form/target', null);

export const loadAnnotations = (collectId: number) =>
    fetchAnnotationList(collectId)
        .then(ans =>
            dispatch('data/collect/annotations', s =>
                updateCollection(s, collectId.toString(), ans)
            )
        )
        .catch(_err => {
            // TODO
        });

export const saveTextAnnotation = () =>
    scopeOption()
        .let('collect', getSelectedCollect())
        .let('target', getAnnotationTarget())
        .let('text', getAnnotationText())
        // .let('user', getUserIdAstNumber())
        .map(({ collect, target, text }) =>
            postTextAnnotation({
                kind: 'text',
                collect: collect.id,
                target,
                // user,
                text,
            })
                .then(annotation =>
                    dispatch('data/collect/annotations', s =>
                        getCollectionItem(collect.id.toString(), s).foldL(
                            () =>
                                updateCollection(s, collect.id.toString(), [
                                    annotation,
                                ]),
                            ans =>
                                updateCollection(
                                    s,
                                    collect.id.toString(),
                                    ans.concat(annotation)
                                )
                        )
                    )
                )
                .catch(_err => {
                    // TODO
                })
        );

export const saveImageAnnotation = () =>
    scopeOption()
        .let('collect', getSelectedCollect())
        .let('target', getAnnotationTarget())
        .let(
            'image',
            getAnnotationImage().chain(s => fromNullable(s.imageId))
        )
        // .let('user', getUserIdAstNumber())
        .map(({ collect, target, image }) =>
            postImageAnnotation({
                kind: 'image',
                collect: collect.id,
                target,
                image,
            })
                .then(annotation =>
                    dispatch('data/collect/annotations', s =>
                        getCollectionItem(collect.id.toString(), s).foldL(
                            () =>
                                updateCollection(s, collect.id.toString(), [
                                    annotation,
                                ]),
                            ans =>
                                updateCollection(
                                    s,
                                    collect.id.toString(),
                                    ans.concat(annotation)
                                )
                        )
                    )
                )
                .catch(_err => {
                    // TODO
                })
        );

export const imageUploaderEvents = makeImageUploaderEvents(
    dispatchK('collect/annotation-form/image')
);

export const resetAnnotationImage = imageUploaderEvents.clearUploadState;

export const saveSoundAnnotation = (track: File) => {
    scopeOption()
        .let('collect', getSelectedCollect())
        .let('target', getAnnotationTarget())
        .map(({ collect, target }) => {
            assign('collect/annotation-form/sound', 'uploading');
            postSoundTrack(track).then(({ id }) =>
                postSoundAnnotation({
                    collect: collect.id,
                    target,
                    kind: 'sound',
                    track: id,
                })
                    .then(annotation =>
                        dispatch('data/collect/annotations', s =>
                            getCollectionItem(collect.id.toString(), s).foldL(
                                () =>
                                    updateCollection(s, collect.id.toString(), [
                                        annotation,
                                    ]),
                                ans =>
                                    updateCollection(
                                        s,
                                        collect.id.toString(),
                                        ans.concat(annotation)
                                    )
                            )
                        )
                    )
                    .catch(_err => {
                        // TODO
                    })
                    .finally(() => {
                        assign('collect/annotation-form/sound', 'none');
                        navigateBack();
                    })
            );
        });
};

export const loadContributions = () => {
    assign('data/collect/me', remoteNone);
    return fetchMe()
        .then(data => assign('data/collect/me', remoteSuccess(data)))
        .catch(err => assign('data/collect/me', remoteError(err.toString())));
};

export const addTrackObservationLayer = () => {
    removeLayerAll(MAP_OBSERVATION_FORM);
    const fetchData: FetchData = () =>
        right(
            some({
                type: 'FeatureCollection',
                features: getTrackerObservationFeatures(),
            })
        );
    addLayer(MAP_OBSERVATION_FORM, observationTrackerInfoOption, fetchData);
};

export const isAnswerdCollect = (collectId: number) => {
    const currentUser = getUserId()
        .chain(uid => tryNumber(uid))
        .getOrElse(-1);
    return (
        getObservations(collectId).findIndex(o => o.user === currentUser) >= 0
    );
};

export const setCommunityImg = (communityId: number, img: string) =>
    dispatch('data/collect/community/img', s => {
        const key = `${communityId}`;
        return getCollectionItem(key, s).fold(
            updateCollection(s, key, img),
            () => s
        );
    });

export const subscribeCommunity = (c: Community) =>
    postAffinity(c.id).then(a =>
        dispatch('data/collect/me', res =>
            mapRemote(res, me => ({
                ...me,
                user: {
                    ...me.user,
                    affinities: me.user.affinities.concat(a),
                },
            }))
        )
    );
export const unsubscribeCommunity = (aff: Affinity) =>
    deleteAffinity(aff.id).then(() =>
        dispatch('data/collect/me', res =>
            mapRemote(res, me => ({
                ...me,
                user: {
                    ...me.user,
                    affinities: me.user.affinities.filter(a => aff.id !== a.id),
                },
            }))
        )
    );

export const setCommunityViewMode = assignK('collect/community/view');
