import * as io from 'io-ts';
import {
    Credentials,
    IUser,
    postIO,
    IUserIO,
    postUnrelatedIO,
} from 'sdi/source';

const lo = io.interface({
    logout: io.string,
});
type Lo = io.TypeOf<typeof lo>;

export const loginUser = (url: string, data: Credentials): Promise<IUser> =>
    postUnrelatedIO(IUserIO, url, data);
export const logoutUser = (url: string): Promise<Lo> => postIO(lo, url, {});

export * from './app';
export * from './collect';
