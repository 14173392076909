import { nameToString } from 'sdi/components/button/names';
import { DIV, H1, LABEL, NodeOrOptional } from 'sdi/components/elements';
import { inputText, attrOptions, renderCheckbox } from 'sdi/components/input';
import tr from 'sdi/locale';
import { markdown } from 'sdi/ports/marked';
import { noop } from 'sdi/util';
import { submitRegister, updateRegisterForm } from 'smartwater/src/events/app';
import { navigateCommunity } from 'smartwater/src/events/route';
import {
    getRegisterCompleted,
    getRegisterConsent,
    getRegisterControlPassword,
    getRegisterEmail,
    getRegisterError,
    getRegisterPassword,
    getRegisterUsername,
} from 'smartwater/src/queries/app';
import { getCurrentCommunity } from 'smartwater/src/queries/collect';
import { buttonValidateRegister, renderButtonCommunity } from '../buttons';

const renderTitle = () => H1('title', tr.smartwater('title/register'));

const wrapField = (name: string, ...nodes: NodeOrOptional[]) =>
    DIV('field', LABEL('name', name, ...nodes));

const renderUsername = () =>
    wrapField(
        tr.smartwater('field/username'),
        inputText(
            attrOptions<string>(
                'register-username',
                () => getRegisterUsername().getOrElse(''),
                updateRegisterForm('username'),
                {
                    required: true,
                    minLength: 3,
                    placeholder: tr.smartwater('placeHolderUserName'),
                }
            )
        )
    );
const renderEmail = () =>
    wrapField(
        tr.smartwater('field/e-mail'),
        inputText(
            attrOptions<string>(
                'register-email',
                () => getRegisterEmail().getOrElse(''),
                updateRegisterForm('email'),
                { type: 'email', required: true }
            )
        )
    );

const renderPassword = () =>
    wrapField(
        tr.smartwater('field/password'),
        inputText(
            attrOptions<string>(
                'register-password',
                () => getRegisterPassword().getOrElse(''),
                updateRegisterForm('password'),
                { type: 'password', required: true, minLength: 3 }
            )
        )
    );

const renderControlPassword = () =>
    wrapField(
        tr.smartwater('field/control-password'),
        inputText(
            attrOptions<string>(
                'register-control-password',
                () => getRegisterControlPassword().getOrElse(''),
                updateRegisterForm('passwordControl'),
                { type: 'password', required: true }
            )
        )
    );

const renderConsent = () => {
    const renderConsentCheckbox = renderCheckbox(
        'consent-checkbox',
        () => tr.smartwater('legal/consent'),
        updateRegisterForm('consent'),
        markdown(tr.smartwater('legal/consent'))
    );

    return renderConsentCheckbox(getRegisterConsent());
};

const validateEnabled = () => buttonValidateRegister(submitRegister);
const validateDisabled = () => buttonValidateRegister(noop, 'disabled');

const renderValidate = () =>
    DIV(
        'buttons',
        getRegisterCompleted() ? validateEnabled() : validateDisabled()
    );

const breadCrumb = () =>
    DIV(
        'breadcrumb',
        getCurrentCommunity().map(com =>
            renderButtonCommunity(com.name)(() => navigateCommunity(com.id))
        )
    );

const renderErrorDuplicate = () =>
    DIV(
        'register-error',
        DIV('icon', nameToString('exclamation-triangle')),
        DIV('error-text', tr.smartwater('registerErrorDuplicate'))
    );

const renderErrorOther = () =>
    DIV('register-other', tr.smartwater('registerErrorOther'));

const renderError = () =>
    getRegisterError().map(err => {
        switch (err.message) {
            case 'duplicate':
                return renderErrorDuplicate();
            case 'other':
                return renderErrorOther();
        }
    });

export const renderRegister = () =>
    DIV(
        'wrapper',
        breadCrumb(),
        renderTitle(),
        renderError(),
        renderUsername(),
        renderEmail(),
        renderPassword(),
        renderControlPassword(),
        renderConsent(),
        renderValidate()
    );

export default renderRegister;
