import {
    getFormAnswerObservation,
    getAnnotationsFromObs,
    getAnnotationsFromQuestion,
    getAnnotationsFromAnswer,
} from '../queries/collect';
import {
    A,
    AUDIO,
    DIV,
    IMG,
    NodeOrOptional,
    SOURCE,
} from 'sdi/components/elements';
import {
    buttonAnnotateText,
    buttonAnnotateImage,
    buttonAnnotateSound,
} from './buttons';
import { navigateAnnotationForm } from '../events/route';
import {
    Answer,
    Annotation,
    AnnotationText,
    AnnotationImage,
    Question,
    Observation,
    AnnotationSound,
} from '../remote';
import { findUser, predAnnotationOpen } from '../queries/app';
import tr, { formatDate } from 'sdi/locale';
import { markdown } from 'sdi/ports/marked';
import { toggleAnnotation } from '../events/app';
import { none, some } from 'fp-ts/lib/Option';

export const annotationTriggerObs = (o: Observation) => {
    const nbOpt = getAnnotationsFromObs(o).chain(list =>
        list.length > 0 ? some(list.length) : none
    );
    return DIV(
        {
            className: 'annotate annotate-trigger',
            onClick: () => toggleAnnotation('observation', o.id),
        },
        DIV({ className: 'annotation-number' }, nbOpt)
        // DIV({className: 'annotation-label'},
        //     nb > 1
        //         ? `${tr.collect('multipleNotesObservation')}`
        //         : `${tr.collect('singleNoteObservation')}`,
        // ),
    );
};
export const annotationTriggerQuestion = (q: Question) => {
    const nbOpt = getAnnotationsFromQuestion(q).chain(list =>
        list.length > 0 ? some(list.length) : none
    );
    return DIV(
        {
            className: 'annotate annotate-trigger',
            onClick: () => toggleAnnotation('question', q.id),
        },
        DIV({ className: 'annotation-number' }, nbOpt)
        // DIV({className: 'annotation-label'},
        //     nb > 1
        //         ? `${tr.collect('multipleComments')}`
        //         : `${tr.collect('singleComment')}`,
        // ),
    );
};
export const annotationTriggerAnswer = (a: Answer, q: Question) => {
    const nbOpt = getAnnotationsFromAnswer(a, q).chain(list =>
        list.length > 0 ? some(list.length) : none
    );
    console.log(`annotationTrigger: answer ${a.id} (question: ${q.id})`);
    return DIV(
        {
            className: 'annotate annotate-trigger',
            onClick: () => toggleAnnotation('answer', a.id),
        },
        DIV({ className: 'annotation-number' }, nbOpt)
        // DIV(
        //   { className: "annotation-label" },
        //   nb > 1
        //     ? `${tr.collect("multipleNotesAnswer")}`
        //     : `${tr.collect("singleNoteAnswer")}`
        // )
    );
};

export const renderAnnotationWrapperAnswer = (a: Answer, q: Question) =>
    predAnnotationOpen('answer', a.id).map(() =>
        DIV(
            'annotation__wrapper answer',
            renderAnnotateAnswer(a),
            renderAnswerAnnotations(a, q)
        )
    );

export const renderAnnotationWrapperQuestion = (q: Question) =>
    predAnnotationOpen('question', q.id).map(() =>
        DIV(
            'annotation__wrapper question',
            renderAnnotateQuestion(q),
            renderQuestionAnnotations(q)
        )
    );

export const renderAnnotationWrapperObservation = (o: Observation) =>
    predAnnotationOpen('observation', o.id).map(() =>
        DIV(
            'annotation__wrapper observation',
            renderAnnotateObservation(),
            renderObservationAnnotations(o)
        )
    );

export const renderAnnotateWrapperObservation = (o: Observation) =>
    predAnnotationOpen('observation', o.id).map(() =>
        DIV('annotation__wrapper observation', renderAnnotateObservation())
    );

export const renderAnnotateObservation = () =>
    getFormAnswerObservation().map(obsId =>
        DIV(
            'annotate annotate-observation',
            buttonAnnotateText(() =>
                navigateAnnotationForm('text', 'observation', obsId)
            ),
            buttonAnnotateImage(() =>
                navigateAnnotationForm('image', 'observation', obsId)
            )
        )
    );

export const renderAnnotateAnswer = (a: Answer) =>
    DIV(
        'annotate annotate-answer',

        buttonAnnotateText(() =>
            navigateAnnotationForm('text', 'answer', a.id)
        ),
        buttonAnnotateImage(() =>
            navigateAnnotationForm('image', 'answer', a.id)
        ),
        buttonAnnotateSound(() =>
            navigateAnnotationForm('sound', 'answer', a.id)
        )
    );

export const renderAnnotateQuestion = (q: Question) =>
    DIV(
        'annotate annotate-answer',
        buttonAnnotateText(() =>
            navigateAnnotationForm('text', 'question', q.id)
        ),
        buttonAnnotateImage(() =>
            navigateAnnotationForm('image', 'question', q.id)
        ),
        buttonAnnotateSound(() =>
            navigateAnnotationForm('sound', 'question', q.id)
        )
    );

const renderAnnotationMeta = (a: Annotation) =>
    DIV(
        'note meta',
        DIV(
            'username',
            findUser(a.user)
                .map(u => u.name)
                .getOrElse(tr.smartwater('anonymous'))
        ),
        DIV('date', formatDate(new Date(a.timestamp)))
    );

const renderAnnotationText = (a: AnnotationText) =>
    DIV('note text', renderAnnotationMeta(a), markdown(a.text));

const renderAnnotationImage = (a: AnnotationImage) =>
    DIV(
        'note image',
        renderAnnotationMeta(a),
        IMG({
            className: 'user-image',
            src: `/documents/images/${a.image}?size=medium`,
        })
    );

const renderAnnotationSound = (a: AnnotationSound) =>
    DIV(
        'note sound',
        renderAnnotationMeta(a),
        AUDIO(
            {
                className: 'user-sound',
                controls: true,
            },
            SOURCE({
                src: `/documents/documents/${a.track}`,
            }),
            DIV(
                'oops',
                tr.smartwater('annotateAudioCannotRead'),
                A(
                    {
                        href: `/documents/documents/${a.track}`,
                    },
                    tr.smartwater('audioDownloadLabel')
                )
            )
        )
    );

const renderAnnotation = (a: Annotation) => {
    switch (a.kind) {
        case 'text':
            return renderAnnotationText(a);
        case 'image':
            return renderAnnotationImage(a);
        case 'sound':
            return renderAnnotationSound(a);
    }
};

const wrapNotes = (...nodes: NodeOrOptional[]) =>
    DIV('annotation__list', ...nodes);

export const renderAnswerAnnotations = (a: Answer, q: Question) =>
    wrapNotes(getAnnotationsFromAnswer(a, q).map(a => a.map(renderAnnotation)));

export const renderQuestionAnnotations = (q: Question) =>
    wrapNotes(getAnnotationsFromQuestion(q).map(a => a.map(renderAnnotation)));

export const renderObservationAnnotations = (o: Observation) =>
    wrapNotes(getAnnotationsFromObs(o).map(a => a.map(renderAnnotation)));
