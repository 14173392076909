import {
    BUTTON,
    DETAILS,
    DIV,
    H1,
    H2,
    H3,
    INPUT,
    NodeOrOptional,
    SPAN,
    SUMMARY,
} from 'sdi/components/elements';
import tr, { fromRecord } from 'sdi/locale';
import { getUserIdAstNumber } from 'sdi/app';
import { scopeOption } from 'sdi/lib';
import { fromPredicate, none, some } from 'fp-ts/lib/Option';
import {
    getHHMM,
    getYYYYMMDD,
    notEmpty,
    parseHHMM,
    parseYYYYMMDD,
} from 'sdi/util';
import { Question, Choice, Collect, Observation } from 'smartwater/src/remote';
import {
    clearQuestionState,
    saveAnswer,
    updateObservationTime,
} from 'smartwater/src/events/collect';
import {
    getChoices,
    getSelectedCollect,
    getFormAnswerObservation,
    findObservation,
    isSelectedChoice,
    getObservedQuestions,
    getObserverQuestions,
    getSelectedObservation,
    getSelectedObservationUser,
    getAnswerAndQuestionForChoice,
    getCurrentCommunity,
    getQuestionState,
    getCollectsWithCategory,
    findCategory,
} from 'smartwater/src/queries/collect';
import { markdown } from 'sdi/ports/marked';
import {
    buttonClose,
    renderButtonCommunity,
    renderButtonPrintForm,
} from '../buttons';
import {
    navigateCommunity,
    navigateObservationFormPrint,
} from 'smartwater/src/events/route';
import {
    renderObservationAnnotations,
    renderAnnotateWrapperObservation,
    // annotationTriggerObs,
    annotationTriggerAnswer,
    renderAnnotationWrapperAnswer,
} from '../annotation';
import { notEmptyDescription } from '../../queries/collect';
import { catOptions } from 'fp-ts/lib/Array';
import { renderCollectLink } from '../collect';
import { nameToString } from 'sdi/components/button/names';

// const choiceClassName = (c: Choice) =>
//     isSelectedChoice(c) ? 'choice__label selected' : 'choice__label';

const choiceClassName = (selected: boolean) =>
    selected ? 'choice__wrapper selected' : 'choice__wrapper';

const renderAnwserAnnotation = (c: Choice) =>
    getAnswerAndQuestionForChoice(c).map(({ a, q }) =>
        DIV(
            {},
            annotationTriggerAnswer(a, q),
            renderAnnotationWrapperAnswer(a, q)
        )
    );

const renderUnselectableChoice = (c: Choice, selected: boolean) =>
    DIV(
        `${choiceClassName(selected)} unselectable`,
        DIV(
            {
                className: 'choice__label  disabled',
                key: c.id,
            },
            fromRecord(c.title)
        )
    );

const renderDescription = (c: Choice) =>
    notEmptyDescription(c.description).map(d =>
        DIV('description', markdown(d))
    );

const renderSelectableChoice = (c: Choice, selected: boolean) =>
    DIV(
        { className: choiceClassName(selected), key: c.id },
        DIV(
            {
                className: 'choice__label',
                onClick: () => {
                    if (!selected) {
                        saveAnswer(c);
                    }
                },
            },
            fromRecord(c.title)
        ),
        renderDescription(c),
        renderAnwserAnnotation(c)
    );

const renderChoice = (c: Choice) => {
    // if (getLayout() === 'answer-form') {
    //     // To check (nw)
    //     return renderSelectableChoice(c);
    // }
    // else
    {
        return scopeOption()
            .let('userId', getUserIdAstNumber())
            .let('obsUserId', getSelectedObservationUser())
            .let('sameUser', ({ userId, obsUserId }) =>
                fromPredicate<number>(u => u === obsUserId)(userId)
            )
            .map(() => renderSelectableChoice(c, isSelectedChoice(c)))
            .getOrElseL(() => renderUnselectableChoice(c, isSelectedChoice(c)));
    }
};

const renderChoiceList = (q: Question) =>
    DIV('choice__list', ...getChoices(q.id).map(renderChoice));

const renderChoiceMeta = (q: Question) =>
    DIV(
        'question__meta',
        H2({}, fromRecord(q.title)),
        DIV('helptext', markdown(fromRecord(q.description)))
    );

const renderChoiceNumber = (n: number, total: number, q: Question) =>
    DIV(
        `question__number ${q.kind}`,

        `${n + 1}/${total}`
    );

const renderStateError = () =>
    DIV(
        'feedback state state-error',
        SPAN('', tr.smartwater('stateError')),
        buttonClose(clearQuestionState)
    );

const renderStateSuccess = () =>
    DIV(
        'feedback state state-success',
        SPAN('', tr.smartwater('stateSuccess')),
        buttonClose(clearQuestionState)
    );
const renderStateProcessing = () =>
    DIV(
        'feedback state state-processing',
        SPAN('', tr.smartwater('stateProcessing'))
    );

const renderState = (q: Question) =>
    getQuestionState(q.id).map(state => {
        switch (state) {
            case 'error':
                return renderStateError();
            case 'success':
                return renderStateSuccess();
            case 'processing':
                return renderStateProcessing();
        }
    });

export const renderQuestion = (
    q: Question,
    index: number,
    questions: Question[]
) =>
    DIV(
        `question ${q.kind}`,
        renderChoiceNumber(index, questions.length, q),
        DIV(
            'question__infos',
            renderChoiceMeta(q)
            // annotationTriggerQuestion(q)
        ),
        // renderAnnotationWrapperQuestion(q),
        renderChoiceList(q),
        renderState(q)
    );

const renderHelptext = () =>
    DIV('helptext', markdown(tr.smartwater('helptext.observationAnswer')));

const renderCollectTitle = (c: Collect) => {
    const o = getSelectedObservation();
    return DIV(
        'collect-head__wrapper',
        DIV(
            'collect-title__wrapper',
            H1({}, fromRecord(c.title))
            // o.map(annotationTriggerObs)
        ),
        o.map(renderAnnotateWrapperObservation),
        renderHelptext()
    );
};

const renderUnknownCollectTitle = () => H1({}, 'unknown collect');

const breadCrumb = () =>
    DIV(
        'breadcrumb',
        // renderButtonHome(navigateHome),
        getCurrentCommunity().map(com =>
            renderButtonCommunity(com.name)(() => navigateCommunity(com.id))
        )
        // DIV('edition-mode__label', tr.smartwater('labelEditionMode'))
    );

const renderThankYou = () =>
    DIV('feedback thank-you', tr.smartwater('thankYou'));

const renderLinkedCollects = (current: Collect) =>
    catOptions(current.categories.map(findCategory)).map(cat =>
        getCollectsWithCategory(cat.id)
            .chain(collects =>
                notEmpty(collects.filter(col => col.id !== current.id))
            )
            // TODO/ check if collects are not refered 2 times
            .map(collects =>
                DIV(
                    'linked-collects',
                    H3(
                        '',
                        `${tr.smartwater('otherLinkedCollects')} "${fromRecord(
                            cat.title
                        )}": `
                    ),
                    DIV(
                        'collect-link__list',
                        ...collects.map(renderCollectLink)
                    )
                )
            )
    );

const renderActions = (current: Collect) =>
    DIV(
        'footer-actions',
        renderThankYou(),
        renderLinkedCollects(current),
        getCurrentCommunity().map(com =>
            renderButtonCommunity(com.name)(() => navigateCommunity(com.id))
        ),
        renderButtonPrintForm(() => navigateObservationFormPrint(current.id))
    );

const renderTimestampInput = (o: Observation) => {
    const datetime = new Date(o.timestamp);
    const date = getYYYYMMDD(datetime);
    const time = getHHMM(datetime);
    return DIV(
        'timestamp-input',

        DETAILS(
            '',
            SUMMARY(
                '',
                DIV(
                    '',
                    SPAN('', `${tr.smartwater('observedOn')} ${date} `),
                    SPAN('icon', nameToString('calendar'))
                )
            ),

            INPUT({
                key: 'answer-form-renderTimestampInput-date',
                type: 'date',
                defaultValue: date,
                onChange: e =>
                    parseYYYYMMDD(e.currentTarget.value).map(
                        ({ year, month, date }) => {
                            datetime.setFullYear(year, month, date);
                        }
                    ),
            }),

            INPUT({
                key: 'answer-form-renderTimestampInput-time',
                type: 'time',
                defaultValue: time,
                onChange: e =>
                    parseHHMM(e.currentTarget.value).map(
                        ({ hours, minutes }) => {
                            datetime.setHours(hours, minutes);
                        }
                    ),
            }),
            BUTTON(
                {
                    // type: 'submit',
                    className: 'btn btn-2 btn-update',
                    onClick: () => updateObservationTime(o, datetime),
                },
                tr.smartwater('updateDateTime')
            )
        )
    );
};

const renderTimestamp = (o: Observation) =>
    getUserIdAstNumber()
        .chain(id => (id === o.user ? some(o) : none))
        .fold(DIV('timestamp', new Date(o.timestamp).toLocaleString()), () =>
            renderTimestampInput(o)
        );

const withObservation = (handler: (o: Observation) => NodeOrOptional) =>
    getFormAnswerObservation().chain(findObservation).map(handler);

const render = () =>
    DIV(
        'answer-form__body',
        breadCrumb(),
        getSelectedCollect().foldL(
            renderUnknownCollectTitle,
            renderCollectTitle
        ),
        withObservation(renderObservationAnnotations),
        withObservation(renderTimestamp),
        // DIV(
        //     'question__list observed',
        //     getSelectedCollect().map(collect =>
        //         getObservedQuestions(collect.id).map(renderQuestion)
        //     )
        // ),
        // DIV(
        //     'question__list observer',
        //     getSelectedCollect().map(collect =>
        //         getObserverQuestions(collect.id).map(renderQuestion)
        //     )
        // ),
        getSelectedCollect().map(collect => {
            const qlist = getObservedQuestions(collect.id).concat(
                getObserverQuestions(collect.id)
            );
            return DIV('question__list', qlist.map(renderQuestion));
        }),
        // renderSaved(),
        getSelectedCollect().foldL(renderUnknownCollectTitle, renderActions)
    );

export default render;
