import { fromNullable, none, some } from 'fp-ts/lib/Option';
import { index } from 'fp-ts/lib/Array';
import { AUDIO, DIV, H1, H3, IMG, INPUT, SPAN } from 'sdi/components/elements';
import {
    getAnnotationKind,
    getAnnotationTarget,
    getAnnotationText,
    findAnswer,
    getQuestionForAnswer,
    getChoices,
    imageUploaderQueries,
    findObservation,
    getObservationAnnotations,
    getAnswerAnnotations,
    // getQuestionAnnotations,
    findQuestion,
    getSelectedCollectId,
    getSoundUploadStep,
} from 'smartwater/src/queries/collect';
import { inputLongText } from 'sdi/components/input';
import {
    setAnnotationText,
    saveTextAnnotation,
    imageUploaderEvents,
    saveImageAnnotation,
    saveSoundAnnotation,
} from 'smartwater/src/events/collect';
import { buttonCancel, buttonSend } from '../buttons';
import { navigateBack } from 'smartwater/src/events/route';
import {
    AnnotationText,
    AnnotationImage,
    Annotation,
    Answer,
    Question,
    Observation,
    AnnotationSound,
} from 'smartwater/src/remote';
import { markdown } from 'sdi/ports/marked';
import { makeImageUploader } from 'sdi/components/upload';
import tr, { fromRecord } from 'sdi/locale';
import { toggleAnnotation } from 'smartwater/src/events/app';
import { nameToString } from 'sdi/components/button/names';

const renderAnnotationX = (_id: number) => DIV('target annotation', 'todo');

const renderAnswerAnnotationText = (a: AnnotationText) =>
    DIV('note text', markdown(a.text));

const renderAnswerAnnotationImage = (a: AnnotationImage) =>
    DIV('note image', IMG({ src: `/documents/images/${a.image}?size=medium` }));

const renderAnswerAnnotationSound = (a: AnnotationSound) =>
    DIV('note sound', AUDIO({ src: `/documents/documents/${a.track}` }));

const renderAnnotation = (a: Annotation) => {
    switch (a.kind) {
        case 'text':
            return renderAnswerAnnotationText(a);
        case 'image':
            return renderAnswerAnnotationImage(a);
        case 'sound':
            return renderAnswerAnnotationSound(a);
    }
};

const renderAnswerAnnotations = (a: Answer, q: Question) =>
    DIV(
        'annotation__list',
        getAnswerAnnotations(a, q).map(annotations =>
            annotations.map(renderAnnotation)
        )
    );

const renderAnswer = (id: number) =>
    DIV(
        'target answer',
        findAnswer(id).map(a =>
            DIV(
                {
                    className: 'answer__wrapper',
                    key: `answer-${a.id}`,
                },
                getQuestionForAnswer(a).map(q =>
                    DIV(
                        'question',
                        H3({}, fromRecord(q.title)),
                        DIV(
                            'choice__list',
                            getChoices(q.id).map(c =>
                                DIV(
                                    {
                                        className:
                                            c.id === a.choice
                                                ? 'choice selected'
                                                : 'choice unselected',
                                        key: c.id,
                                    },
                                    SPAN(
                                        'icon',
                                        c.id === a.choice
                                            ? nameToString('check')
                                            : ''
                                    ),
                                    SPAN('choice__label', fromRecord(c.title))
                                )
                            )
                        ),
                        renderAnswerAnnotations(a, q)
                    )
                )
            )
        )
    );

const renderChoice = (_id: number) => DIV('target choice', 'choice');

const renderObservationAnnotations = (o: Observation) =>
    DIV(
        'annotation__list',
        getObservationAnnotations(o).map(ans => ans.map(renderAnnotation))
    );

const renderObservation = (id: number) =>
    DIV(
        'target observation',
        findObservation(id).map(o =>
            DIV('observation__wrapper', renderObservationAnnotations(o))
        )
    );

// const renderQuestionAnnotations = (q: Question) =>
//     DIV(
//         'annotation__list',
//         getQuestionAnnotations(q).map(ans => ans.map(renderAnnotation))
//     );

const renderQuestion = (id: number) =>
    DIV(
        'target observation',

        DIV(
            'question-being-annotated',
            findQuestion(id, getSelectedCollectId().getOrElse(-1)).map(q =>
                fromRecord(q.title)
            )
        )
        // findQuestion(id).map(q =>
        //     DIV(
        //         'question__infos',
        //         renderQuestionAnnotations(q)
        //     )
        // )
    );

const renderTarget = () =>
    getAnnotationTarget().map(({ kind, id }) => {
        switch (kind) {
            case 'annotation':
                return renderAnnotationX(id);
            case 'answer':
                return renderAnswer(id);
            case 'choice':
                return renderChoice(id);
            case 'observation':
                return renderObservation(id);
            case 'question':
                return renderQuestion(id);
        }
    });

const toggleTarget = () =>
    getAnnotationTarget().map(({ kind, id }) => {
        console.log(`TOGGLE: ${kind} - ${id}`);
        toggleAnnotation(kind, id);
    });

const renderText = () =>
    DIV(
        'text-form',
        H1({}, tr.smartwater('addTextTitle')),
        renderTarget(),
        DIV('helptext', tr.smartwater('annotateTextPlaceholder')),
        inputLongText(
            () => getAnnotationText().getOrElse(''),
            setAnnotationText,
            {
                rows: 5,
                // placeholder: tr.smartwater('annotateTextPlaceholder'),
            }
        ),
        DIV(
            'footer-actions',
            getAnnotationText().map(() =>
                buttonSend(() => {
                    saveTextAnnotation();
                    navigateBack();
                    toggleTarget();
                })
            ),
            buttonCancel(navigateBack)
        )
    );

const imageUploader = makeImageUploader(
    'image',
    imageUploaderQueries,
    imageUploaderEvents
);

const renderImage = () =>
    DIV(
        'image-form',
        H1({}, tr.smartwater('addImageTitle')),
        renderTarget(),
        DIV('helptext', tr.smartwater('annotateImagePlaceholder')),
        imageUploader(),
        imageUploaderQueries.getImageId().map(() =>
            buttonSend(() => {
                saveImageAnnotation();
                navigateBack();
                toggleTarget();
            })
        ),
        buttonCancel(navigateBack)
    );

export type AudioUploadStep = 'none' | 'uploading'; // | 'error'

const AUDIO_ACCEPT = [
    'audio/wav',
    'audio/mpeg',
    'audio/mp4',
    'audio/aac',
    'audio/aacp',
    'audio/ogg',
    'audio/webm',
    'audio/ogg',
    'audio/webm',
    'audio/flac',
];

const AUDIO_MAX_SIZE = 10000000;

const validateSound = (f: File) =>
    f.size < AUDIO_MAX_SIZE && AUDIO_ACCEPT.indexOf(f.type) >= 0
        ? some(f)
        : none;

const renderSoundInitial = () =>
    DIV(
        'sound-form',
        H1({}, tr.smartwater('addSoundTitle')),
        renderTarget(),
        DIV('helptext', tr.smartwater('annotateAudioPlaceholder')),
        INPUT({
            type: 'file',
            accept: AUDIO_ACCEPT.join(','),
            onChange: e => {
                fromNullable(e.currentTarget.files)
                    .chain(fl => index(0, Array.from(fl)))
                    .chain(validateSound)
                    .map(saveSoundAnnotation);
            },
        }),
        buttonCancel(navigateBack)
    );

const renderSoundUploading = () =>
    DIV(
        'sound-form',
        H1({}, tr.smartwater('addSoundTitle')),
        renderTarget(),
        DIV('helptext', '~UPLOADING')
    );

const renderSound = () => {
    switch (getSoundUploadStep()) {
        case 'none':
            return renderSoundInitial();
        case 'uploading':
            return renderSoundUploading();
    }
};

const render = () => {
    switch (getAnnotationKind()) {
        case 'text':
            return renderText();
        case 'image':
            return renderImage();
        case 'sound':
            return renderSound();
    }
};

export default render;
