import { DIV, H3, H1 } from 'sdi/components/elements';
import { Collect, Question } from 'smartwater/src/remote';
import { markdown } from 'sdi/ports/marked';
import {
    getChoices,
    getNbOfObservations,
    getObservedQuestions,
    getObserverQuestions,
    getSelectedCollect,
} from 'smartwater/src/queries/collect';
import { navigateBack } from 'smartwater/src/events/route';
import { buttonBack, renderButtonPrint } from '../buttons';
import tr, { fromRecord } from 'sdi/locale';

const renderErrorSelection = () =>
    DIV('error', tr.smartwater('error.noCampaign'));

// FIX ME
const renderQuestionChoices = (q: Question) =>
    DIV(
        'choice__list',
        getChoices(q.id).map(c =>
            DIV(
                {
                    className: 'choice__wrapper',
                    key: c.id,
                },
                DIV('choice__label', fromRecord(c.title)),
                DIV('choice__description', markdown(fromRecord(c.description)))
            )
        )
    );
// END FIX ME

const renderPrintDisclamer = () =>
    DIV(
        'print-disclaimer',
        buttonBack(() => navigateBack()),
        renderButtonPrint(() => window.print()),
        DIV('', tr.smartwater('printDisclaimer'))
    );

const renderPrintSpecificQuestionsTop = () =>
    DIV(
        'question print-questions',
        H3('print-question date', tr.smartwater('printFormDate')),
        H3('print-question place', tr.smartwater('printFormPlace'))
    );

const renderPrintSpecificQuestionsBottom = () =>
    DIV(
        'question print-questions',
        H3('print-question name', tr.smartwater('printFormName')),
        H3('print-question comment', tr.smartwater('printFormComment'))
    );

const renderPrintFooter = () =>
    DIV(
        'print-footer',
        // DIV('footer-item', `~guideline encodage appli + Lien`),
        DIV('footer-item', tr.smartwater('creditInnoviris'))
    );

const renderQuestionNumber = (n: number, total: number, q: Question) =>
    DIV(`question__number ${q.kind}`, `${n + 1}/${total}`);

const renderQuestion = (q: Question, index: number, questions: Question[]) =>
    DIV(
        {
            className: 'question',
            key: `question-${q.id}`,
        },
        DIV(
            'question__infos',
            renderQuestionNumber(index, questions.length, q),
            H3({}, fromRecord(q.title)),
            markdown(fromRecord(q.description)),
            renderQuestionChoices(q),
            // annotationTriggerQuestion(q)
        ),
        // renderAnnotationWrapperQuestion(q)
    );

const collectForm = (c: Collect) =>
    DIV(
        'page-2 question-lists__wrapper',
        H1('collect__title', fromRecord(c.title)),
        renderPrintSpecificQuestionsTop(),
        getObservedQuestions(c.id).map(renderQuestion),
        getObserverQuestions(c.id).map(renderQuestion),
        renderPrintSpecificQuestionsBottom(),
        renderPrintFooter()
    );

const renderSelection = (c: Collect) =>
    DIV(
        'collect-infos__body',
        DIV(
            'page-1 collect__description',

            DIV('logo-print'),
            H1('collect__title', fromRecord(c.title)),
            markdown(fromRecord(c.description)),
            DIV(
                'collect__meta',
                getNbOfObservations(c.id) > 1
                    ? `${getNbOfObservations(c.id)} ${tr.smartwater(
                        'labelObservationMultiple'
                    )}`
                    : `${getNbOfObservations(c.id)} ${tr.smartwater(
                        'labelObservationSingle'
                    )}`
            )
        ),
        collectForm(c)
    );

export const render = () =>
    DIV(
        'collect-print',
        renderPrintDisclamer(),
        getSelectedCollect().foldL(renderErrorSelection, renderSelection)
    );

export default render;
