import { Extent } from 'ol/extent';
import { MultiPolygon } from 'sdi/source';
import { getMultiPolygonExtent } from '../queries/map';

export const regionPolygon: MultiPolygon = {
    type: 'MultiPolygon',
    coordinates: [
        [
            [
                [154021, 173522],
                [153945, 173409],
                [153676, 173040],
                [153810, 172846],
                [153847, 172792],
                [154186, 172517],
                [154282, 172497],
                [154299, 172320],
                [154491, 172206],
                [154563, 172166],
                [154610, 172140],
                [154713, 172083],
                [154754, 172058],
                [154814, 172025],
                [154835, 172020],
                [154876, 172093],
                [155191, 172088],
                [155322, 171906],
                [155345, 171889],
                [155392, 171874],
                [155416, 171860],
                [155445, 171831],
                [155448, 171824],
                [155453, 171807],
                [155457, 171781],
                [155457, 171765],
                [155447, 171747],
                [155439, 171737],
                [155436, 171705],
                [155438, 171688],
                [155446, 171667],
                [155462, 171642],
                [155533, 171585],
                [155576, 171552],
                [155606, 171544],
                [155627, 171529],
                [155699, 171490],
                [155739, 171467],
                [155806, 171458],
                [155901, 171463],
                [155979, 171461],
                [155995, 171455],
                [156024, 171434],
                [156073, 171416],
                [156098, 171411],
                [156128, 171401],
                [156160, 171388],
                [156172, 171384],
                [156209, 171384],
                [156264, 171381],
                [156317, 171368],
                [156385, 171340],
                [156410, 171330],
                [156460, 171313],
                [156477, 171306],
                [156483, 171303],
                [156496, 171292],
                [156507, 171286],
                [156515, 171185],
                [156526, 171025],
                [156531, 171009],
                [156594, 170950],
                [156603, 170946],
                [156632, 170899],
                [156653, 170851],
                [156680, 170776],
                [156707, 170669],
                [156726, 170616],
                [156747, 170559],
                [156761, 170521],
                [156766, 170508],
                [156782, 170464],
                [156837, 170448],
                [156923, 170449],
                [156924, 170429],
                [156925, 170416],
                [156931, 170381],
                [156937, 170326],
                [156928, 170221],
                [156920, 170146],
                [156908, 169993],
                [156907, 169975],
                [156949, 169873],
                [156963, 169845],
                [156977, 169811],
                [156995, 169723],
                [157002, 169668],
                [156984, 169645],
                [156932, 169584],
                [156832, 169463],
                [156912, 169288],
                [156928, 169253],
                [157099, 168882],
                [157136, 168802],
                [157218, 168625],
                [157255, 168544],
                [157267, 168519],
                [157403, 168224],
                [157442, 168140],
                [157581, 167777],
                [157404, 167753],
                [156058, 167559],
                [156115, 167463],
                [156160, 167428],
                [156149, 167233],
                [155973, 167139],
                [155850, 167002],
                [155657, 166857],
                [155590, 166749],
                [155614, 166711],
                [155640, 166656],
                [155681, 166601],
                [155742, 166552],
                [155865, 166494],
                [155914, 166506],
                [156009, 166611],
                [156037, 166643],
                [156075, 166671],
                [156113, 166676],
                [156265, 166692],
                [156280, 166657],
                [156240, 166634],
                [156102, 166640],
                [155960, 166475],
                [155888, 166435],
                [155813, 166436],
                [155676, 166470],
                [155521, 166433],
                [156726, 165641],
                [156959, 165486],
                [157498, 165126],
                [157594, 165050],
                [157682, 165004],
                [157876, 164876],
                [157882, 164863],
                [157958, 164767],
                [158005, 164729],
                [157983, 164720],
                [157852, 164665],
                [157560, 164540],
                [157245, 164406],
                [157013, 164308],
                [156675, 164164],
                [156007, 163691],
                [155891, 163612],
                [155836, 163572],
                [155474, 163364],
                [155075, 163137],
                [154768, 162961],
                [154560, 163042],
                [154323, 162957],
                [153994, 162782],
                [153725, 162675],
                [153325, 162517],
                [153078, 162420],
                [152880, 162332],
                [152624, 162207],
                [152575, 162184],
                [152252, 162034],
                [151968, 161903],
                [151026, 161476],
                [150996, 161479],
                [150990, 161506],
                [150982, 161575],
                [150981, 161701],
                [150980, 161870],
                [150980, 162023],
                [150949, 162016],
                [150877, 162020],
                [150869, 162092],
                [150738, 162138],
                [150664, 162165],
                [150339, 162278],
                [150129, 162351],
                [150026, 162386],
                [149951, 162411],
                [149851, 162448],
                [149737, 162487],
                [149692, 162504],
                [149643, 162525],
                [149607, 162519],
                [149631, 162515],
                [149642, 162515],
                [149649, 162514],
                [149662, 162511],
                [149680, 162504],
                [149688, 162465],
                [149566, 162391],
                [149432, 162467],
                [149360, 162486],
                [149229, 162523],
                [149137, 162548],
                [149127, 162551],
                [149078, 162554],
                [149067, 162561],
                [149054, 162564],
                [149043, 162564],
                [149033, 162563],
                [149026, 162562],
                [149021, 162561],
                [149015, 162560],
                [148987, 162555],
                [148969, 162558],
                [148966, 162559],
                [148959, 162556],
                [148954, 162561],
                [148947, 162567],
                [148938, 162568],
                [148925, 162566],
                [148912, 162563],
                [148903, 162562],
                [148894, 162561],
                [148868, 162575],
                [148850, 162577],
                [148829, 162590],
                [148818, 162601],
                [148810, 162608],
                [148800, 162616],
                [148791, 162623],
                [148773, 162630],
                [148729, 162644],
                [148723, 162649],
                [148719, 162651],
                [148714, 162650],
                [148712, 162647],
                [148710, 162652],
                [148706, 162655],
                [148700, 162658],
                [148696, 162663],
                [148657, 162681],
                [148647, 162681],
                [148637, 162683],
                [148625, 162678],
                [148619, 162673],
                [148613, 162674],
                [148595, 162683],
                [148590, 162692],
                [148581, 162699],
                [148572, 162695],
                [148564, 162695],
                [148550, 162696],
                [148511, 162709],
                [148503, 162720],
                [148495, 162746],
                [148491, 162758],
                [148487, 162769],
                [148482, 162776],
                [148473, 162785],
                [148463, 162788],
                [148446, 162778],
                [148437, 162774],
                [148426, 162775],
                [148385, 162780],
                [148359, 162769],
                [148347, 162769],
                [148328, 162774],
                [148309, 162770],
                [148297, 162772],
                [148277, 162756],
                [148260, 162766],
                [148247, 162748],
                [148252, 162735],
                [148248, 162729],
                [148234, 162727],
                [148207, 162711],
                [148196, 162698],
                [148182, 162696],
                [148161, 162685],
                [148149, 162681],
                [148128, 162667],
                [148118, 162664],
                [148087, 162646],
                [148065, 162607],
                [148055, 162600],
                [148032, 162578],
                [148016, 162569],
                [147929, 162593],
                [147897, 162602],
                [147883, 162606],
                [147778, 162635],
                [147665, 162667],
                [147655, 162670],
                [147625, 162679],
                [147620, 162678],
                [147614, 162680],
                [147607, 162683],
                [147525, 162715],
                [147521, 162716],
                [147481, 162731],
                [147445, 162745],
                [147434, 162750],
                [147357, 162779],
                [147346, 162791],
                [147345, 162803],
                [147348, 162812],
                [147347, 162821],
                [147335, 162840],
                [147326, 162870],
                [147324, 162884],
                [147317, 162898],
                [147309, 162910],
                [147309, 162920],
                [147295, 162948],
                [147286, 162958],
                [147277, 162963],
                [147270, 162967],
                [147269, 162976],
                [147267, 162985],
                [147255, 162997],
                [147242, 163015],
                [147217, 163030],
                [147203, 163041],
                [147195, 163049],
                [147188, 163053],
                [147179, 163055],
                [147169, 163057],
                [147144, 163079],
                [147135, 163091],
                [147130, 163099],
                [147117, 163101],
                [147109, 163108],
                [147118, 163117],
                [147119, 163124],
                [147114, 163129],
                [147103, 163137],
                [147095, 163152],
                [147082, 163166],
                [147079, 163174],
                [147074, 163179],
                [147069, 163179],
                [147064, 163192],
                [147058, 163203],
                [147051, 163208],
                [147044, 163209],
                [147042, 163211],
                [147041, 163223],
                [147028, 163233],
                [147018, 163240],
                [147005, 163251],
                [146992, 163256],
                [146981, 163264],
                [146968, 163269],
                [146951, 163274],
                [146936, 163294],
                [146930, 163312],
                [146923, 163350],
                [146920, 163357],
                [146915, 163365],
                [146909, 163375],
                [146902, 163382],
                [146875, 163418],
                [146726, 163592],
                [146720, 163597],
                [146716, 163609],
                [146694, 163637],
                [146690, 163648],
                [146688, 163655],
                [146688, 163658],
                [146688, 163664],
                [146686, 163671],
                [146672, 163709],
                [146672, 163716],
                [146675, 163729],
                [146675, 163735],
                [146676, 163739],
                [146672, 163756],
                [146667, 163758],
                [146663, 163763],
                [146662, 163774],
                [146651, 163791],
                [146641, 163806],
                [146644, 163828],
                [146646, 163835],
                [146653, 163845],
                [146654, 163850],
                [146646, 163869],
                [146649, 163877],
                [146634, 163883],
                [146628, 163901],
                [146630, 163907],
                [146625, 163911],
                [146615, 163909],
                [146609, 163912],
                [146605, 163924],
                [146603, 163943],
                [146597, 163959],
                [146593, 163967],
                [146592, 163986],
                [146586, 164004],
                [146578, 164010],
                [146571, 164021],
                [146555, 164034],
                [146532, 164053],
                [146527, 164051],
                [146512, 164044],
                [146507, 164061],
                [146503, 164077],
                [146498, 164093],
                [146496, 164106],
                [146497, 164112],
                [146499, 164123],
                [146506, 164125],
                [146509, 164124],
                [146517, 164127],
                [146527, 164135],
                [146531, 164145],
                [146529, 164154],
                [146512, 164160],
                [146507, 164169],
                [146516, 164175],
                [146523, 164184],
                [146515, 164196],
                [146509, 164215],
                [146514, 164220],
                [146536, 164231],
                [146532, 164244],
                [146540, 164253],
                [146527, 164267],
                [146510, 164281],
                [146502, 164314],
                [146502, 164321],
                [146504, 164338],
                [146497, 164343],
                [146489, 164358],
                [146488, 164363],
                [146498, 164368],
                [146503, 164372],
                [146501, 164393],
                [146505, 164399],
                [146507, 164409],
                [146499, 164417],
                [146493, 164423],
                [146489, 164431],
                [146490, 164439],
                [146487, 164447],
                [146481, 164451],
                [146476, 164459],
                [146474, 164467],
                [146468, 164473],
                [146450, 164469],
                [146439, 164464],
                [146434, 164479],
                [146439, 164518],
                [146447, 164542],
                [146450, 164550],
                [146454, 164568],
                [146454, 164594],
                [146467, 164608],
                [146469, 164626],
                [146455, 164651],
                [146442, 164661],
                [146436, 164667],
                [146435, 164687],
                [146442, 164709],
                [146436, 164722],
                [146440, 164754],
                [146447, 164777],
                [146434, 164787],
                [146432, 164802],
                [146419, 164815],
                [146420, 164838],
                [146424, 164865],
                [146412, 164888],
                [146413, 164920],
                [146400, 164944],
                [146370, 164965],
                [146369, 164977],
                [146363, 164990],
                [146347, 165014],
                [146344, 165017],
                [146340, 165022],
                [146335, 165046],
                [146324, 165064],
                [146322, 165079],
                [146307, 165100],
                [146296, 165102],
                [146289, 165096],
                [146282, 165094],
                [146270, 165104],
                [146255, 165104],
                [146243, 165109],
                [146233, 165106],
                [146219, 165112],
                [146205, 165122],
                [146190, 165111],
                [146180, 165109],
                [146165, 165108],
                [146152, 165101],
                [146147, 165101],
                [146148, 165110],
                [146152, 165122],
                [146149, 165128],
                [146147, 165135],
                [146154, 165140],
                [146157, 165145],
                [146152, 165153],
                [146143, 165162],
                [146143, 165172],
                [146136, 165176],
                [146130, 165173],
                [146121, 165181],
                [146101, 165189],
                [146083, 165200],
                [146071, 165210],
                [146060, 165217],
                [146042, 165222],
                [146031, 165232],
                [146006, 165234],
                [145988, 165242],
                [145966, 165248],
                [145932, 165239],
                [145912, 165246],
                [145907, 165272],
                [145908, 165279],
                [145827, 165292],
                [145747, 165309],
                [145556, 165349],
                [145531, 165354],
                [145516, 165354],
                [145507, 165362],
                [145501, 165369],
                [145451, 165508],
                [145450, 165572],
                [145412, 165678],
                [145403, 165704],
                [145396, 165729],
                [145382, 165754],
                [145373, 165763],
                [145352, 165795],
                [145330, 165836],
                [145310, 165877],
                [145297, 165926],
                [145301, 165983],
                [145314, 166010],
                [145340, 166057],
                [145370, 166122],
                [145372, 166170],
                [145377, 166244],
                [145387, 166303],
                [145394, 166323],
                [145437, 166435],
                [145460, 166516],
                [145475, 166531],
                [145537, 166532],
                [145552, 166637],
                [145556, 166669],
                [145553, 166694],
                [145549, 166706],
                [145558, 166761],
                [145588, 166835],
                [145611, 166890],
                [145446, 166925],
                [145299, 166954],
                [145231, 166929],
                [145154, 166946],
                [145133, 166914],
                [145124, 166888],
                [145118, 166860],
                [145096, 166850],
                [145111, 166797],
                [145079, 166767],
                [145029, 166775],
                [145017, 166757],
                [145001, 166716],
                [145009, 166672],
                [145010, 166663],
                [145007, 166660],
                [145000, 166660],
                [144981, 166628],
                [144971, 166602],
                [144953, 166605],
                [144922, 166607],
                [144907, 166616],
                [144906, 166601],
                [144903, 166590],
                [144862, 166586],
                [144861, 166580],
                [144874, 166556],
                [144868, 166540],
                [144846, 166527],
                [144820, 166516],
                [144799, 166492],
                [144783, 166482],
                [144766, 166432],
                [144743, 166419],
                [144734, 166426],
                [144725, 166421],
                [144715, 166433],
                [144700, 166435],
                [144706, 166447],
                [144693, 166453],
                [144687, 166470],
                [144677, 166490],
                [144665, 166500],
                [144654, 166477],
                [144647, 166475],
                [144636, 166467],
                [144617, 166470],
                [144582, 166475],
                [144556, 166498],
                [144517, 166489],
                [144459, 166479],
                [144434, 166477],
                [144409, 166471],
                [144392, 166455],
                [144371, 166454],
                [144324, 166445],
                [144243, 166438],
                [144195, 166431],
                [144173, 166386],
                [144081, 166315],
                [144014, 166293],
                [144000, 166303],
                [143998, 166322],
                [143981, 166310],
                [143970, 166318],
                [143920, 166316],
                [143860, 166340],
                [143820, 166350],
                [143780, 166348],
                [143753, 166350],
                [143721, 166348],
                [143707, 166361],
                [143690, 166357],
                [143675, 166378],
                [143673, 166397],
                [143659, 166404],
                [143636, 166420],
                [143608, 166431],
                [143540, 166428],
                [143530, 166447],
                [143516, 166489],
                [143511, 166508],
                [143506, 166526],
                [143496, 166545],
                [143490, 166570],
                [143481, 166597],
                [143496, 166626],
                [143485, 166636],
                [143476, 166650],
                [143466, 166666],
                [143458, 166691],
                [143447, 166709],
                [143437, 166727],
                [143397, 166774],
                [143345, 166814],
                [143285, 166841],
                [143239, 166860],
                [143204, 166864],
                [143178, 166860],
                [143152, 166854],
                [143124, 166855],
                [143085, 166861],
                [143053, 166855],
                [143021, 166851],
                [142989, 166842],
                [142940, 166849],
                [142916, 166846],
                [142908, 166832],
                [142886, 166804],
                [142857, 166796],
                [142827, 166803],
                [142792, 166801],
                [142775, 166793],
                [142761, 166807],
                [142740, 166818],
                [142712, 166821],
                [142668, 166806],
                [142642, 166800],
                [142630, 166792],
                [142617, 166797],
                [142602, 166796],
                [142575, 166792],
                [142544, 166784],
                [142532, 166772],
                [142517, 166767],
                [142513, 166758],
                [142496, 166755],
                [142485, 166744],
                [142465, 166739],
                [142460, 166741],
                [142466, 166745],
                [142468, 166754],
                [142460, 166752],
                [142453, 166757],
                [142445, 166757],
                [142437, 166751],
                [142430, 166755],
                [142424, 166759],
                [142415, 166753],
                [142409, 166746],
                [142394, 166750],
                [142386, 166750],
                [142376, 166743],
                [142362, 166747],
                [142345, 166744],
                [142338, 166742],
                [142330, 166746],
                [142324, 166770],
                [142297, 166924],
                [142274, 167039],
                [142264, 167066],
                [142231, 167164],
                [142214, 167211],
                [142130, 167355],
                [142082, 167437],
                [142029, 167455],
                [141967, 167443],
                [141866, 167430],
                [141724, 167428],
                [141617, 167675],
                [141528, 167657],
                [141456, 167647],
                [141404, 167533],
                [141411, 167463],
                [141261, 167572],
                [141234, 167688],
                [141369, 167855],
                [141476, 167968],
                [141518, 168018],
                [141691, 168225],
                [141855, 168309],
                [142000, 168599],
                [142022, 168680],
                [141991, 168711],
                [141990, 168717],
                [142032, 168779],
                [142030, 168830],
                [142026, 168896],
                [141996, 169014],
                [141972, 169047],
                [141945, 169108],
                [141961, 169156],
                [141965, 169193],
                [141965, 169204],
                [141960, 169234],
                [141989, 169272],
                [142049, 169351],
                [142091, 169406],
                [142155, 169452],
                [142257, 169514],
                [142338, 169460],
                [142364, 169448],
                [142432, 169433],
                [142474, 169430],
                [142529, 169447],
                [142553, 169440],
                [142584, 169437],
                [142587, 169456],
                [142621, 169463],
                [142655, 169492],
                [142882, 169543],
                [142949, 169552],
                [142999, 169552],
                [143035, 169549],
                [143146, 169539],
                [143183, 169565],
                [143173, 169612],
                [143231, 169700],
                [143263, 169786],
                [143286, 169808],
                [143315, 169820],
                [143393, 169844],
                [143414, 169836],
                [143459, 169807],
                [143501, 169773],
                [143520, 169763],
                [143545, 169741],
                [143585, 169717],
                [143602, 169720],
                [143640, 169715],
                [143696, 169723],
                [143741, 169730],
                [143779, 169724],
                [143815, 169722],
                [143840, 169724],
                [143875, 169721],
                [143921, 169723],
                [143957, 169724],
                [143941, 169912],
                [143919, 169912],
                [143874, 169906],
                [143912, 169967],
                [143964, 169975],
                [143999, 170023],
                [144013, 170026],
                [143984, 170138],
                [143999, 170164],
                [144018, 170227],
                [144010, 170377],
                [144059, 170380],
                [144065, 170387],
                [144080, 170498],
                [144077, 170529],
                [144073, 170544],
                [144063, 170576],
                [144077, 170632],
                [144075, 170670],
                [144066, 170685],
                [144051, 170693],
                [144026, 170712],
                [144006, 170726],
                [143990, 170733],
                [143979, 170734],
                [143991, 170768],
                [144023, 170844],
                [144037, 170863],
                [144068, 170879],
                [144100, 170884],
                [144150, 170841],
                [144184, 170821],
                [144234, 170827],
                [144259, 170835],
                [144279, 170846],
                [144322, 170873],
                [144322, 170891],
                [144290, 170972],
                [144281, 170992],
                [144264, 171055],
                [144232, 171135],
                [144207, 171203],
                [144199, 171221],
                [144181, 171248],
                [144144, 171284],
                [144037, 171392],
                [143988, 171433],
                [143970, 171464],
                [143980, 171494],
                [143990, 171507],
                [144054, 171554],
                [144069, 171563],
                [144104, 171575],
                [144151, 171578],
                [144193, 171582],
                [144227, 171595],
                [144429, 171712],
                [144428, 171758],
                [144371, 171864],
                [144250, 171905],
                [144035, 171996],
                [143994, 172048],
                [144026, 172099],
                [143938, 172148],
                [143918, 172112],
                [143825, 172144],
                [143813, 172240],
                [143811, 172247],
                [143800, 172286],
                [143775, 172349],
                [143777, 172385],
                [143784, 172421],
                [143765, 172472],
                [143743, 172537],
                [143730, 172585],
                [143720, 172613],
                [143716, 172637],
                [143702, 172658],
                [143704, 172672],
                [143702, 172692],
                [143696, 172713],
                [143711, 172736],
                [143723, 172753],
                [143697, 172782],
                [143690, 172797],
                [143707, 172825],
                [143774, 172968],
                [143810, 172979],
                [143842, 172974],
                [143859, 172998],
                [143876, 173016],
                [143891, 173042],
                [143911, 173058],
                [143928, 173071],
                [143951, 173093],
                [143968, 173112],
                [143984, 173129],
                [143998, 173146],
                [144016, 173160],
                [144028, 173170],
                [144030, 173178],
                [144051, 173210],
                [144062, 173235],
                [144077, 173253],
                [144115, 173283],
                [144133, 173311],
                [144131, 173331],
                [144131, 173363],
                [144130, 173370],
                [144127, 173385],
                [144132, 173390],
                [144149, 173425],
                [144153, 173440],
                [144147, 173449],
                [144147, 173457],
                [144155, 173467],
                [144175, 173501],
                [144189, 173525],
                [144199, 173532],
                [144209, 173536],
                [144218, 173540],
                [144223, 173544],
                [144226, 173549],
                [144251, 173576],
                [144268, 173599],
                [144300, 173629],
                [144306, 173636],
                [144309, 173648],
                [144316, 173655],
                [144326, 173661],
                [144331, 173668],
                [144350, 173677],
                [144366, 173686],
                [144377, 173685],
                [144382, 173683],
                [144383, 173683],
                [144386, 173690],
                [144402, 173698],
                [144422, 173709],
                [144440, 173718],
                [144458, 173729],
                [144471, 173735],
                [144475, 173734],
                [144482, 173739],
                [144487, 173745],
                [144491, 173745],
                [144504, 173749],
                [144511, 173758],
                [144528, 173759],
                [144539, 173769],
                [144547, 173770],
                [144564, 173781],
                [144564, 173785],
                [144558, 173795],
                [144567, 173794],
                [144573, 173791],
                [144578, 173799],
                [144578, 173807],
                [144590, 173822],
                [144601, 173839],
                [144598, 173847],
                [144579, 173864],
                [144566, 173874],
                [144557, 173878],
                [144555, 173884],
                [144585, 173864],
                [144604, 173902],
                [144608, 173907],
                [144612, 173908],
                [144621, 173907],
                [144628, 173908],
                [144631, 173912],
                [144631, 173916],
                [144632, 173921],
                [144635, 173923],
                [144642, 173923],
                [144649, 173923],
                [144655, 173926],
                [144659, 173931],
                [144662, 173936],
                [144669, 173942],
                [144675, 173946],
                [144684, 173951],
                [144696, 173961],
                [144705, 173969],
                [144714, 173979],
                [144719, 173990],
                [144732, 173994],
                [144730, 174004],
                [144726, 174015],
                [144734, 174013],
                [144754, 174035],
                [144770, 174046],
                [144775, 174047],
                [144782, 174058],
                [144782, 174061],
                [144780, 174062],
                [144773, 174060],
                [144767, 174060],
                [144766, 174062],
                [144767, 174069],
                [144766, 174074],
                [144759, 174081],
                [144756, 174087],
                [144759, 174089],
                [144763, 174089],
                [144770, 174090],
                [144778, 174095],
                [144784, 174098],
                [144792, 174101],
                [144794, 174105],
                [144795, 174110],
                [144797, 174116],
                [144802, 174120],
                [144809, 174122],
                [144816, 174122],
                [144823, 174125],
                [144824, 174129],
                [144829, 174133],
                [144836, 174132],
                [144847, 174138],
                [144857, 174142],
                [144859, 174147],
                [144860, 174152],
                [144863, 174153],
                [144867, 174153],
                [144871, 174154],
                [144874, 174158],
                [144877, 174166],
                [144881, 174168],
                [144884, 174169],
                [144889, 174174],
                [144897, 174182],
                [144904, 174185],
                [144909, 174189],
                [144915, 174194],
                [144919, 174195],
                [144925, 174200],
                [144927, 174206],
                [144931, 174209],
                [144940, 174213],
                [144946, 174215],
                [144951, 174218],
                [144959, 174223],
                [144963, 174228],
                [144968, 174235],
                [144978, 174241],
                [144983, 174245],
                [144987, 174248],
                [144991, 174249],
                [144993, 174250],
                [144994, 174254],
                [144995, 174258],
                [144999, 174260],
                [145004, 174262],
                [145008, 174265],
                [145011, 174269],
                [145027, 174283],
                [145040, 174301],
                [145052, 174314],
                [145061, 174331],
                [145064, 174341],
                [145060, 174353],
                [145061, 174366],
                [145073, 174377],
                [145080, 174387],
                [145082, 174398],
                [145081, 174403],
                [145079, 174412],
                [145077, 174428],
                [145079, 174451],
                [145069, 174484],
                [145051, 174562],
                [145038, 174633],
                [145037, 174647],
                [145033, 174665],
                [145035, 174681],
                [145034, 174691],
                [145030, 174704],
                [145024, 174714],
                [145020, 174725],
                [145020, 174741],
                [145017, 174770],
                [145016, 174789],
                [145014, 174796],
                [145007, 174814],
                [145001, 174824],
                [144993, 174847],
                [144986, 174863],
                [144979, 174886],
                [144975, 174905],
                [144963, 174929],
                [144957, 174951],
                [144953, 174968],
                [144951, 174970],
                [144949, 174971],
                [144946, 174971],
                [144943, 174971],
                [144920, 174960],
                [144910, 174963],
                [144895, 174975],
                [144840, 175017],
                [144784, 175082],
                [144746, 175158],
                [144732, 175214],
                [144731, 175309],
                [144729, 175397],
                [145098, 175521],
                [145216, 175561],
                [145242, 175557],
                [145308, 175562],
                [145371, 175579],
                [145492, 175600],
                [145575, 175625],
                [145628, 175624],
                [145672, 175627],
                [145711, 175645],
                [145726, 175652],
                [145804, 175702],
                [145850, 175724],
                [145919, 175758],
                [146028, 175824],
                [146127, 175883],
                [146182, 175916],
                [146408, 176009],
                [146438, 176021],
                [146485, 176045],
                [146558, 176098],
                [146602, 176144],
                [146630, 176181],
                [146653, 176213],
                [146724, 176313],
                [146754, 176352],
                [146806, 176402],
                [146851, 176433],
                [146932, 176486],
                [147041, 176555],
                [147071, 176574],
                [147096, 176590],
                [147212, 176664],
                [147277, 176702],
                [147335, 176729],
                [147412, 176760],
                [147499, 176785],
                [147602, 176806],
                [147777, 176839],
                [147830, 176849],
                [147911, 176864],
                [148037, 176888],
                [148128, 176897],
                [148171, 176897],
                [148290, 176887],
                [148368, 176867],
                [148442, 176840],
                [148554, 176801],
                [148621, 176792],
                [148790, 176776],
                [148807, 176774],
                [148894, 176770],
                [149197, 176781],
                [149262, 176784],
                [149355, 176766],
                [149421, 176759],
                [149536, 176749],
                [149597, 176748],
                [149662, 176756],
                [149848, 176659],
                [149960, 176600],
                [150142, 176503],
                [150159, 176500],
                [150230, 176483],
                [150285, 176465],
                [150328, 176455],
                [150477, 176404],
                [150528, 176370],
                [150548, 176353],
                [150583, 176332],
                [150601, 176308],
                [150611, 176293],
                [150635, 176304],
                [150675, 176357],
                [150666, 176408],
                [150660, 176443],
                [150674, 176465],
                [150654, 176510],
                [150651, 176534],
                [150691, 176620],
                [150732, 176688],
                [150802, 176745],
                [150883, 176777],
                [150996, 176828],
                [151001, 176833],
                [151088, 177002],
                [151111, 177044],
                [151116, 177053],
                [151182, 177188],
                [151297, 177527],
                [151332, 177706],
                [151389, 177772],
                [151442, 177783],
                [151558, 177744],
                [151601, 177746],
                [151698, 177736],
                [151817, 177782],
                [151959, 177904],
                [151987, 177932],
                [152026, 177973],
                [152129, 178074],
                [152211, 178110],
                [152297, 178114],
                [152450, 178169],
                [152613, 178097],
                [152639, 178083],
                [152807, 178041],
                [152931, 178010],
                [152991, 177988],
                [153080, 177955],
                [153114, 177942],
                [153155, 177925],
                [153191, 177908],
                [153202, 177894],
                [153183, 177835],
                [153164, 177771],
                [153144, 177666],
                [153104, 177637],
                [153082, 177607],
                [153071, 177526],
                [153055, 177417],
                [153075, 177409],
                [153079, 177406],
                [153086, 177398],
                [153091, 177390],
                [153102, 177374],
                [153125, 177365],
                [153178, 177356],
                [153353, 177281],
                [153412, 177251],
                [153456, 177233],
                [153428, 177140],
                [153434, 177127],
                [153463, 177078],
                [153548, 177054],
                [153578, 177045],
                [153625, 177031],
                [153654, 177021],
                [153673, 177001],
                [153689, 176985],
                [153754, 176983],
                [153785, 176917],
                [153795, 176924],
                [153844, 176927],
                [153911, 176846],
                [153921, 176827],
                [153953, 176792],
                [153988, 176774],
                [153993, 176756],
                [154034, 176741],
                [154031, 176719],
                [154054, 176710],
                [154063, 176674],
                [154091, 176658],
                [154124, 176639],
                [154155, 176626],
                [154143, 176600],
                [154110, 176578],
                [154064, 176526],
                [154038, 176476],
                [154021, 176464],
                [154041, 176444],
                [154066, 176407],
                [154081, 176388],
                [154107, 176371],
                [154136, 176360],
                [154177, 176351],
                [154207, 176339],
                [154230, 176324],
                [154293, 176268],
                [154397, 176156],
                [154402, 176146],
                [154397, 176110],
                [154399, 176103],
                [154414, 176096],
                [154456, 176076],
                [154488, 176057],
                [154514, 176036],
                [154444, 175950],
                [154422, 175921],
                [154389, 175897],
                [154262, 175842],
                [154227, 175796],
                [154201, 175764],
                [154179, 175734],
                [154124, 175655],
                [154103, 175596],
                [154111, 175513],
                [154113, 175499],
                [154129, 175453],
                [154158, 175398],
                [154196, 175339],
                [154281, 175221],
                [154319, 175153],
                [154336, 175120],
                [154362, 175078],
                [154641, 174558],
                [154658, 174539],
                [154721, 174428],
                [154742, 174392],
                [154774, 174332],
                [154821, 174248],
                [154444, 174182],
                [154184, 174135],
                [154103, 174066],
                [154127, 173855],
                [154035, 173582],
                [154026, 173537],
                [154021, 173522],
            ],
        ],
    ],
};

export const regionExtent = (): Extent => {
    const extent = getMultiPolygonExtent(regionPolygon);
    const buffer = 1000;
    return [
        extent[0] - buffer,
        extent[1] - buffer,
        extent[2] + buffer,
        extent[3] + buffer,
    ];
};
