import {
    BUTTON,
    DETAILS,
    DIV,
    H3,
    SPAN,
    SUMMARY,
} from 'sdi/components/elements';
import {
    getSelectedObservation,
    getAnswers,
    getQuestionForAnswer,
    getChoices,
    getQuestions,
    getSelectedCollect,
} from '../queries/collect';
import { Observation, Answer, Question } from '../remote';
import tr, { formatDate, fromRecord } from 'sdi/locale';
import { partitionArray, notEmpty } from 'sdi/util';
import {
    renderAnnotateAnswer,
    renderAnswerAnnotations,
    // renderObservationAnnotations,
    // renderAnnotateObservation,
} from './annotation';
import { findUser } from '../queries/app';
import { renderQuestion } from './form/answer';
import { navigateCollect } from '../events/route';
import { getUserData } from '../queries/login';
import { nameToString } from 'sdi/components/button/names';

const renderErrorSelection = () =>
    DIV('error', tr.smartwater('errorSelectionLabel'));

const renderAnswer = (a: Answer) =>
    DIV(
        {
            className: 'answer__wrapper',
            key: `answer-${a.id}`,
        },
        getQuestionForAnswer(a).map(q =>
            DIV(
                'question',
                H3({}, fromRecord(q.title)),
                DIV(
                    'choice__list',
                    getChoices(q.id).map(c =>
                        DIV(
                            {
                                className:
                                    c.id === a.choice
                                        ? 'choice selected'
                                        : 'choice unselected',
                                key: c.id,
                            },
                            SPAN(
                                'icon',
                                c.id === a.choice ? nameToString('check') : ''
                            ),
                            SPAN('choice__label', fromRecord(c.title))
                        )
                    )
                ),
                renderAnswerAnnotations(a, q),
                renderAnnotateAnswer(a)
            )
        )
    );

const renderNonAnsweredQuestions = (qs: Question[]) =>
    DETAILS(
        'non-anwswered-questions',
        SUMMARY(
            '',
            DIV('btn btn-2 expand', tr.smartwater('questionWithoutAnswer'))
        ),
        ...qs.map(renderQuestion)
    );

const renderObservation = (o: Observation) => {
    const answers = getAnswers(o.id);
    const answeredQuestionIds = answers
        .map(a =>
            getQuestionForAnswer(a)
                .map(q => q.id)
                .getOrElse(0)
        )
        .filter(qid => qid > 0);
    const nonAsweredQuestions = getQuestions(o.collect).filter(
        q => answeredQuestionIds.indexOf(q.id) < 0
    );

    const [r, d] = partitionArray(answers, a =>
        getQuestionForAnswer(a)
            .map(q => q.kind === 'observer')
            .getOrElse(false)
    );

    return DIV(
        'observation',
        // getCurrentCommunity().map(c =>
        //     renderButtonCommunity(c.name)(() => navigateCommunity(c.id))
        // ),
        getSelectedCollect().map(c =>
            BUTTON({ onClick: () => navigateCollect(c.id) }, 'TEST')
        ),
        DIV(
            'obsevration-meta',
            `${tr.smartwater('observationObservedOn')} ${formatDate(
                new Date(o.timestamp)
            )} ${tr.smartwater('observationObservedBy')} ${findUser(o.user)
                .map(u => u.name)
                .getOrElse('na')}`
        ),
        // No more annotation at this level, right ?
        // renderObservationAnnotations(o),
        DIV('observed', ...d.map(renderAnswer)),
        DIV('observer', ...r.map(renderAnswer)),

        // FIX-ME : Let's make it visiblke only when you are the author of the observation

        getUserData()
            .map(({ name }) => name)
            .getOrElse('nope') ===
            findUser(o.user)
                .map(u => u.name)
                .getOrElse('na')
            ? notEmpty(nonAsweredQuestions).map(renderNonAnsweredQuestions)
            : ''
    );
};

export const render = () =>
    DIV(
        'observation-wrapper',
        getSelectedObservation().foldL(renderErrorSelection, renderObservation)
    );

export default render;
