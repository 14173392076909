import { IMapViewData, Interaction, defaultInteraction, IViewEvent, FeaturePath } from 'sdi/map';

declare module 'sdi/shape' {
    export interface IShape {
        'port/map/view': IMapViewData;
        'port/map/interaction': Interaction;
        'port/map/selection': FeaturePath;
        'port/map/observation/view': IMapViewData;
    }
}

export const defaultMapView = (): IMapViewData => ({
    dirty: 'geo',
    srs: 'EPSG:3857',
    center: [4.3623, 50.8383],
    rotation: 0,
    zoom: 8,
    feature: null,
    extent: null,
});

export const defaultMapEvent = (): IViewEvent => ({
    dirty: 'geo',
    center: [148885, 170690],
    rotation: 0,
    zoom: 8,
});


export const defaultMapState = () => ({
    'port/map/view': defaultMapView(),
    'port/map/interaction': defaultInteraction(),
    'port/map/selection': { layerId: null, featureId: null },
    'port/map/observation/view': defaultMapView(),
});
