const communityListPitchFR = `
Rejoignez les campagnes d'observations collectives en cours près de vous. 

`;
const communityListPitchNL = `
Sluit je aan bij de collectieve observatiecampagnes die bij jou in de buurt lopen. 
`;
const communityListPitchEN = `
Join the collective observation campaigns underway near you. 
`;
const aboutAppFR = `
Cette application web (single page application) a été créée dans le cadre du projet [Smartwater](http://www.smartwater.brussels) (financé par [Innoviris](https://innoviris.brussels)) pour mener des campagnes d'observations citoyennes relatives aux multiples dimensions de la qualité de l'eau à Bruxelles. Elle ne nécéssite pas de téléchargement. Vous y accédez simplement en tapant l'url dans un navigateur web.

Ces observations peuvent être menées par toutes et tous (habitants, usagers, gestionnaires publics et associatifs) autour de lieux spécifiques où se trouve une ou plusieurs pièces d’eau (étangs, ruisseaux, rivières, canaux, etc.).    

Les observations sont libres, mais peuvent parfois entrer dans le cadre d’une campagne d’observations collectives autour d’un lieu spécifique ou organisée à l’échelle régionale. Le calendrier de ces campagnes est disponible sur le [site web de Smartwater](https://smartwater.brussels). Pour chacun des lieux où une campagne d’observations est en cours, une série de formulaires est disponible. Pour chacune des questions posées, vous avez, si besoin, la possibilité d’annoter ou d’associer une photo à votre réponse.    

En encourageant la participation citoyenne, cette application et les observations qui y sont faites nous invitent à prendre soin collectivement de l’eau qui nous entoure. C’est au sein des équipes du projet SmartWater que les campagnes d’observations sont organisées, et que la pertinence et la nécessité d’intégrer l’un ou l’autre formulaire d’observations à une campagne d’observation sont décidées. Ces équipes sont donc également impliquées dans la création des formulaires eux-mêmes structurés sous forme de questionnaires à choix multiple dans le but de faciliter le traitement de l’information. A la fin d’une campagne, les citoyens (ou observateurs) peuvent, s’ils le souhaitent, rester impliqués dans l’analyse et la visualisation des données collectées.

Pour associer un nouveau lieu à une campagne d’observations collective: contact@smartwater.brussels

`;
const aboutAppNL = `Deze webapplicatie werd gecreëerd in het kader van het [SmartWater-project](http://www.smartwater.brussels)  (gefinancierd door [Innoviris](https://innoviris.brussels)) om burgerobservatiecampagnes te voeren over de verschillende dimensies van de waterkwaliteit in Brussel. Je hoeft de app niet te downloaden, ze is rechtstreeks toegankelijk als je de url intikt in een webbrowser.
 
De observaties hebben betrekking op specifieke plekken waar zich een waterlichaam bevindt (vijvers, beken, rivieren, kanalen) en kunnen door iedereen vrij worden uitgevoerd (inwoners, gebruikers, openbare beheerders en verenigingen). Soms sluiten ze aan bij een collectieve observatiecampagne die betrekking heeft op een specifieke locatie of die op gewestelijke schaal georganiseerd wordt. De kalender van deze collectieve campagnes is beschikbaar op de [Smartwater-website.](http://www.smartwater.brussels)  Voor elke plek waar een observatiecampagne aan de gang is, is een reeks vragenlijsten beschikbaar. Bij elke vraag kan desgewenst een aantekening of foto aan het antwoord toegevoegd worden. 
 
Door de burgerparticipatie te stimuleren nodigen deze app en de observaties die ermee gemaakt worden ons uit om collectief te zorgen voor het water dat ons omringt. Het is binnen de teams van het SmartWater-project dat de observatiecampagnes worden georganiseerd en dat beslist wordt over de relevantie en de noodzaak om een of andere observatievorm op te nemen in een campagne. Daarom worden deze teams ook betrokken bij het opstellen van de formulieren, die gestructureerd zijn als meerkeuzevragenlijsten om de verwerking van de informatie te vergemakkelijken. Aan het einde van een campagne kunnen deze burgers (of observators), indien ze dit wensen, betrokken blijven bij de analyse en visualisatie van de verzamelde data.
 
 
Om een nieuwe plek te koppelen aan een collectieve observatiecampagne: contact@smartwater.brussels
`;
const aboutAppEN = `
This web application (single page application) was created within the framework of the [Smartwater project](http://www.smartwater.brussels) to conduct citizen observation campaigns on the multiple dimensions of water quality in Brussels. It does not require any download. You can access it simply by typing the url in a web browser.

The observation campaigns are carried out by hybrid groups of inhabitants, users, public managers and associations around specific places where there is a piece of water (ponds, streams, rivers, canals, etc.). These citizen observation campaigns invite us to collectively take care of the water that surrounds us. In the framework of the [Smartwater project](http://www.smartwater.brussels), the involvement of these collectives is essential to assess the relevance and necessity of integrating one or other observation form into a campaign. These groups are therefore involved in the creation of the forms, which are structured as multiple choice questionnaires to facilitate the processing of the information. At the end of a campaign, they remain involved in the analysis and visualisation of the data collected.

For each place where an observation campaign is underway, a series of questionnaires is available. For each question, you can annotate or associate a photo with your answer. 

You can join a collective observation campaign around a specific place or participate individually in the regional observation campaign. The collective campaign calendar is available on the [Smartwater website](http://www.smartwater.brussels).

To associate a new place to a collective observation campaign: contact@atelier-cartographique.be
`;
const homeDescriptionFR = `
Bienvenue sur l'application web Smartwater!    
`;
const homeDescriptionNL = `
Welkom bij de SmartWater-webapplicatie!  
`;
const homeDescriptionEN = `
Welcome to the Smartwater web application!   
`;
const helptexthomeFR = ``;
const helptexthomeNL = `Welkom`;
const helptexthomeEN = `welcome`;
const gdprTextFR = `
### INFORMATION RELATIVE À LA PROTECTION DES DONNÉES PERSONNELLES EX ART 13 RÈGLEMENT EUROPÉEN 2016/679 (" GDPR ").    

En vertu et aux fins des dispositions de l'art. 13 du GDPR, nous vous informons que vos données personnelles seront traitées à l'aide d'outils électroniques et manuels en Belgique et à l'étranger.
Cette information, établie sur la base du principe de transparence et de tous les éléments requis par le GDPR, est divisée en différentes sections, chacune traitant d'un sujet spécifique afin de rendre la lecture plus rapide, plus facile et simple à comprendre (ci-après les "Informations").

**Contrôleur de données**    
Le Contrôleur des données (ci-après " Contrôleur des données ") est atelier cartographique dont le siège social est situé rue Van Elewyck 47,1050 Bruxelles.

**Base juridique du traitement**    
La base juridique du traitement de vos données personnelles par le Contrôleur de données est :
- le consentement que vous avez exprimé par l'activation et l'enregistrement éventuel dans la web-app appelée "SmartWater - Collect".

**Finalité du traitement**        
La collecte et le traitement de vos données personnelles sont effectués pour les finalités suivantes :    
- Collecte et gestion des rapports d'observations environnementales telles que la présence de phénomènes indésirables, par exemple : efflorescences algales, mousses, taches huileuses, litières, odeurs, vidanges et aspects faunistiques (présence d'animaux décédés ou d'espèces exotiques) ;
- L'envoi de rapports aux autorités compétentes (ex : Environnement Bruxelles - Leefmilieu Brussel) ;
Le consentement au traitement de vos données est obligatoire pour la réalisation des finalités susmentionnées et le défaut, l'attribution partielle et incorrecte rendra impossible l'accès aux fonctionnalités de l'application "SmartWater - Collect" réservées aux utilisateurs enregistrés .

**Retrait du consentement**    
Vous avez le droit de retirer totalement et/ou partiellement votre consentement en envoyant un courriel à contact@atelier-cartographique.be à tout moment, sans préjudice de la licéité du traitement fondé sur le consentement donné avant la révocation.


**Communication des données**    
Vos données personnelles seront traitées exclusivement par des personnes autorisées à traiter (article 29 du GDPR) et par des tiers expressément désignés en tant que Processeurs de données (article 28 du GDPR), afin d'effectuer toutes les activités de traitement nécessaires à la poursuite des objectifs détaillés dans le présent Avis. Les Données personnelles peuvent être divulguées aux organismes publics ou aux autorités judiciaires, lorsque la loi l'exige ou pour prévenir ou réprimer la commission d'un crime.

**Période de conservation des données**    
Vos données personnelles collectées seront traitées par le Contrôleur des données pendant la durée strictement nécessaire à la réalisation des finalités susmentionnées, à l'exception d'une période de conservation supplémentaire pouvant être imposée par des obligations légales.

**Portée territoriale du traitement**    
Vos données personnelles seront traitées par le Contrôleur des données sur le territoire de l'Union européenne.
Si, pour des raisons techniques et/ou opérationnelles, il est nécessaire de faire appel à des sujets situés en dehors de l'Union européenne, nous vous informons dès à présent que le traitement aura lieu dans le respect des droits et des garanties prévus par la législation en vigueur, donc le transfert des données personnelles à ces sujets aura lieu avec des garanties adéquates pour la protection des données personnelles, comme l'exige l'art. 44-49 du GDPR.
Dans tous les cas, vous pouvez demander plus de détails au Contrôleur des données si vos données personnelles sont traitées en dehors de l'Union européenne en demandant la preuve des garanties spécifiques adoptées.

**Droits de l'intéressé**    
Les articles 15 et ss. du Règlement vous donnent l'exercice de droits spécifiques et vous permettent d'obtenir :
- la confirmation que des données à caractère personnel vous concernant sont ou ne sont pas traitées et d'obtenir l'accès aux données et aux informations suivantes : finalité du traitement, catégories de données à caractère personnel, destinataires et/ou catégories de destinataires auxquels les données ont été et/ou seront communiquées, durée de conservation ;
- la rectification des données à caractère personnel inexactes vous concernant et/ou l'intégration des données à caractère personnel incomplètes, y compris en fournissant une déclaration complémentaire ;
- l'effacement des données personnelles, dans les cas prévus par le GDPR ;
- la limitation du traitement dans les cas prévus par la législation en vigueur ;
- la portabilité des données vous concernant, et notamment de demander les données personnelles vous concernant fournies au propriétaire et/ou de demander la transmission directe de vos données à un autre responsable du traitement ;
- l'opposition au traitement à tout moment, pour des raisons liées à une situation particulière, et/ou au traitement des données personnelles vous concernant dans le plein respect de la loi actuelle sur la protection de la vie privée, ainsi qu'à toute fin commerciale.
Vous pouvez exercer ces droits en vous adressant à l'adresse électronique suivante : info@smartwater.brussels, en joignant une copie de votre pièce d'identité.

`;
const gdprTextNL = `
### INFORMATIE BETREFFENDE DE BESCHERMING VAN PERSOONSGEGEVENS EX ART 13 EUROPESE VERORDENING 2016/679 ("GDPR")
Op grond van en met het oog op de bepalingen van art. 13 van de GDPR, informeren wij u dat uw persoonsgegevens zullen worden verwerkt met behulp van elektronische en handmatige hulpmiddelen in België en in het buitenland.
Deze informatie, opgesteld op basis van het principe van transparantie en van alle elementen vereist door de GDPR, is opgedeeld in afzonderlijke secties, die elk een specifiek onderwerp behandelen om het lezen sneller, gemakkelijker en begrijpelijker te maken (hierna de "Informatie").

**Gegevensbeheerder**    
De verantwoordelijke voor de verwerking (hierna "verantwoordelijke") is atelier cartographique met maatschappelijke zetel in de Van Elewyckstraat 47 te 1050 Brussel.

**Rechtsgrondslag van de verwerking**    
De rechtsgrondslag voor de verwerking van uw persoonsgegevens door de Gegevenscontroleur is:
- de toestemming die u heeft geuit door activering en eventuele registratie in de web-app genaamd "SmartWater - Collect".

**Doel van de verwerking**        
Het verzamelen en verwerken van uw persoonsgegevens wordt uitgevoerd voor de volgende doeleinden:    
- Verzamelen en beheren van meldingen van milieuwaarnemingen zoals de aanwezigheid van ongewenste verschijnselen, bijvoorbeeld: algenbloei, schuimvorming, olievlekken, nesten, stank, afvoerkanalen en faunale aspecten (aanwezigheid van overleden dieren of uitheemse soorten);
- het versturen van rapporten naar de bevoegde autoriteiten (bijvoorbeeld: Leefmilieu Brussel);
De toestemming voor de verwerking van uw gegevens is verplicht voor het bereiken van de bovengenoemde doeleinden en het niet, gedeeltelijk of onjuist verlenen van de toestemming zal het onmogelijk maken om toegang te krijgen tot de functies van de "SmartWater - Collect" app die zijn voorbehouden aan geregistreerde gebruikers.

**Intrekking van de toestemming**    
U hebt het recht om uw toestemming geheel en/of gedeeltelijk in te trekken door op elk moment een e-mail te sturen naar contact@atelier-cartographique.be zonder afbreuk te doen aan de rechtmatigheid van de verwerking op basis van de toestemming die voorafgaand aan de intrekking is gegeven.


**Gegevensverstrekking**    
Uw persoonsgegevens worden uitsluitend verwerkt door personen die gemachtigd zijn om deze te verwerken (artikel 29 van de GDPR) en door derden die uitdrukkelijk zijn aangewezen als gegevensverwerkers (artikel 28 van de GDPR), teneinde alle verwerkingsactiviteiten uit te voeren die nodig zijn om de in deze kennisgeving beschreven doeleinden na te streven. Persoonsgegevens kunnen worden bekendgemaakt aan overheidsinstanties of gerechtelijke autoriteiten, indien dit bij wet is vereist of om het plegen van een misdrijf te voorkomen of te bestrijden.

**Bewaartermijn van de gegevens**    
Uw verzamelde persoonsgegevens zullen door de Gegevensverwerker worden verwerkt gedurende de tijd die strikt noodzakelijk is om de hierboven vermelde doeleinden te verwezenlijken, met uitzondering van een verdere bewaartermijn die door wettelijke verplichtingen kan worden opgelegd.

**Territoriale reikwijdte van de verwerking**    
Uw persoonsgegevens worden door de verantwoordelijke voor de verwerking verwerkt op het grondgebied van de Europese Unie.
Indien het om technische en/of operationele redenen noodzakelijk is om gebruik te maken van subjecten die zich buiten de Europese Unie bevinden, informeren wij u vanaf heden dat de verwerking zal plaatsvinden met inachtneming van de rechten en waarborgen waarin de huidige wetgeving voorziet, derhalve zal de overdracht van persoonsgegevens aan deze subjecten plaatsvinden met adequate waarborgen voor de bescherming van persoonsgegevens zoals vereist door art. 44-49 van de GDPR.
In elk geval kunt u meer details opvragen bij de verantwoordelijke voor de verwerking indien uw persoonsgegevens buiten de Europese Unie worden verwerkt, door te vragen om bewijs van de specifieke garanties die zijn goedgekeurd.

**Rechten van de betrokkene**    
De artikelen 15 en ss. van de verordeningen geven u de uitoefening van specifieke rechten en stellen u in staat om
- bevestiging of er al dan niet persoonsgegevens over u worden verwerkt en toegang tot de gegevens en de volgende informatie: doel van de verwerking, categorieën van persoonsgegevens, ontvangers en/of categorieën van ontvangers aan wie de gegevens zijn en/of zullen worden meegedeeld, bewaartermijn;
- de correctie van u betreffende onjuiste persoonsgegevens en/of de integratie van onvolledige persoonsgegevens, onder meer door het verstrekken van een aanvullende verklaring
- de annulering van persoonsgegevens, in de gevallen waarin de GDPR voorziet
- de beperking van de verwerking in de gevallen voorzien in de huidige wetgeving;
- de overdraagbaarheid van de u betreffende gegevens, en met name om de u betreffende persoonsgegevens op te vragen die aan de eigenaar zijn verstrekt en/of om de rechtstreekse overdracht van uw gegevens aan een andere verantwoordelijke voor de verwerking te verzoeken
- verzet tegen de verwerking op elk moment, om redenen die verband houden met een bijzondere situatie, en/of tegen de verwerking van persoonsgegevens die u betreffen in volledige overeenstemming met de huidige Privacywetgeving, alsmede voor eventuele commerciële doeleinden.
U kunt deze rechten uitoefenen door contact op te nemen met het volgende e-mailadres: info@smartwater.brussels, onder bijvoeging van een kopie van uw identiteitsbewijs.
`;
const gdprTextEN = `
### INFORMATION REGARDING THE PROTECTION OF PERSONAL DATA EX ART 13 EUROPEAN REGULATION 2016/679 ("GDPR")
Pursuant to and for the purposes of the provisions of art. 13 of the GDPR, we inform you that your personal data will be processed using electronic and manual tools in Belgium and abroad.
This information, drawn up on the basis of the principle of transparency and of all the elements required by the GDPR, is divided into individual sections, each of which deals with a specific topic in order to make reading faster, easier and simple to understand (hereinafter the "Information").

**Data Controller**    
The Data Controller (hereinafter "Data Controller") is atelier cartographique with registered office in rue Van Elewyck 47,1050 Brussels.

**Legal basis of the processing**    
The legal basis for the processing of your personal data by the Data Controller is:
- the consent you have expressed through activation and possible registration in the web-app called "SmartWater - Collect".

**Purpose of the processing**        
The collection and processing of your personal data are carried out for the following purposes:    
- Collection and management of reports of environmental observations such as the presence of unwanted phenomena, for example: algal blooms, foams, oily stains, litters, odours, drains and faunal aspects (presence of deceased animals or alien species);
- Sending of reports to the competent authorities (eg: Environment Brussels (Bruxelles Environnement – Leefmilieu Brussel);
The consent for the processing of your data is mandatory for the achievement of the aforementioned purposes and failure, partial and incorrect conferment will make it impossible to access the "SmartWater - Collect" app features reserved for registered users .

**Withdrawal of the consent**    
You have the right to withdraw your consent totally and/or partially by sending an email to contact@atelier-cartographique.be at any time without prejudice to the lawfulness of the processing based on the consent given prior to the revocation.


**Data communication**    
Your personal data will be processed exclusively by persons authorized to process (Article 29 of the GDPR) and by third parties expressly designated as Data Processors (Article 28 of the GDPR), in order to carry out all the processing activities necessary to pursue the purposes detailed in this Notice. Personal Data may be disclosed to public bodies or the judicial authorities, where required by law or to prevent or suppress the commission of a crime.

**Data retention period**    
Your collected personal data will be processed by the Data Controller for the time strictly necessary to achieve the aforementioned purposes, except a further retention period that may be imposed by legal obligations.

**Territorial scope of the processing**    
Your personal data will be processed by the Data Controller within the territory of the European Union.
If for technical and/or operational reasons it is necessary to make use of subjects located outside the European Union, we inform you from now on that the processing will take place in compliance with the rights and guarantees provided for by current legislation, therefore the transfer of personal data to these subjects will take place with adequate guarantees for the protection of personal data as required by art. 44-49 of the GDPR.
In any case, you can request more details from the Data Controller if your personal data are processed outside the European Union by requesting evidence of the specific guarantees adopted.

**Rights of the interested party**    
The articles 15 and ss. of the Regulations give you the exercise of specific rights and allow you to obtain:
- confirmation as to whether or not personal data concerning you are being processed and to obtain access to the data and the following information: purpose of the processing, categories of personal data, recipients and/or categories of recipients to whom the data have been and/or will be communicated, retention period;
- the correction of inaccurate personal data concerning you and/or the integration of incomplete personal data, including by providing an additional declaration;
- the cancellation of personal data, in the cases provided for by the GDPR;
- the limitation of processing in the cases provided for by the current legislation;
- the portability of the data concerning you, and in particular to request the personal data concerning you provided to the owner and/or request the direct transmission of your data to another data controller;
- opposition to processing at any time, for reasons related to a particular situation, and/or to the processing of personal data concerning you in full compliance with the current Privacy Law, as well as for any commercial purposes.
You can exercise these rights by contacting the following e-mail address: info@smartwater.brussels, attaching a copy of your identity document.

`;
const helptextobservationAnswerFR = `
Les choix présentés dans ce formulaire sont **facultatifs**.

Les réponses sont **enregistrées au fur et à mesure**, il n'y a donc pas besoin de sauver vos réponses à la fin.
`;
const helptextobservationAnswerNL = `
De meerkeuzevragen in dit formulier zijn facultatief.

Antwoorden worden onmiddellijk geregistreerd. Je hoeft je antwoorden op het einde niet op te slaan.
`;
const helptextobservationAnswerEN = `
The choices presented in this form are **optional**.

Answers are **recorded as you go**, so there is no need to save your answers at the end.
`;

export const marked = {
    communityListPitch: {
        fr: communityListPitchFR,
        nl: communityListPitchNL,
        en: communityListPitchEN,
    },
    aboutApp: {
        fr: aboutAppFR,
        nl: aboutAppNL,
        en: aboutAppEN,
    },
    homeDescription: {
        fr: homeDescriptionFR,
        nl: homeDescriptionNL,
        en: homeDescriptionEN,
    },
    'helptext.home': {
        fr: helptexthomeFR,
        nl: helptexthomeNL,
        en: helptexthomeEN,
    },
    gdprText: {
        fr: gdprTextFR,
        nl: gdprTextNL,
        en: gdprTextEN,
    },
    'helptext.observationAnswer': {
        fr: helptextobservationAnswerFR,
        nl: helptextobservationAnswerNL,
        en: helptextobservationAnswerEN,
    },
};
