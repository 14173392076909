import { displayException } from 'sdi/app';
import { configure, defaultShape, IShape } from 'sdi/shape';
import { AppConfigIO, getMessage, source } from 'sdi/source';
import { app } from './app';
import './locale';
import { defaultAppShape, defaultMapState, defaultCollectShape } from './shape';

import './queries/collect';

export const main = (SDI: unknown) =>
    AppConfigIO.decode(SDI).fold(
        errors => {
            const textErrors = errors.map(e => getMessage(e.value, e.context));
            displayException(textErrors.join('\n'));
        },
        config => {
            const initialState: IShape = {
                'app/codename': 'smartwater',
                ...defaultShape(config),
                ...defaultAppShape(),
                ...defaultMapState(),
                ...defaultCollectShape(),
            };

            const stateActions = source<IShape>(['app/lang']);
            const store = stateActions(initialState);
            configure(store); // too much boilerplate?

            const start = app(config.args)(store);
            start();
        }
    );
