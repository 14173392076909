import * as io from 'io-ts';
import { Setter, Getter } from '../../shape';
import { Nullable, toDataURL, uniqIdGen } from '../../util';
import { uploadIO } from '../../source';
import { fromNullable } from 'fp-ts/lib/Option';
import { INPUT, DIV, IMG, SPAN } from '../elements';
import { FormEvent } from 'react';
import { makeLabelAndIcon } from '../button';
import tr from '../../locale';

type DataUrl = string;

export type ImageUploaderStep =
    | 'ShowInitial'
    | 'ShowSelected'
    | 'GeneratePreview'
    | 'Upload';

const UPLOAD_PATH = '/documents/images/';
const MEDIA_URL = '/documents/images/';

const getImageUrl = (id: string) => `${MEDIA_URL}${id}`;

// export const fileUploader = <T>(
//     ioType: io.Type<T>
// ) => (
//     url: string,
//     f: File,
//     ): Promise<T> => {
//         const options = defaultFetchOptions();
//         const data = new FormData();
//         data.append('file', f);
//         options.body = data;
//         const headers = options.headers as Headers;
//         if (headers) {
//             headers.delete('Content-Type');
//         }

//         return postIO(ioType, url, {}, options);
//     };

// fileUploader(io.interface({
//     id: io.string,
//     url: io.string,
// }));

const uploadFile = (file: File) =>
    uploadIO(
        io.interface({
            id: io.string,
            url: io.string,
        }),
        UPLOAD_PATH,
        'file',
        file
    );

export interface ImageUploaderState {
    step: ImageUploaderStep;
    imageId: Nullable<string>;
}

export const defaultImageUploaderState = (): ImageUploaderState => ({
    step: 'ShowInitial',
    imageId: null,
});

export const makeImageUploaderEvents = (set: Setter<ImageUploaderState>) => {
    const clearUploadState = () => set(defaultImageUploaderState);

    const setStep = (step: ImageUploaderStep) => set(s => ({ ...s, step }));

    const setImageId = (imageId: string) => set(s => ({ ...s, imageId }));

    const saveImage = (img: File) => {
        uploadFile(img)
            .then(data => {
                setStep('ShowInitial');
                setImageId(data.id);
            })
            .catch(() => {
                setStep('ShowSelected');
            });
    };

    return {
        clearUploadState,
        setStep,
        setImageId,
        saveImage,
    };
};

type ImageUploaderEvents = ReturnType<typeof makeImageUploaderEvents>;

export const makeImageUploaderQueries = (get: Getter<ImageUploaderState>) => {
    const getStep = () => get().step;

    const getImageId = () => fromNullable(get().imageId);

    return {
        getStep,
        getImageId,
    };
};

type ImageUploaderQueries = ReturnType<typeof makeImageUploaderQueries>;

const makeKey = uniqIdGen('image-upload-');

interface Selection {
    file: File;
    dataUrl: DataUrl;
}

export const makeImageUploader = (
    name: string,
    { getStep, getImageId }: ImageUploaderQueries,
    { setStep, saveImage }: ImageUploaderEvents
) => {
    const key = makeKey();

    /**
     * Internal state management
     */
    const { getSelection, select, clearSelection, saveSelection } = (() => {
        let selection: Nullable<Selection> = null;

        const getSelection = () => fromNullable(selection);

        const clearSelection = () => {
            selection = null;
            setStep('ShowInitial');
        };

        const select = (file: File) => {
            toDataURL(file)
                .then((dataUrl: DataUrl) => {
                    selection = { file, dataUrl };
                    setStep('ShowSelected');
                })
                .catch(() => setStep('ShowInitial'));

            setStep('GeneratePreview');
        };

        const saveSelection = () =>
            getSelection().map(({ file }) => saveImage(file));

        return {
            getSelection,
            select,
            clearSelection,
            saveSelection,
        };
    })();

    const renderInput = () =>
        INPUT({
            key,
            name,
            type: 'file',
            onChange: (e: FormEvent<HTMLInputElement>) => {
                if (
                    e &&
                    e.currentTarget.files &&
                    e.currentTarget.files.length > 0
                ) {
                    select(e.currentTarget.files[0]);
                } else {
                    clearSelection();
                }
            },
        });

    const renderSave = makeLabelAndIcon('save', 2, 'save', () =>
        tr.core('validate')
    );

    const renderShowInitial = () =>
        DIV(
            'upload-image initial',
            getImageId().map(id =>
                IMG({
                    className: 'preview initial',
                    src: getImageUrl(id),
                })
            ),
            renderInput()
        );

    const renderShowSelected = () =>
        DIV(
            'upload-image selected',
            getSelection().map(({ dataUrl }) =>
                IMG({
                    className: 'preview selected',
                    src: dataUrl,
                })
            ),
            renderInput(),
            renderSave(saveSelection)
        );

    const renderGeneratePreview = () =>
        DIV('upload-image generate', SPAN('loader-spinner'), tr.core('load'));

    const renderUpload = () =>
        DIV('upload-image upload', SPAN('loader-spinner'), tr.core('load'));

    const render = () => {
        switch (getStep()) {
            case 'ShowInitial':
                return renderShowInitial();
            case 'ShowSelected':
                return renderShowSelected();
            case 'GeneratePreview':
                return renderGeneratePreview();
            case 'Upload':
                return renderUpload();
        }
    };

    return render;
};
