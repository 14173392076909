import { Credentials } from 'sdi/source';
import tr from 'sdi/locale';
import {
    A,
    BUTTON,
    DIV,
    H1,
    H2,
    INPUT,
    LABEL,
    SPAN,
} from 'sdi/components/elements';
import { isENTER } from 'sdi/components/keycodes';
import { makeLabelAndIcon } from 'sdi/components/button';
import { getLang, getApiUrl } from 'sdi/app';

import {
    getUsername,
    getPassword,
    getLoginError,
    LoginError,
} from '../../queries/login';
import { setUsername, setPassword, tryLogin } from '../../events/login';
import { navigateBack, navigateRegister } from 'smartwater/src/events/route';
import { buttonBack, renderButtonSignup } from '../buttons';
import { nameToString } from 'sdi/components/button/names';

export interface LoginForm {
    credentials: Credentials;
}

export const defaultLoginForm = (): LoginForm => ({
    credentials: {
        username: '',
        password: '',
    },
});

const tryOnReturn = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (isENTER(e)) {
        tryLogin();
    }
};

const breadCrumb = () => DIV('breadcrumb', buttonBack(navigateBack));
const renderTitle = () => H1('title', tr.smartwater('title/no-login'));
const renderHelpText = () => DIV('help-text', tr.smartwater('help/no-login'));

const loginButton = makeLabelAndIcon('login', 1, 'chevron-right', () =>
    tr.smartwater('login')
);

const renderUsername = () =>
    DIV(
        { className: 'username' },
        LABEL(
            { className: 'login-label' },
            tr.smartwater('field/e-mail'),
            INPUT({
                key: 'login-username',
                type: 'text',
                defaultValue: getUsername(),
                onChange: e => setUsername(e.currentTarget.value),
                onKeyUp: tryOnReturn,
                autoCapitalize: 'none',
                autoComplete: 'off',
                autoCorrect: 'off',
            })
        )
    );

const renderPassword = () =>
    DIV(
        { className: 'password' },
        LABEL(
            { className: 'login-label' },
            tr.smartwater('field/password'),
            INPUT({
                key: 'login-password',
                type: 'password',
                defaultValue: getPassword(),
                onChange: e => setPassword(e.currentTarget.value),
                onKeyUp: tryOnReturn,
            })
        )
    );

const errorMessage = (err: LoginError) => {
    switch (err) {
        case 'auth':
            return tr.smartwater('wrongCredentials');
        case 'server':
            return tr.smartwater('serverError');
        case 'unknown':
            return tr.smartwater('unknownError');
    }
};

const wrapError = (...children: React.ReactNode[]) =>
    getLoginError().foldL(
        () =>
            DIV(
                { className: 'login-widget' },
                H2('', tr.smartwater('loginTitle')),
                ...children
            ),
        err =>
            DIV(
                { className: 'login-widget error-wrapper' },
                DIV({ className: 'error-message' }, errorMessage(err)),
                ...children
            )
    );

const resetPasswordURL = () =>
    getApiUrl(`geodata/smartwater/password-reset-${getLang()}.html`);

const renderSignupWrapper = () =>
    DIV('signup__wrapper', renderButtonSignup(navigateRegister));

const renderLinks = () =>
    DIV(
        'links',

        BUTTON(
            'btn btn-3 navigate label-and-icon',
            A(
                {
                    href: resetPasswordURL(),
                },
                SPAN('icon', nameToString('key')),
                SPAN('btn-label', tr.smartwater('forgotPassword'))
            )
        )
    );

const render = () =>
    DIV(
        'no-login--inner',
        breadCrumb(),
        renderTitle(),
        renderHelpText(),
        renderSignupWrapper(),
        wrapError(renderUsername(), renderPassword(), loginButton(tryLogin)),
        renderLinks()
    );

export default render;
