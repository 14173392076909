import { queryK, query } from 'sdi/shape';
import { fromNullable, fromPredicate, none, some } from 'fp-ts/lib/Option';
import {
    AnnotationTargetKind,
    Answer,
    Choice,
    Community,
    Observation,
    Question,
} from '../remote';
import {
    remoteToOption,
    ILayerInfo,
    Inspire,
    IMapInfo,
    Feature,
    Point,
    MessageRecord,
} from 'sdi/source';
import { nameToCode } from 'sdi/components/button/names';
import { flatten, getCollectionItem } from 'sdi/util';
import { makeImageUploaderQueries } from 'sdi/components/upload';
import { fromOption } from 'fp-ts/lib/Either';
import { getUserId } from 'sdi/app';
import { scopeOption } from 'sdi/lib';
import { catOptions, last } from 'fp-ts/lib/Array';
import {
    withInteractionOpt,
    InteractionTrack,
    TrackerCoordinate,
    tryCoord2D,
    Coord2D,
} from 'sdi/map';
import { getMapInteraction } from './map';
import { fromRecord } from 'sdi/locale';
import clientLayer from 'sdi/map/client-layer';

import { Extent } from 'ol/extent';

export const BASE_COMMUNITY_ID = 1;

const collects = queryK('data/collect/collect');
const categories = queryK('data/collect/category');
const questions = queryK('data/collect/question');
const choices = queryK('data/collect/choice');
const observations = queryK('data/collect/observation');
const answers = queryK('data/collect/answer-list');

export const getRemoteCollects = collects;
export const getRemoteCategories = categories;
export const getRemoteQuestions = questions;
export const getRemoteObservations = observations;
export const getRemoteAnswers = answers;
export const getRemoteChoices = choices;

export const getQuestions = (collectId: number) =>
    remoteToOption(questions())
        .getOrElse([])
        .filter(q => q.collect === collectId)
        .sort((a, b) => (a.order < b.order ? -1 : 1));

export const getObserverQuestions = (collectId: number) =>
    remoteToOption(questions())
        .getOrElse([])
        .filter(q => q.collect === collectId && q.kind === 'observer')
        .sort((a, b) => (a.order < b.order ? -1 : 1));

export const getObservedQuestions = (collectId: number) =>
    remoteToOption(questions())
        .getOrElse([])
        .filter(q => q.collect === collectId && q.kind === 'observed')
        .sort((a, b) => (a.order < b.order ? -1 : 1));

export const getChoices = (questionId: number) =>
    remoteToOption(choices())
        .getOrElse([])
        .filter(c => c.question === questionId)
        .sort((a, b) => (a.order < b.order ? -1 : 1));

export const getObservations = (collectId: number) =>
    remoteToOption(observations())
        .getOrElse([])
        .filter(obs => obs.collect === collectId);

export const getNbOfObservations = (collectId: number) =>
    getObservations(collectId).length;

export const getAnswers = (observationId: number) =>
    remoteToOption(answers())
        .getOrElse([])
        .filter(a => a.observation === observationId);

//FIXME (make descriptions optional?)
export const notEmptyDescription = (description: MessageRecord) =>
    fromPredicate((text: string) => text != '' && text != ' ' && text != '-')(
        fromRecord(description)
    );

export const findCollect = (id: number) =>
    remoteToOption(collects()).chain(items =>
        fromNullable(items.find(c => c.id === id))
    );

export const findCategory = (id: number) =>
    remoteToOption(categories()).chain(items =>
        fromNullable(items.find(c => c.id === id))
    );

export const getCollectsWithCategory = (id: number) =>
    scopeOption()
        .let('community', getCurrentCommunity())
        .let('category', findCategory(id))
        .map(({ community, category }) =>
            catOptions(community.collects.map(findCollect)).filter(
                ({ categories }) => categories.indexOf(category.id) >= 0
            )
        );

export const findQuestion = (id: number, collectId: number) =>
    remoteToOption(questions()).chain(items =>
        fromNullable(items.find(c => c.id === id && c.collect === collectId))
    );

export const findChoice = (id: number) =>
    remoteToOption(choices()).chain(items =>
        fromNullable(items.find(c => c.id === id))
    );

export const findObservation = (id: number) =>
    remoteToOption(observations()).chain(items =>
        fromNullable(items.find(c => c.id === id))
    );

export const findAnswer = (id: number) =>
    remoteToOption(answers()).chain(items =>
        fromNullable(items.find(c => c.id === id))
    );

export const findAnswerForChoice = (choiceId: number, obsId: number) =>
    remoteToOption(answers()).chain(items =>
        fromNullable(
            items
                .filter(a => a.choice === choiceId)
                .find(a => a.observation === obsId)
        )
    );

export const getQuestionForAnswer = (a: Answer) =>
    findChoice(a.choice).chain(c =>
        findQuestion(c.question, getSelectedCollectId().getOrElse(-1))
    );

export const getQuestionForAnswerInCollect = (a: Answer, collectId: number) =>
    findChoice(a.choice).chain(c => findQuestion(c.question, collectId));

export const getAnswerAndQuestionForChoice = (c: Choice) =>
    getFormAnswerObservation().chain(o => {
        return scopeOption()
            .let('a', findAnswerForChoice(c.id, o))
            .let(
                'q',
                findQuestion(c.question, getSelectedCollectId().getOrElse(-1))
            );
    });

export const isSelectedChoice = (c: Choice) =>
    getFormAnswerObservation()
        .map(obsId => getAnswers(obsId).findIndex(a => a.choice === c.id) >= 0)
        .getOrElse(false);

export const pickChoice = (a: Answer) => a.choice;
export const pickQuestion = (c: Choice) => c.question;
export const pickCollect = (q: Question) => q.collect;

export const getSelectedCollectId = () =>
    fromNullable(query('collect/selected/collect'));
export const getSelectedCollect = () =>
    getSelectedCollectId().chain(findCollect);

export const getSelectedObservation = () =>
    fromNullable(query('collect/selected/observation')).chain(findObservation);

export const getSelectedObservationUser = () =>
    getSelectedObservation().chain(o => fromNullable(o.user));

export const getFormObservationInteraction = queryK('collect/map/interaction');

export const getFormObservation = () =>
    fromNullable(query('collect/form/observation/observed'));

export const getFormAnswerObservation = () =>
    fromNullable(query('collect/form/answer/observation'));

// form map
export const MAP_OBSERVATION_FORM = 'map-observation-form';
export const LAYER_OBSERVATION_FORM = 'layer-observation-form';
export const LAYER_TRACKER_OBSERVATION_FORM = 'layer-observation-form';

export const observationCommunityLayer = () =>
    getCurrentCommunity().map(c =>
        clientLayer(
            `Community ${c.name}`,
            'MultiPolygon',
            {
                kind: 'polygon-simple',
                fillColor: 'transparent',
                strokeColor: 'white',
                strokeWidth: 2,
            },
            [
                {
                    type: 'Feature',
                    geometry: c.geom,
                    properties: {},
                    id: 1,
                },
            ]
        )
    );

const observationMetadata: Inspire = {
    id: LAYER_OBSERVATION_FORM,
    geometryType: 'Point',
    resourceTitle: { en: LAYER_OBSERVATION_FORM },
    resourceAbstract: { en: LAYER_OBSERVATION_FORM },
    uniqueResourceIdentifier: LAYER_OBSERVATION_FORM,
    topicCategory: [],
    keywords: [],
    geographicBoundingBox: { west: 0.0, north: 0.0, east: 0.0, south: 0.0 },
    temporalReference: { creation: Date(), revision: Date() },
    responsibleOrganisation: [1],
    metadataPointOfContact: [1],
    metadataDate: Date(),
    published: false,
    dataStreamUrl: null,
    maintenanceFrequency: 'continual',
};

const observationLayerInfo: ILayerInfo = {
    id: LAYER_OBSERVATION_FORM,
    legend: null,
    group: null,
    metadataId: LAYER_OBSERVATION_FORM,
    visible: true,
    featureViewOptions: { type: 'default' },
    style: {
        kind: 'point-simple',
        marker: {
            codePoint: nameToCode('circle'),
            size: 12,
            color: '#3668b3',
        },
    },
    layerInfoExtra: null,
    visibleLegend: true,
    opacitySelector: false,
};

export const observationInfoOption = () =>
    some({
        name: { en: LAYER_OBSERVATION_FORM },
        info: observationLayerInfo,
        metadata: observationMetadata,
    });

export const getObservationMapInfo = (): IMapInfo => ({
    baseLayer: 'urbis.irisnet.be/urbis_gray',
    id: MAP_OBSERVATION_FORM,
    id_origin: MAP_OBSERVATION_FORM,
    url: `/dev/null/write/`,
    lastModified: Date.now(),
    status: 'published',
    title: { fr: '', nl: '', en: '' },
    description: { fr: '', nl: '', en: '' },
    categories: [],
    attachments: [],
    layers: observationCommunityLayer()
        .map(cl => [cl.layer, observationLayerInfo])
        .getOrElse([observationLayerInfo]),
});

export const getObservationFeatures = () =>
    getFormObservation()
        .map<Feature[]>(geometry => [
            {
                type: 'Feature',
                id: Date.now(),
                properties: {},
                geometry,
            },
        ])
        .getOrElse([]);

// end of form map

export const getAnnotationKind = () => query('collect/annotation-form/kind');

export const getAnnotationTarget = () =>
    fromNullable(query('collect/annotation-form/target'));

export const getAnnotationText = () =>
    fromNullable(query('collect/annotation-form/text'));

export const getAnnotationImage = () =>
    fromNullable(query('collect/annotation-form/image'));

export const getAllAnnotations = () => query('data/collect/annotations');

export const getAnnotations = (collectId: number) =>
    getCollectionItem(collectId.toString(), getAllAnnotations());

export const getNLastAnnotations = (community: Community, length: number) =>
    community.collects
        .map(col => getAnnotations(col).getOrElse([]))
        .flat()
        .sort((a, b) => b.timestamp - a.timestamp)
        .slice(0, length);

export const getNLastObservations = (community: Community, length: number) =>
    community.collects
        .map(c => getObservations(c))
        .flat()
        .filter(obs => obs.communities.indexOf(community.id) >= 0)
        .sort((a, b) => b.timestamp - a.timestamp)
        .slice(0, length);

export const getAnnotationsFromObs = (o: Observation) =>
    getAnnotations(o.collect).map(annotations =>
        annotations.filter(
            ({ target }) => target.kind === 'observation' && target.id === o.id
        )
    );

export const getAnnotationsFromQuestion = (q: Question) =>
    getAnnotations(q.collect).map(annotations =>
        annotations.filter(
            ({ target }) => target.kind === 'question' && target.id === q.id
        )
    );

export const getAnnotationsFromAnswer = (a: Answer, q: Question) =>
    getAnnotations(q.collect).map(annotations =>
        annotations.filter(
            ({ target }) => target.kind === 'answer' && target.id === a.id
        )
    );

export const getTargetAnnotations = (
    collectId: number,
    targetKind: AnnotationTargetKind,
    targetId: number
) =>
    getAnnotations(collectId).map(annotations =>
        annotations.filter(
            ({ target }) => target.kind === targetKind && target.id === targetId
        )
    );

export const getObservationAnnotations = (o: Observation) =>
    getTargetAnnotations(o.collect, 'observation', o.id);

export const getQuestionAnnotations = (q: Question) =>
    getTargetAnnotations(q.collect, 'question', q.id);

export const getAnswerAnnotations = (a: Answer, q: Question) =>
    getTargetAnnotations(q.collect, 'answer', a.id);

export const imageUploaderQueries = makeImageUploaderQueries(
    queryK('collect/annotation-form/image')
);

export const getCommunityExtent = (communityId: number) =>
    findCommunity(communityId).map(community => {
        const exteriorRing = flatten(
            community.geom.coordinates.map(poly => poly[0])
        );
        const extent = exteriorRing.reduce<Extent>(
            ([minx, miny, maxx, maxy], coord) => [
                Math.min(minx, coord[0]),
                Math.min(miny, coord[1]),
                Math.max(maxx, coord[0]),
                Math.max(maxy, coord[1]),
            ],
            [
                Number.MAX_VALUE,
                Number.MAX_VALUE,
                Number.MIN_VALUE,
                Number.MIN_VALUE,
            ]
        );
        return extent;
    });

export const getRemoteCommunities = queryK('data/collect/community');

export const getCommunityListOpt = () => remoteToOption(getRemoteCommunities());

export const findCommunity = (id: number) =>
    id === BASE_COMMUNITY_ID
        ? getBaseCommunity()
        : getCommunityListOpt().chain(cs =>
              fromNullable(cs.find(c => c.id === id))
          );

export const getCurrentCommunity = () =>
    fromNullable(query('collect/selected/community')).chain(findCommunity);

export const getBaseCommunity = () =>
    fromNullable(query('data/collect/base-community'));

export const isBaseCommunity = (id: number) => id === BASE_COMMUNITY_ID;

export const getRemoteMe = queryK('data/collect/me');

export const getContributions = () =>
    fromOption('no-auth')(getUserId()).map(getRemoteMe);

const withTrack = withInteractionOpt<InteractionTrack>(
    'track',
    ({ state }) => state.track
);

const point = (coordinates: Coord2D): Point => ({
    type: 'Point',
    coordinates,
});

const observationTrackerMetadata: Inspire = {
    id: LAYER_TRACKER_OBSERVATION_FORM,
    geometryType: 'Point',
    resourceTitle: { en: LAYER_TRACKER_OBSERVATION_FORM },
    resourceAbstract: { en: LAYER_TRACKER_OBSERVATION_FORM },
    uniqueResourceIdentifier: LAYER_TRACKER_OBSERVATION_FORM,
    topicCategory: [],
    keywords: [],
    geographicBoundingBox: { west: 0.0, north: 0.0, east: 0.0, south: 0.0 },
    temporalReference: { creation: Date(), revision: Date() },
    responsibleOrganisation: [1],
    metadataPointOfContact: [1],
    metadataDate: Date(),
    published: false,
    dataStreamUrl: null,
    maintenanceFrequency: 'asNeeded',
};

const observationTrackerLayerInfo: ILayerInfo = {
    id: LAYER_TRACKER_OBSERVATION_FORM,
    legend: null,
    group: null,
    metadataId: LAYER_TRACKER_OBSERVATION_FORM,
    visible: true,
    featureViewOptions: { type: 'default' },
    style: {
        kind: 'point-simple',
        marker: {
            codePoint: nameToCode('circle'),
            size: 30,
            color: '#3399CC',
        },
    },
    layerInfoExtra: null,
    visibleLegend: true,
    opacitySelector: false,
};

export const observationTrackerInfoOption = () =>
    some({
        name: { en: 'trackerinfo' },
        info: observationTrackerLayerInfo,
        metadata: observationTrackerMetadata,
    });

export const getTrackerObservationFeatures = () =>
    withTrack(getMapInteraction())
        .chain<TrackerCoordinate>(last)
        .pick('coord')
        .chain(tryCoord2D)
        .map<Feature[]>(coord => [
            {
                type: 'Feature',
                id: Date.now(),
                properties: {},
                geometry: point(coord),
            },
        ])
        .getOrElse([]);

export const getQuestionState = (qid: number) =>
    fromNullable(query('collect/form/question-state')).chain(([id, state]) =>
        id === qid ? some(state) : none
    );

export const getCommunityImg = (communityId: number) =>
    getCollectionItem(`${communityId}`, query('data/collect/community/img'));

export const getSoundUploadStep = queryK('collect/annotation-form/sound');

export const getCommunityViewMode = queryK('collect/community/view');
