import { DIV, H2, NodeOrOptional, H1, BUTTON } from 'sdi/components/elements';
import { Community } from '../remote';
import { getBaseCommunity, getRemoteCommunities } from '../queries/collect';
import { foldRemote } from 'sdi/source';
import { navigateCommunity } from '../events/route';
import tr, { fromRecord } from 'sdi/locale';
import { renderButtonParticipate } from './buttons';
import { markdown } from 'sdi/ports/marked';
import { nameToString } from 'sdi/components/button/names';
import { minimap, renderCommunityImg, renderMinimap } from './minimap';

const renderHeader = () =>
    DIV(
        '',
        H1('title', tr.smartwater('homeTitle'))
        // H2('sub-title', tr.smartwater('homeSubTitle'))
        // markdown(tr.smartwater('homeDescription'))
    );

// const renderRegion = () =>
//     getBaseCommunity().map(({ id }: Community) =>
//         DIV(
//             'community region',
//             // H2('name', fromRecord(name)),
//             SPAN({ onClick: () => navigateCommunity(id) }, regionMiniMap()),
//             // DIV('description', fromRecord(shortDescription)),
//             renderButtonParticipate(() => navigateCommunity(id))
//         )
//     );

const renderRegionButton = () =>
    getBaseCommunity().map(({ id }: Community) =>
        BUTTON(
            {
                className: 'btn btn-1 switch-btn region-btn',
                onClick: () => navigateCommunity(id),
            },
            DIV('icon', nameToString('chevron-down')),
            DIV('label', tr.smartwater('switchLabelRegion')),
            DIV('illustration  illu-region')
        )
    );

const renderCommunityButton = () =>
    BUTTON(
        {
            className: 'btn btn-1 switch-btn community-btn',
            onClick: () =>
                document
                    .getElementById('communityListTitle')
                    ?.scrollIntoView({ behavior: 'smooth' }),
        },
        DIV('icon', nameToString('chevron-down')),
        DIV('label', tr.smartwater('switchLabelCommunity')),
        DIV('illustration illu-community')
    );

const renderSwitch = () =>
    DIV(
        'home-switch',
        H2('', tr.smartwater('reportYourObservation')),
        DIV('switch__wrapper', renderCommunityButton(), renderRegionButton()),
        DIV('home-switch__illu')
    );

const renderCommunity = ({ id, name, shortDescription, geom }: Community) =>
    DIV(
        {
            className: 'community',
            id: `community-${id}`,
            onClick: () => navigateCommunity(id),
        },
        H2('name', fromRecord(name)),
        DIV(
            'community-main',
            DIV(
                {
                    className: 'picto-maps',
                    onClick: () => navigateCommunity(id),
                },
                renderCommunityImg(id),
                renderMinimap(minimap(geom))
            ),
            DIV('description', fromRecord(shortDescription))
        ),
        // renderMinimap(minimap(geom)),
        renderButtonParticipate(() => navigateCommunity(id))
    );

const renderCommunityList = foldRemote<Community[], string, NodeOrOptional>(
    () => DIV({}, 'Application did not start yet'),
    () => DIV({}, 'Communities are loading'),
    error =>
        DIV(
            { className: 'error' },
            `Could not load Campaigns because of: ${error}`
        ),
    cs =>
        DIV(
            'community__list',
            H1(
                {
                    className: 'title',
                    id: 'communityListTitle',
                },
                tr.smartwater('communityListTitle')
            ),
            markdown(tr.smartwater('communityListPitch')),
            ...cs.map(renderCommunity)
        )
);

export const render = () =>
    DIV(
        { className: 'home-wrapper' },
        renderHeader(),
        DIV(
            { className: 'home-content' },
            renderSwitch(),
            renderCommunityList(getRemoteCommunities())
        )
    );

export default render;
