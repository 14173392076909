import * as debug from 'debug';
import { index } from 'fp-ts/lib/Array';
import { TypeOf, union, literal } from 'io-ts';
import { Router, Path } from 'sdi/router';
import { tryNumber, noop } from 'sdi/util';
import { clearToggleAnnotation, initRegisterForm, setLayout } from './app';
import { foldRemote, onRemoteNone, remoteToOption } from 'sdi/source';
import {
    loadCollectList,
    selectCollect,
    loadQuestionList,
    loadObservationList,
    selectObservation,
    loadAnswerList,
    loadChoiceList,
    setFormObservationCollect,
    resetFormObservationObserved,
    addObservationLayer,
    setFormAnswerObservation,
    setAnnotationFormTarget,
    setAnnotationFormKind,
    resetAnnotationText,
    resetAnnotationImage,
    loadCommunityList,
    selectCommunity,
    loadCategoryList,
    loadAnnotations,
    loadContributions,
} from './collect';
import {
    getRemoteCollects,
    getRemoteQuestions,
    getRemoteObservations,
    getRemoteAnswers,
    getRemoteChoices,
    getSelectedCollect,
    findObservation,
    getRemoteCommunities,
    getCurrentCommunity,
    getRemoteCategories,
    findCommunity,
    getAnnotations,
    getContributions,
    getRemoteMe,
} from '../queries/collect';
import { fromEither } from 'fp-ts/lib/Option';
import { scopeOption } from 'sdi/lib';
import {
    AnnotationTargetKindIO,
    AnnotationKindIO,
    AnnotationKind,
    AnnotationTargetKind,
} from '../remote';
import { loadCollectMap, zoomToExtent } from './map';
import { getRoute, getUserId } from 'sdi/app';
import { resetLogin, setFrom } from './login';

const logger = debug('sdi:route');

// tslint:disable-next-line: variable-name
const RouteIO = union([
    literal('home'),
    literal('community'),
    literal('collect'),
    literal('user'),
    literal('login'),
    literal('register'),
    literal('observation'),
    literal('observation-form'),
    literal('observation-form-print'),
    literal('answer-form'),
    literal('annotation-form'),
    literal('about'),
]);
export type Route = TypeOf<typeof RouteIO>;

const { home, route, navigate, back } = Router<Route>('smartwater');

export const navigateBack = back;

export type Layout = Route;

// parsers

const idParser = (p: Path) => index(0, p).chain(n => tryNumber(n));

const observationParser = (p: Path) =>
    scopeOption()
        .let(
            'communityId',
            index(0, p).chain(n => tryNumber(n))
        )
        .let(
            'collectId',
            index(1, p).chain(n => tryNumber(n))
        )
        .let(
            'obsId',
            index(2, p).chain(n => tryNumber(n))
        );

const collectParser = (p: Path) =>
    scopeOption()
        .let(
            'communityId',
            index(0, p).chain(n => tryNumber(n))
        )
        .let(
            'collectId',
            index(1, p).chain(n => tryNumber(n))
        );

const answerParser = (p: Path) =>
    scopeOption()
        .let(
            'communityId',
            index(0, p).chain(n => tryNumber(n))
        )
        .let(
            'collectId',
            index(1, p).chain(n => tryNumber(n))
        )
        .let(
            'observationId',
            index(2, p).chain(n => tryNumber(n))
        );

const annotationParser = (p: Path) =>
    scopeOption()
        .let(
            'collectId',
            index(0, p).chain(n => tryNumber(n))
        )
        .let(
            'kind',
            index(1, p).chain(t => fromEither(AnnotationKindIO.decode(t)))
        )
        .let(
            'targetKind',
            index(2, p).chain(t => fromEither(AnnotationTargetKindIO.decode(t)))
        )
        .let(
            'targetId',
            index(3, p).chain(n => tryNumber(n))
        );

// Declare route handlers

home('home', () => {
    foldRemote(loadCollectList, noop, noop, noop)(getRemoteCollects());

    setLayout('home');
});

const onCollectsNone = onRemoteNone(getRemoteCollects);
const onCategoriesNone = onRemoteNone(getRemoteCategories);
const onQuestionsNone = onRemoteNone(getRemoteQuestions);
const onChoicesNone = onRemoteNone(getRemoteChoices);
const onObservationsNone = onRemoteNone(getRemoteObservations);
const onAnswersNone = onRemoteNone(getRemoteAnswers);
const onCommunitiesNone = onRemoteNone(getRemoteCommunities);
const onContributionsNone = onRemoteNone(getRemoteMe);

const withData = (then: () => void) => {
    onCollectsNone(() => loadCollectList().then(then));
    onCategoriesNone(() => loadCategoryList().then(then));
    onQuestionsNone(() => loadQuestionList().then(then));
    onChoicesNone(() => loadChoiceList().then(then));
    onAnswersNone(() => loadAnswerList().then(then));
    onObservationsNone(() => loadObservationList().then(then));
    onCommunitiesNone(() => loadCommunityList().then(then));
    onContributionsNone(() =>
        getUserId()
            .map(loadContributions)
            .map(prm => prm.then(then))
    );
};

const userGate =
    <T>(route: Route, handler: (parsed: T) => void) =>
    (parsed: T, raw: Path) =>
        getUserId().foldL(
            () => navigate('login', [route as string].concat(raw)),
            () => handler(parsed)
        );

route('login', from => {
    setFrom(from);
    resetLogin();
    setLayout('login');
});

route(
    'community',
    optId => {
        clearToggleAnnotation();
        optId.map(id => {
            const select = () => {
                selectCommunity(id);
                setLayout('community');
                findCommunity(id).map(com =>
                    com.collects.map(col =>
                        getAnnotations(col).fold(loadAnnotations(col), noop)
                    )
                );
            };
            withData(select);
            select();
        });
    },
    idParser
);

route(
    'collect',
    scope => {
        clearToggleAnnotation();
        scope.map(({ communityId, collectId }) => {
            const select = () => {
                setLayout('collect');
                selectCollect(collectId);
                selectCommunity(communityId);
                loadCollectMap(collectId);
                zoomToExtent(communityId, 'map');
            };
            withData(select);
            select();
        });
    },
    collectParser
);

// route(
//     'map',
//     optId => {
//         optId.map(id => {
//             const select = () => {
//                 setLayout('map');
//                 selectCollect(id);
//                 loadCollectMap(id);
//             };

//             onCollectsNone(() => loadCollectList().then(select));
//             onObservationsNone(() => loadObservationList().then(select));

//             select();
//         });
//     },
//     collectParser
// );

route(
    'observation',
    scope => {
        clearToggleAnnotation();
        scope.map(({ communityId, collectId, obsId }) => {
            const select = () => {
                setLayout('observation');
                selectObservation(obsId);
                findObservation(obsId).map(obs => {
                    selectCollect(obs.collect);
                    setFormAnswerObservation(obs.id);
                });
                selectCommunity(communityId);
                selectCollect(collectId);
            };

            withData(select);
            select();
        });
    },
    observationParser
);

route(
    'observation-form',
    userGate('observation-form', scope => {
        clearToggleAnnotation();
        scope.map(({ communityId, collectId }) => {
            const select = () => {
                selectCollect(collectId);
                // loadCollectMap(collectId);
                zoomToExtent(communityId, 'observation');
                setFormObservationCollect(collectId);
                selectCommunity(communityId);
                resetFormObservationObserved();
                addObservationLayer();
                setLayout('observation-form');
            };

            withData(select);
            select();
        });
    }),
    collectParser
);

route(
    'observation-form-print',
    userGate('observation-form-print', optId => {
        optId.map(id => {
            const select = () => {
                selectCollect(id);
                setFormObservationCollect(id);
                resetFormObservationObserved();
                addObservationLayer();
                setLayout('observation-form-print');
            };

            withData(select);
            select();
        });
    }),
    idParser
);

route(
    'answer-form',
    userGate('answer-form', scope => {
        // clearToggleAnnotation();
        scope.map(({ communityId, collectId, observationId }) => {
            const select = () => {
                selectCommunity(communityId);
                selectCollect(collectId);
                selectObservation(observationId);
                setFormAnswerObservation(observationId);
                setLayout('answer-form');
            };

            withData(select);
            select();
        });
    }),
    answerParser
);

route(
    'annotation-form',
    userGate('annotation-form', scope => {
        clearToggleAnnotation();
        scope.map(({ collectId, kind, targetKind, targetId }) => {
            const select = () => {
                selectCollect(collectId);
                resetAnnotationText();
                resetAnnotationImage();
                setAnnotationFormKind(kind);
                setAnnotationFormTarget(targetKind, targetId);
                setLayout('annotation-form');
            };

            withData(select);
            select();
        });
    }),
    annotationParser
);

route('user', () => {
    withData(() => {
        getContributions()
            .map(remoteToOption)
            .map(o =>
                o.map(({ observations }) =>
                    observations
                        .reduce(
                            (acc, o) => acc.add(o.collect),
                            new Set<number>()
                        )
                        .forEach(col =>
                            getAnnotations(col)
                                .map(() => 1)
                                .getOrElseL(() => {
                                    loadAnnotations(col);
                                    return 1;
                                })
                        )
                )
            );
    });
    setLayout('user');
});

route(
    'register',
    comIdOpt => {
        comIdOpt.map(id => {
            selectCommunity(id);
            initRegisterForm(id);
            setLayout('register');
        });
    },
    idParser
);

// route(
//     'no-login',
//     comIdOpt => {
//         comIdOpt.map(id => {
//             selectCommunity(id);
//             setLayout('no-login');
//         });
//     },
//     idParser
// );

route('about', () => {
    setLayout('about');
});

// end of route handlers

export const loadRoute = (initial: string[]) =>
    index(0, initial)
        .chain(prefix =>
            fromEither(
                RouteIO.decode(prefix)
                    .map(c => navigate(c, initial.slice(1)))
                    .swap()
            )
        )
        .map(() => navigateHome());

export const navigateHome = () => navigate('home', []);

export const navigateProfile = () => navigate('user', []);

export const navigateCommunity = (id: number) => navigate('community', [id]);

export const navigateCollect = (collecId: number) =>
    getCurrentCommunity().map(com => navigate('collect', [com.id, collecId]));

// export const navigateMap = (collecId: number) => navigate('map', [collecId]);

export const navigateObservation = (obsId: number) =>
    scopeOption()
        .let(
            'comId',
            getCurrentCommunity().map(c => c.id)
        )
        .let(
            'colId',
            getSelectedCollect().map(c => c.id)
        )
        .map(({ comId, colId }) =>
            navigate('observation', [comId, colId, obsId])
        );

export const navigateObservationForm = (collectId: number) =>
    getCurrentCommunity().map(com =>
        getUserId().foldL(
            () =>
                navigateLoginTo([
                    'observation-form',
                    com.id.toString(),
                    collectId.toString(),
                ]),
            () => navigate('observation-form', [com.id, collectId])
        )
    );

export const navigateObservationFormPrint = (collectId: number) =>
    navigate('observation-form-print', [collectId]);

export const navigateAnswerForm = (collectId: number, observationId: number) =>
    getCurrentCommunity().map(com =>
        getUserId().foldL(
            () =>
                navigateLoginTo([
                    'answer-form',
                    com.id.toString(),
                    collectId.toString(),
                    observationId.toString(),
                ]),
            () => navigate('answer-form', [com.id, collectId, observationId])
        )
    );
export const navigateAnnotationForm = (
    kind: AnnotationKind,
    targetKind: AnnotationTargetKind,
    targetId: number
) =>
    getSelectedCollect().map(collect =>
        getUserId().foldL(
            () =>
                navigateLoginTo([
                    'annotation-form',
                    collect.id.toString(),
                    kind.toString(),
                    targetKind.toString(),
                    targetId.toString(),
                ]),
            () =>
                navigate('annotation-form', [
                    collect.id,
                    kind,
                    targetKind,
                    targetId,
                ])
        )
    );
export const navigateRegister = () => {
    const currentCommunity = getCurrentCommunity()
        .map(({ id }) => id)
        .getOrElse(1);
    navigate('register', [currentCommunity]);
};

export const navigateAbout = () => navigate('about', []);

export const navigateLogin = () => {
    const currentRoute = getRoute();
    navigate('login', currentRoute);
};

const navigateLoginTo = (path: Path) => navigate('login', path, true);

logger('loaded');
