import {
    DETAILS,
    DIV,
    H1,
    H2,
    H3,
    NodeOrOptional,
    SPAN,
    SUMMARY,
} from 'sdi/components/elements';
import {
    Affinity,
    Answer,
    Collect,
    CollectCategory,
    Community,
    Contact,
    Observation,
} from '../remote';
import { markdown } from 'sdi/ports/marked';
import {
    // findCategory,
    getCurrentCommunity,
    isBaseCommunity,
    getNLastObservations,
    getAnswers,
    findChoice,
    getQuestionForAnswerInCollect,
    getContributions,
    getCommunityViewMode,
    getRemoteCategories,
    getRemoteCollects,
} from '../queries/collect';
import {
    navigateCollect,
    navigateHome,
    navigateObservationForm,
} from '../events/route';
import {
    renderButtonAddObervation,
    renderButtonHome,
    renderButtonViewCollect,
} from './buttons';
import { foldRemote, remoteToOption } from 'sdi/source';
import tr, { fromRecord } from 'sdi/locale';
import {
    minimap,
    regionMiniMap,
    renderCommunityImg,
    renderMinimap,
} from './minimap';
import { dateISOFormat, noop, notEmpty } from 'sdi/util';
// import { catOptions } from 'fp-ts/lib/Array';
import { nameToString } from 'sdi/components/button/names';
import { findUser } from '../queries/app';
import { renderAnswerAnnotations } from './annotation';
import { fromNullable, none } from 'fp-ts/lib/Option';
import { divTooltipBottom } from 'sdi/components/tooltip';
import { makeLabel, makeLabelAndIcon } from 'sdi/components/button';
import {
    setCommunityViewMode,
    subscribeCommunity,
    unsubscribeCommunity,
} from '../events/collect';
import { iife } from 'sdi/lib';

export type CommunityView = 'collect' | 'journal';

const breadcrumb = () =>
    DIV(
        'breadcrumb',
        renderButtonHome(() => navigateHome())
    );

const btnSubscribe = makeLabelAndIcon('switch', 3, 'toggle-off', () =>
    tr.smartwater('notifications')
);

const btnUnSubscribe = makeLabelAndIcon('switch', 3, 'toggle-on', () =>
    tr.smartwater('notifications')
);

const renderSubscribe = (c: Community) =>
    divTooltipBottom(
        tr.smartwater('unsubscribed'),
        {},
        btnSubscribe(() => subscribeCommunity(c))
    );

const renderUnsubscribe = (a: Affinity) =>
    divTooltipBottom(
        tr.smartwater('subscribed'),
        {},
        btnUnSubscribe(() => unsubscribeCommunity(a))
    );

const renderAffinity = (c: Community) =>
    getContributions()
        .map(remoteToOption)
        .getOrElse(none)
        .map(({ user }) =>
            DIV(
                'affinity-box',
                fromNullable(
                    user.affinities.find(a => a.community === c.id)
                ).fold(renderSubscribe(c), renderUnsubscribe)
            )
        );

const renderErrorSelection = () =>
    DIV('error', 'Sorry, could not find the selected community');

const renderContact = ({ name }: Contact) =>
    DIV(
        'contact',
        DIV('contact-label', `${tr.smartwater('communityContactLabel')} : `),
        DIV('contact-name', name)
    );

const renderActions = (c: Collect) =>
    DIV(
        'actions',
        renderButtonViewCollect(() => navigateCollect(c.id)),
        renderButtonAddObervation(() => navigateObservationForm(c.id))
    );

// const renderCategory = (c: CollectCategory) =>
//     DIV(
//         {
//             className: 'category',
//             title: fromRecord(c.description),
//         },
//         SPAN('icon', nameToString('tag')),
//         fromRecord(c.title)
//     );

// const renderCategories = (cl: CollectCategory[]) =>
//     DIV('cat-box', ...cl.map(renderCategory));

const renderCollect = (c: Collect) =>
    DIV(
        {
            className: 'collect__item',
            key: `collect-${c.id}`,
        },
        H3('collect__title', fromRecord(c.title)),
        // notEmpty(catOptions(c.categories.map(findCategory))).map(
        //     renderCategories
        // ),

        DIV('short-description', markdown(fromRecord(c.shortDescription))),
        renderActions(c)
    );

const renderCollectList = (ids: number[], cat?: CollectCategory) =>
    foldRemote<Collect[], string, NodeOrOptional>(
        () => DIV('', 'Application did not start yet'),
        () => DIV('', 'Campaigns are loading'),
        error => DIV('error', `Could not load Campaigns because of: ${error}`),
        collects => {
            if (cat != undefined) {
                const filteredCollects = collects.filter(
                    col =>
                        ids.indexOf(col.id) >= 0 &&
                        col.categories.indexOf(cat.id) >= 0
                );
                return notEmpty(filteredCollects).map(() =>
                    DETAILS(
                        'collect__category',
                        SUMMARY(
                            'category-summary',
                            DIV(
                                'cat-title',
                                SPAN('fa icon', nameToString('tag')),
                                fromRecord(cat.title)
                            ),
                            DIV('cat-description', fromRecord(cat.description))
                        ),
                        DIV(
                            'collect__list',
                            filteredCollects.map(renderCollect)
                        )
                    )
                );
            }
            return DIV(
                'collect__list',
                collects
                    .filter(
                        col =>
                            ids.indexOf(col.id) >= 0 &&
                            col.categories.length == 0
                    )
                    .map(renderCollect)
            );
        }
    );

// const renderCollects = (ids: number[]) => (cat: CollectCategory) =>
//     DETAILS(
//         'collect__category',
//         SUMMARY(
//             'category-summary',
//             DIV(
//                 'cat-title',
//                 SPAN('fa icon', nameToString('tag')),
//                 fromRecord(cat.title)
//             ),
//             DIV('cat-description', fromRecord(cat.description))
//         ),
//         renderCollectList(ids, cat)(getRemoteCollects())
//     );

const renderCollectsWithoutCat = (ids: number[]) =>
    renderCollectList(ids)(getRemoteCollects());

const renderCollectListByCat = (ids: number[]) =>
    foldRemote<CollectCategory[], string, NodeOrOptional>(
        () => DIV({}, 'Application did not start yet'),
        () => DIV({}, 'Categories are loading'),
        error => DIV('error', `Could not load Campaigns because of: ${error}`),
        cat =>
            DIV(
                'collect__list',
                ...cat.map(c => renderCollectList(ids, c)(getRemoteCollects()))
            )
    );

// const renderAnnotation = (a: Annotation) =>
//     findUser(a.user).map(u =>
//         DIV(
//             '',
//             `${u.name} ${tr.smartwater('madeAnObservation')} ${dateISOFormat(
//                 new Date(a.timestamp)
//             )} ${tr.smartwater('madeAnObservation2')}`
//         )
//     );
// const renderObservation = (o: Observation) =>
//     findUser(o.user).map(u =>
//         DIV(
//             { className: 'log', onClick: () => navigateObservation(o.id) },
//             `${u.name} ${tr.smartwater('madeAnObservation')} ${dateISOFormat(
//                 new Date(o.timestamp)
//             )} ${tr.smartwater('madeAnObservation2')}`
//         )
//     );

export const renderLogAnswer = (o: Observation) => (answer: Answer) =>
    getQuestionForAnswerInCollect(answer, o.collect).map(question =>
        DIV(
            'log-part',
            // DIV('label-question', '--A répondu à la question'),
            DIV('question', fromRecord(question.title)),
            DIV(
                'choice selected',
                findChoice(answer.choice).map(({ title }) => fromRecord(title))
            ),
            // DIV('label-note', 'et a annoté '),
            renderAnswerAnnotations(answer, question)
        )
    );

// const renderObservation = (o: Observation) =>
//     DIV('log-answers', ...getAnswers(o.id).map(renderLogAnswer(o)));

const renderObservation = (o: Observation) =>
    findUser(o.user).map(u =>
        DIV(
            `log id-${o.id}`,
            DIV(
                'log-meta',
                `${tr.smartwater('log/on')} ${dateISOFormat(
                    new Date(o.timestamp)
                )} ${u.name} ${tr.smartwater('log/answered')}`
            ),
            ...getAnswers(o.id).map(renderLogAnswer(o))
        )
    );

// const renderObservation = (o: Observation) =>
//     findUser(o.user).map(u =>
//         DIV(
//             { className: 'log' },
//             `${tr.smartwater('madeAnObservationOn')} ${dateISOFormat(
//                 new Date(o.timestamp)
//             )} ${u.name}  ${tr.smartwater('madeAnObservation')}`,
//             SPAN(
//                 {
//                     className: 'log-link',
//                     onClick: () => {
//                         selectCollect(o.collect);
//                         navigateObservation(o.id);
//                     },
//                 },
//                 tr.smartwater('madeAnObservationLink'),
//                 SPAN('icon', nameToString('link'))
//             ),
//             ` ${tr.smartwater('madeAnObservation2')}`,
//             DIV('log-answers', ...getAnswers(o.id).map(renderLogAnswer(o)))
//         )
//     );

const renderCommunityLog = (c: Community) => {
    // const annotations = getNLastAnnotations(c, 10);
    return DIV(
        'community-log',
        H2('', tr.smartwater('communityLogTitle')),
        // ...annotations.map(renderAnnotation)
        ...getNLastObservations(c, 10).map(renderObservation)
    );
};

const seeCollectBtn = makeLabel('navigate', 1, () =>
    tr.smartwater('seeCollectTabLabel')
);
const seeLogBtn = makeLabel('navigate', 1, () =>
    tr.smartwater('seeLogTabLabel')
);

// const renderFullClass = (full: boolean) =>
//     full ? 'view-collect-list' : 'view-log';

const renderCollectOrLog = (c: Community) => {
    const content = iife(() => {
        switch (getCommunityViewMode()) {
            case 'collect':
                return DIV(
                    'collects-wrapper',
                    H2('', tr.smartwater('campaignListTitle')),
                    renderCollectsWithoutCat(c.collects),
                    renderCollectListByCat(c.collects)(getRemoteCategories())
                );
            case 'journal':
                return renderCommunityLog(c);
        }
    });

    const tabs = iife(() => {
        switch (getCommunityViewMode()) {
            case 'collect':
                return DIV(
                    'tabs__list',
                    seeCollectBtn(noop, 'tab selected'),
                    seeLogBtn(
                        () => setCommunityViewMode('journal'),
                        'tab unselected'
                    )
                );

            case 'journal':
                return DIV(
                    'tabs__list',
                    seeCollectBtn(
                        () => setCommunityViewMode('collect'),
                        'tab unselected'
                    ),
                    seeLogBtn(noop, 'tab selected')
                );
        }
    });

    return DIV('tab-wrapper', tabs, content);
};

const renderSelection = (c: Community) =>
    DIV(
        'collect__content',
        DIV('content-header', breadcrumb(), renderAffinity(c)),
        H1('', fromRecord(c.name)),
        isBaseCommunity(c.id)
            ? regionMiniMap()
            : DIV(
                  'picto-maps',
                  renderCommunityImg(c.id),
                  renderMinimap(minimap(c.geom))
              ),
        markdown(fromRecord(c.description)),
        renderContact(c.contact),
        renderCollectOrLog(c)
    );

export const render = () =>
    DIV(
        'community__wrapper',
        getCurrentCommunity().foldL(renderErrorSelection, renderSelection)
    );

export default render;
