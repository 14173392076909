import debug = require('debug');
import { fromNullable } from 'fp-ts/lib/Option';
import { Extent } from 'ol/extent';
import { DIV, IMG, SPAN } from 'sdi/components/elements';
import { iife } from 'sdi/lib';
import tr from 'sdi/locale';
import { miniMap2, MiniStep } from 'sdi/map/mini';
import { setCommunityImg } from '../events/collect';
import { getCommunityImg } from '../queries/collect';
import { getMultiPolygonExtent } from '../queries/map';
import { Community } from '../remote';
import { placeholder, region } from './images';
import { regionPolygon, regionExtent } from './region_feature';

const logger = debug('sdi:minimap');

// const WIDTH = iife(() => {
//     const width = document.documentElement.clientWidth;
//     const height = document.documentElement.clientHeight;
//     if (height > width) {
//         return Math.round(width * 0.94);
//     }
//     return 736;
// });
const WIDTH = 512;
const HEIGHT = 512;

// const paint = (
//     ctx: CanvasRenderingContext2D /* geom: DirectGeometryObject */
// ) => {
//     ctx.strokeStyle = 'white';
//     ctx.lineWidth = 16;
//     ctx.lineCap = 'round';
//     ctx.lineJoin = 'round';
//     ctx.stroke();
// };

const paint = (
    ctx: CanvasRenderingContext2D /* geom: DirectGeometryObject */
) => {
    ctx.strokeStyle = '#ed6b06';
    ctx.lineWidth = 5;
    ctx.lineCap = 'round';
    ctx.lineJoin = 'round';
    ctx.stroke();
};

// const paint = (
//     ctx: CanvasRenderingContext2D /* geom: DirectGeometryObject */
// ) => {
//     const source = ctx.canvas;
//     const tmp = document.createElement('canvas');
//     tmp.width = source.width;
//     tmp.height = source.height;
//     fromNullable(tmp.getContext('2d')).map(ctx2 => {
//         ctx2.putImageData(
//             ctx.getImageData(0, 0, source.width, source.height),
//             0,
//             0
//         );
//         ctx.clearRect(0, 0, source.width, source.height);
//         // if (geom.type === 'MultiPolygon') {
//         //     geom.coordinates.map(coords => {
//         //         drawPolygon(ctx, coords);
//         //     });
//         // }
//         ctx.clip();
//         ctx.drawImage(tmp, 0, 0);
//     });
// };

export const minimap = miniMap2(
    'https://geoservices-urbis.irisnet.be/geoserver/wms',
    {
        // LAYERS: 'Urbis:Ortho2020',
        // LAYERS: 'Ortho2020,UrbisAdm:Adpt,UrbisAdm:ToNameLine',
        // LAYERS: 'Hillshade2019',
        LAYERS: 'Bg',
        WIDTH,
        HEIGHT,
    },
    paint
);

export const renderMinimap = (step: MiniStep) => {
    switch (step.step) {
        case 'loaded':
            return SPAN('minimap__wrapper', IMG({ src: step.data }));
        case 'loading':
            return SPAN(
                'minimap__wrapper',
                IMG({ src: placeholder }),
                SPAN('minimap-message loading', tr.smartwater('loadingLabel'))
            );
        case 'failed':
            return SPAN(
                'minimap__wrapper',
                IMG({ src: placeholder }),
                SPAN(
                    'minimap-message error',
                    tr.smartwater('loadingErrorLabel')
                )
            );
    }
};

let regionData: string | null = null;

iife(() => {
    const source = document.createElement('img');
    source.addEventListener('load', () => {
        const target = document.createElement('canvas');
        const width2 = WIDTH * 2;
        const height2 = HEIGHT * 2;
        target.width = width2;
        target.height = height2;

        return fromNullable(target.getContext('2d')).map(ctx => {
            const sourceWidth = source.naturalWidth;
            const sourceHeight = source.naturalHeight;
            const scale = height2 / sourceHeight;
            const targetWidth = sourceWidth * scale;
            const x = (width2 - targetWidth) / 2;
            ctx.drawImage(source, x, 0, targetWidth, height2);
            regionData = target.toDataURL();
        });
    });
    source.setAttribute('src', region);
});

const px = (n: number) => `${n}px`;

export const regionMiniMap = () =>
    fromNullable(regionData).map(src =>
        DIV(
            'picto-maps',

            IMG({
                className: 'community-img region',
                width: px(WIDTH),
                height: px(HEIGHT),
                src,
            })
        )
    );

const dx = (extent: Extent) => Math.abs(extent[2] - extent[0]);
const dy = (extent: Extent) => Math.abs(extent[3] - extent[1]);

export const createCommunityImg = (community: Community) => {
    const communtityExtent = getMultiPolygonExtent(community.geom);
    const xDist = dx(communtityExtent);
    const yDist = dy(communtityExtent);
    const maxDistCommunity = Math.max(xDist, yDist);

    const target = document.createElement('canvas');
    const extent = regionExtent();
    logger(`create Img for community: ${community.id} - extent: ${extent}`);
    target.width = WIDTH;
    target.height = HEIGHT;
    return fromNullable(target.getContext('2d')).map(ctx => {
        //to test:
        const scale = WIDTH / dx(extent);
        const { tx, ty } = {
            tx: -extent[0],
            ty: -extent[1] - HEIGHT / scale,
            // ty: - (dy(extent) - HEIGHT * scale) / 2,
        };
        const base = new DOMMatrix();
        const scaled = base.scale(scale, -scale);
        const translated = scaled.translate(tx, ty);
        logger(
            'TRANSORM 149546, 169775',
            translated.transformPoint({ x: 149546, y: 169775 })
        );
        ctx.setTransform(translated);
        // ctx.translate(tx, ty);
        // ctx.scale(scale, scale);

        // future transform matrix? :
        // const transformScale = dx(extent) / WIDTH;
        // ctx.transform(
        //     transformScale,
        //     1,
        //     1,
        //     transformScale,
        //     extent[0],
        //     extent[1]
        // );

        //draw community polygon
        const communityCoords = community.geom.coordinates;
        ctx.beginPath();
        communityCoords.map(poly =>
            poly.map(line => {
                const start = line[0];
                ctx.moveTo(start[0], start[1]);
                line.map(c => ctx.lineTo(c[0], c[1]));
                ctx.closePath();
            })
        );

        ctx.fillStyle = '#ed6b06';
        ctx.fill();
        logger(
            'linewith scaling',
            8 / scale,
            Math.pow(dy(extent) / maxDistCommunity, 2),
            (8 / scale) * Math.pow(dy(extent) / maxDistCommunity, 2)
        );
        ctx.strokeStyle = '#ed6b06';

        //make a bigger stroke when community is small
        ctx.lineWidth = ((8 / scale) * dy(extent)) / (30 * maxDistCommunity);
        // ctx.lineCap = 'round';
        // ctx.lineJoin = 'round';
        ctx.stroke();

        //draw region
        const regCoords = regionPolygon.coordinates[0][0];
        ctx.beginPath();
        ctx.moveTo(regCoords[0][0], regCoords[0][1]);
        regCoords.map(c => {
            // if (i % 50 === 0) {
            ctx.lineTo(c[0], c[1]);
            // }
        });
        ctx.closePath();
        ctx.strokeStyle = '#ed6b06';
        ctx.lineWidth = 8 / scale;
        ctx.lineCap = 'round';
        ctx.lineJoin = 'round';
        ctx.stroke();

        //draw community-picto (does not work...?)
        // if (maxDistCommunity < WIDTH / scale) {
        // const centerCommunity = [
        //     communtityExtent[0] + xDist / 2,
        //     communtityExtent[1] + yDist / 2,
        // ];
        // logger(
        //     'img center',
        //     centerCommunity[0],
        //     centerCommunity[1],
        //     WIDTH / scale
        // );
        // const picto = document.createElement('img');
        // picto.addEventListener('load', () => {
        //     const sourceWidth = picto.naturalWidth;
        //     const sourceHeight = picto.naturalHeight;
        //     const scalePicto = HEIGHT / sourceHeight;
        //     const targetWidth = sourceWidth * scalePicto;
        //     const x = (WIDTH - targetWidth) / 2;
        //     ctx.drawImage(
        //         picto,
        //         x / scale + centerCommunity[0],
        //         centerCommunity[1],
        //         WIDTH / scale,
        //         WIDTH / scale
        //     );
        // });
        // picto.setAttribute('src', communityPicto);
        // }

        setCommunityImg(community.id, target.toDataURL());
    });
};

export const renderCommunityImg = (communityId: number) =>
    getCommunityImg(communityId).map(src =>
        IMG({
            className: 'community-img',
            src,
            width: px(WIDTH),
            height: px(HEIGHT),
        })
    );

// const drawPolygon = (ctx: CanvasRenderingContext2D, polygon: MultiPolygon) => {
//     const coords = polygon.coordinates[0][0];
//     ctx.moveTo(coords[0][0], coords[0][1]);
//     coords.map(c => ctx.lineTo(c[0], c[1]));
//     ctx.closePath();
//     return ctx;
// };

logger('loaded');
