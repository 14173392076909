import { create, EditOptions } from 'sdi/map';
import { Option, none, some } from 'fp-ts/lib/Option';
import { DIV, NodeOrOptional, H2, BUTTON } from 'sdi/components/elements';
import { makeLabelAndIcon } from 'sdi/components/button';
import tr from 'sdi/locale';
import {
    getObservationMapInfo,
    MAP_OBSERVATION_FORM,
    getFormObservationInteraction,
    getFormObservation,
    LAYER_OBSERVATION_FORM,
} from 'smartwater/src/queries/collect';
import { Feature, DirectGeometryObject, Point } from 'sdi/source';
import {
    updateObservationView,
    setScaleLine,
    trackerEvents,
    startMark,
    endMark,
} from 'smartwater/src/events/map';
import { navigateHome } from 'smartwater/src/events/route';
import {
    getMapObservationView,
    getBaseLayer,
    getMapInteraction,
    isTracking,
} from 'smartwater/src/queries/map';
import {
    addObservationLayer,
    addTrackObservationLayer,
    saveFormObservation,
    setFormObservationObserved,
    withLambert72,
} from 'smartwater/src/events/collect';
import { noop } from 'sdi/util';
import { renderButtonHome } from '../buttons';

let mapUpdate: Option<() => void> = none;
let mapSetTarget: Option<(e: HTMLElement) => void> = none;

const pointGeom = (g: DirectGeometryObject): Option<Point> =>
    g.type === 'Point' ? some(g) : none;

const editOptions: EditOptions = {
    getCurrentLayerId: () => LAYER_OBSERVATION_FORM,
    getGeometryType: () => 'Point',
    addFeature: (f: Feature) =>
        pointGeom(f.geometry).map(geom => {
            setFormObservationObserved(withLambert72(geom));
            addObservationLayer();
            stopTracker();
            // getFormObservation().foldL(
            //     () => setFormObservationObserved(geom),
            //     (_o) => { }
            // )
            // const { observer, observed } = getFormObservation();
            // if (observer === null && observed === null) {
            //     setFormObservationObserver(geom);
            // }
            // else if (observed === null) {
            //     setFormObservationObserved(geom);
            // }
        }), // create
    setGeometry: () => void 0, // TODO: modify
};

const attachMap = (element: HTMLElement | null) => {
    mapUpdate = mapUpdate.foldL(
        () => {
            const { update, setTarget, editable, trackable, markable } = create(
                MAP_OBSERVATION_FORM,
                {
                    getBaseLayer,
                    getView: getMapObservationView,
                    getMapInfo: getObservationMapInfo,
                    updateView: updateObservationView,
                    setScaleLine,
                    element,
                }
            );
            editable(editOptions, getFormObservationInteraction);
            trackable(
                {
                    resetTrack: trackerEvents.resetTrack,
                    setCenter: center =>
                        updateObservationView({
                            dirty: 'geo',
                            center,
                            zoom: 14,
                        }),
                    updateTrack: c => {
                        trackerEvents.updateTrack(c);
                        addTrackObservationLayer();
                    },
                },
                getMapInteraction
            );

            markable({ startMark, endMark }, getMapInteraction);

            mapSetTarget = some(setTarget);
            update();
            return some(update);
        },
        update => some(update)
    );

    if (element) {
        mapSetTarget.map(f => f(element));
    }
};

const renderMap = () => {
    mapUpdate.map(f => f());
    return DIV(
        {
            className: 'observation-wrapper-map-form',
            key: 'observation-wrapper-map-form',
        },
        DIV({
            id: 'observation-map',
            key: 'observation-map',
            className: 'map-wrapper',
            ref: attachMap,
        })
    );
};

const wrap = (...nodes: NodeOrOptional[]) =>
    DIV(
        {
            className: 'observation-wrapper-form',
            key: 'observation-wrapper-form',
        },
        ...nodes
    );

// const buttonValidate = makeLabelAndIcon('switch', 2, 'chevron-right', () => tr.smartwater('saveAndContinue'));

// const renderCollectTitle = (c: Collect) =>
//     H1({}, c.title);

// const renderUnknownCollectTitle = () =>
//     H1({}, 'unknown collect');

// const renderInitial = () => wrap(
//     renderButtonHome(navigateHome),
//     getSelectedCollect()
//         .foldL(
//             renderUnknownCollectTitle,
//             renderCollectTitle,
//         ),
//     DIV({ className: 'helptext' }, `Encodage d'une nouvelle observation`),
//     DIV({ className: 'choice-item' },
//         H2({ className: 'form-header' }, `D'où observez-vous ?`),
//         DIV({ className: 'helptext' }, `Indiquez votre position spatiale en tant qu'observateur-rice`),
//         buttonValidate(noop, 'disabled'),
//     ),
//     renderMap(),
// );

const btnGeolocate = makeLabelAndIcon('select', 2, 'location-arrow', () =>
    tr.smartwater('findPosition')
);
const btnStop = makeLabelAndIcon('close', 2, 'stop', () =>
    tr.smartwater('stop')
);

const startTracker = () => {
    trackerEvents.startTrack();
};
const stopTracker = () => {
    trackerEvents.stopTrack();
};

// const withTrack = withInteractionOpt<InteractionTrack>('track',
//     ({ state }) => `${state.track.length}`
// )

const breadCrumb = () =>
    DIV({ className: 'breadcrumb' }, renderButtonHome(navigateHome));

const renderObserved = () =>
    wrap(
        breadCrumb(),
        // getSelectedCollect()
        //     .foldL(
        //         renderUnknownCollectTitle,
        //         renderCollectTitle,
        //     ),
        DIV(
            {},
            H2(
                { className: 'form-header' },
                tr.smartwater('locateObservation')
            ),
            isTracking()
                ? btnStop(stopTracker)
                : btnGeolocate(() => {
                      startTracker();
                  })
            // buttonValidate(noop, 'disabled'),
            // withTrack(getMapInteraction())
        ),
        renderMap(),
        BUTTON(
            {
                className: 'btn btn-1 validate-location disabled',
                onClick: noop,
            },
            tr.smartwater('saveAndContinue')
        )
    );

const renderValidate = () =>
    wrap(
        breadCrumb(),
        // getSelectedCollect()
        //     .foldL(
        //         renderUnknownCollectTitle,
        //         renderCollectTitle,
        //     ),
        DIV(
            {},
            H2(
                { className: 'form-header' },
                tr.smartwater('locateObservation')
            ),

            // buttonValidate(saveFormObservation),
            isTracking() ? btnStop(stopTracker) : btnGeolocate(startTracker)
        ),
        renderMap(),
        BUTTON(
            {
                className: 'btn btn-1 validate-location',
                onClick: saveFormObservation,
            },
            tr.smartwater('saveAndContinue')
        )
    );

const render = () => getFormObservation().foldL(renderObserved, renderValidate);

// {
//     const { observer, observed } = getFormObservation();
//     if (observer === null) {
//         return renderInitial();
//     }
//     else if (observed === null) {
//         return renderObserved();
//     }
//     return renderValidate();
// };

export default render;
